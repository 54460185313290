import React from 'react'
import styled from 'styled-components';
import UserFormMenu from '../_UserForm/Menu';

const SProfileContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  max-height: 100Vh;
`
const SProfileContainerHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  margin-bottom: 64px;
  width: 100%;
`

const SProfileContainerContent = styled.div`
  // min-height: 604px;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 2;
  margin-bottom: 100px;
`

export default function ProfileContainer({...props}:any) {

  return(
    <>
      <SProfileContainerWrapper>
          <SProfileContainerHeader>
              <UserFormMenu 
                pos={props.pos} 
                contact_url="/profile/contact"
                dreamjob_url="/profile/dreamjob"
                skills_url="/profile/skills"
                portfolio_url="/profile/portfolio"
                personal_url="/profile/personal"
              />
          </SProfileContainerHeader>
          <SProfileContainerContent>
              {props.children}
          </SProfileContainerContent>
      </SProfileContainerWrapper>
    </>
  )
}