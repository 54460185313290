import React from 'react';
import { useNavigate } from 'react-router-dom';
import SignUpBanner from '../_Menu/SignUpBanner';
import UserFormLogin from '../_UserForm/Login';
import { SignUpContainer } from './SignUpStyles';

interface IVLogin {
}

export default function VLogin({...props}:IVLogin) {
  const navigate = useNavigate();

  return(
    <>
      <SignUpBanner/>
      <SignUpContainer>
        <UserFormLogin callback={() => {navigate(`/`)}}/>
      </SignUpContainer>
    </>
  )
}