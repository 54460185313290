import React, { useEffect, useState, useContext } from 'react'
import { Col, Row } from 'react-bootstrap';
import { SForm, SCol, SGroup, STitle, SLabel, SControl, SButton, SFeedback } from './FormStyle'
import { Formik } from 'formik';
import * as yup from "yup";
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { ToastContext } from '../_Core/Toast';
import './FormStyle.css';

export default function UserFormResetPasswordRequest({...props}:any) {
  const t = useTranslation().t;
  const location = useLocation();
  const [email, setEmail] = useState("")
  const toastHandler = useContext(ToastContext);


  useEffect(() => {
    const startEmail = location.search.indexOf("email=") + 6
    const endEmail = location.search.indexOf("&")-2
    setEmail(decodeURIComponent(location.search).substring(startEmail,endEmail))
  }, [])

  return (
    <Formik
      initialValues={{
        email: email,
        password: '',
        passwordcheck: '',
      }}
      enableReinitialize={true}
      onSubmit={
        async (values) => {
          const startToken = location.search.indexOf("token=") + 6
          const token = location.search.substring(startToken)
          const body = {
            password: values.password,
            email: values.email,
            token: token
          }
          await fetch(window.__API_URL__ + '/resetpassword', {
            method: 'post',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
          })
          .then(() => {
            toastHandler.showMsg("Password successfully changed. Please Login.", false, true);
            props.callback()
          })
        }
      }
      validationSchema={yup.object().shape({
        email: yup.string()
          .required(t('ProfileContact.emailValidationErrorRequired'))
          .email(t('ProfileContact.emailValdationErrorFormat')), 
        password: yup.string()
          .required(t('ResetPassword.passwordValidationErrorRequired'))
          .matches(/^(?=.*[A-Z])(?=.*[!§$%&/=?*+~#<>|^°])(?=.*[0-9])(?=.*[a-z]).{8,}$/, t('ResetPassword.passwordValidationErrorMatch')), 
        passwordcheck: yup.string()
          .required(t('ResetPassword.passwordValidationErrorRequired'))
          .oneOf([yup.ref('password')], t('ResetPassword.passwordValidationErrorMatchCheck'))
      })}
    >
      {({
      handleSubmit,
      handleChange,
      handleBlur,
      values,
      touched,
      isValid,
      errors,
      setFieldValue
      }) => (  
        <SForm noValidate onSubmit={handleSubmit}>
          <SCol md="5">
            <Row>
              <STitle>{t('ResetPassword.title')}</STitle>
            </Row>
            <Row>
              <SGroup as={Col} md="12" controlId="email">
                <SLabel>{t('ProfileContact.email')}</SLabel>
                <SControl
                  type="text"
                  name="email"
                  placeholder={t('ProfileContact.emailPlaceHolder')}
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.email && !!errors.email}
                />
                <SFeedback type="invalid" tooltip>{errors.email}</SFeedback>
              </SGroup>     
              <SGroup as={Col} md="12" controlId="password">
                <SLabel>{t('ProfileContact.password')}</SLabel>
                <SControl 
                  type='password' 
                  name="password" 
                  placeholder={"******"}
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.password && !!errors.password}
                /> 
                <SFeedback type="invalid" tooltip>{errors.password}</SFeedback>
              </SGroup>
              <SGroup as={Col} md="12" controlId="passwordcheck">
                <SLabel>{t('ProfileContact.password')}</SLabel>
                <SControl 
                  type='password' 
                  name="passwordcheck" 
                  placeholder={"******"}
                  value={values.passwordcheck}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.passwordcheck && !!errors.passwordcheck}
                /> 
                <SFeedback type="invalid" tooltip>{errors.passwordcheck}</SFeedback>
              </SGroup>
              <SGroup as={Col} md="12" >
                <SButton primary={true} type="submit">{t('Base.submit')}</SButton>
              </SGroup>
            </Row>
          </SCol>
        </SForm>
      )}  
    </Formik>
  )
}