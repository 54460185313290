import React, { useState, useEffect } from 'react'
import { Formik } from 'formik';
import { Col, Row } from 'react-bootstrap';
import { SForm, SCol, SGroup, STitle, SSubTitle, SEditButton, SLabel, SControl, SFeedback, SButton, selectStyles, SRow } from './FormStyle'
import * as yup from "yup";
import { useTranslation } from 'react-i18next';
import { NationalitySelectField } from './Fields/NationalitySelectField'
import { PermitSelectField, TPermit } from './Fields/PermitSelectField'
import './FormStyle.css';
import { useFetchAuth } from '../_Core/AuthHandling';
import CountryCodes from './Fields/CountryCodes.json'
import { Select } from './Fields/SelectField';

interface IUserWorkpermit {
  nationalities: string[]
  birthday: string
  street: string
  zip: string
  city: string
  country: string
  workpermits: TPermit[]
}

const DateRegex = [
  /^([0-3])$/,
  /^$/,
  /^(0[1-9])|([1-2][0-9])|(3[0-1])\.$/,
  /^(((0[1-9])|([1-2][0-9])|(3[0-1]))\.[0-1])$/,
  /^$/,
  /^(((0[1-9])|([1-2][0-9])|(3[0-1]))\.(0[1-9]|1[0-2])\.)$/,
  /^(((0[1-9])|([1-2][0-9])|(3[0-1]))\.(0[1-9]|1[0-2])\.[1-2])$/,
  /^(((0[1-9])|([1-2][0-9])|(3[0-1]))\.(0[1-9]|1[0-2])\.((19)|(20)))$/,
  /^(((0[1-9])|([1-2][0-9])|(3[0-1]))\.(0[1-9]|1[0-2])\.((19)|(20))[0-9])$/,
  /^(((0[1-9])|([1-2][0-9])|(3[0-1]))\.(0[1-9]|1[0-2])\.((19)|(20))[0-9][0-9])$/,
]

const dateCheck = (value:any) => {
  if(typeof(value)!="string"){
    return(false)
  }
  if (value.length > 10){
    return(false)
  }
  const regex = DateRegex[value.length-1];
  if(regex.test(value.toString())){
    return(true)
  } else {
    return(false)
  }
}

export default function UserFormPersonal({...props}:any) {
  const t = useTranslation().t;
  const fetchAuth = useFetchAuth()
  const [readOnly, setReadOnly] = useState(props.readinit === true)
  const [nationalities, setNationalities] = useState(new Array<string>())
  const [birthday, setBirthday] = useState('')
  const [street, setStreet] = useState("")
  const [zip, setZip] = useState("")
  const [city, setCity] = useState("")
  const [country, setCountry] = useState("CH")
  const [workpermits, setWorkpermits] = useState([] as TPermit[])

  useEffect(() => {
    const effectAsync = async () => {
      fetchAuth(
        window.__API_URL__ + `/user/0/workpermit`, 
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        }
      )
      .then((data: IUserWorkpermit) => {
        setNationalities(data.nationalities === undefined ? [] : data.nationalities)
        setBirthday(data.birthday)
        setStreet(data.street)
        setZip(data.zip)
        setCity(data.city)
        setCountry(data.country)
        setWorkpermits(data.workpermits === undefined ? [] : data.workpermits)
      })
      .catch(error => {setReadOnly(true)})
    }
    effectAsync()
  }, [])

  const countryOptions = CountryCodes.map((code)=>{
    return({ value: code, label: t(`Country.${code}`) })
  })

  return(
    <Formik
      initialValues={{
        nationalities: nationalities,
        birthday: birthday,
        street: street,
        zip: zip,
        city: city,
        country: country,
        workpermits: workpermits,
      }}
      enableReinitialize={true}
      onSubmit={async (values) => {
          const body = {
            nationalities: nationalities.join(","),
            birthday: values.birthday,
            street: values.street,
            zip: values.zip,
            city: values.city,
            country: values.country,
            workpermits: workpermits
          }
          await fetchAuth(
            window.__API_URL__ + `/user/0/workpermit`, 
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },  
              body: JSON.stringify(body),
            }
          )
          .then(data => {setReadOnly(true); props.submitCallback(); return data})
        }
      }
      validationSchema={yup.object().shape({ 
        //nationality: yup.string()
        //  .required(t('ProfilePersonal.nationalityValidationErrorRequired')),
        birthday: yup.string()
        //  .required(t('ProfilePersonal.birthdateValidationErrorRequired'))
          .test(
            "valid-birthdate",
            t("ProfilePersonal.birthdateValidationErrorFormat"),
            dateCheck),   
        //street: yup.string(),
        //  .required(t('ProfilePersonal.residencyValidationErrorRequired')), 
        //zip: yup.string(),
        //  .required(t('ProfilePersonal.residencyValidationErrorRequired')), 
        //city: yup.string(),
        //  .required(t('ProfilePersonal.residencyValidationErrorRequired')), 
        //country: yup.string(),
        //  .required(t('ProfilePersonal.residencyValidationErrorRequired')), 
        //permits: yup.string()
        //  .required(t('ProfilePersonal.workpermitsValidationErrorRequired')),
      })}
    >
      {({
      handleSubmit,
      handleChange,
      handleBlur,
      values,
      touched,
      isValid,
      errors,
      setFieldValue
      }) => (  
        <SForm noValidate onSubmit={handleSubmit}>
          <SCol md="5">
            <STitle>{t('ProfilePersonal.title')}</STitle>
            <SSubTitle>{t('ProfilePersonal.subTitle')}</SSubTitle>
            {props.readinit === true ? 
              <Row>
                <SEditButton 
                  className="fas fa-pen"
                  onClick={() => setReadOnly(false)}/> 
              </Row> : <></>
            }
            <SGroup as={Col} md="12" controlId="nationality">
              <SLabel>{t('ProfilePersonal.nationality')}</SLabel>
              <NationalitySelectField 
                readOnly={readOnly}
                nationalities={nationalities}
                setNationalities={setNationalities}
              />
              <SFeedback type="invalid" tooltip>{errors.nationalities}</SFeedback>
            </SGroup>
            <SGroup as={Col} md="12" controlId="workpermits">
              <SLabel>{t('ProfilePersonal.workpermits')}</SLabel>
              <PermitSelectField 
                readOnly={readOnly} 
                permits={workpermits} 
                setPermits={setWorkpermits}
              />
              <SFeedback type="invalid" tooltip>{errors.workpermits}</SFeedback>
            </SGroup>
            <SGroup as={Col} md="12" controlId="birthday">
              <SLabel>{t('ProfilePersonal.birthday')}</SLabel>
              <SControl 
                type="text" 
                name="birthday" 
                placeholder={readOnly ? t('Base.empty') : t('ProfilePersonal.birthdatePlaceHolder')}
                value={values.birthday}
                onChange={(e:any) => {handleChange(e); setBirthday(e.target.value)}}
                onBlur={handleBlur}
                isInvalid={!!errors.birthday}
                readOnly={readOnly}
                plaintext={readOnly}
              />
              <SFeedback type="invalid" tooltip>{errors.birthday}</SFeedback>
            </SGroup>
            <SGroup as={Col} md="12" controlId="residency" style={{marginBottom:"0px"}}>
              <SLabel>{t('ProfilePersonal.residency')}</SLabel>
              <SCol style={{padding:"0px"}}>
                <SControl 
                  type="text" 
                  name="street" 
                  placeholder={readOnly ? t('Base.empty') : t('ProfilePersonal.streetPlaceHolder')}
                  value={values.street}
                  onChange={(e:any) => {handleChange(e); setStreet(e.target.value)}}
                  onBlur={handleBlur}
                  isInvalid={!!errors.street}
                  readOnly={readOnly}
                  plaintext={readOnly}
                />
                <SFeedback type="invalid" tooltip>{errors.street}</SFeedback>
                <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                  <div style={{width:"35%"}}>
                    <SControl
                      style={{width:"100%"}}
                      type="text" 
                      name="zip" 
                      placeholder={readOnly ? t('Base.empty') : t('ProfilePersonal.zipPlaceHolder')}
                      value={values.zip}
                      onChange={(e:any) => {handleChange(e); setZip(e.target.value)}}
                      onBlur={handleBlur}
                      isInvalid={!!errors.zip}
                      readOnly={readOnly}
                      plaintext={readOnly}
                    />
                    <SFeedback type="invalid" tooltip>{errors.city}</SFeedback>
                  </div>
                  <div style={{width:"60%"}}>
                    <SControl 
                      style={{width:"100%"}}
                      type="text" 
                      name="city" 
                      placeholder={readOnly ? t('Base.empty') : t('ProfilePersonal.cityPlaceHolder')}
                      value={values.city}
                      onChange={(e:any) => {handleChange(e); setCity(e.target.value)}}
                      onBlur={handleBlur}
                      isInvalid={!!errors.city}
                      readOnly={readOnly}
                      plaintext={readOnly}
                    />
                    <SFeedback type="invalid" tooltip>{errors.country}</SFeedback>
                  </div>
                </div>
              </SCol>
            </SGroup> 
            <SGroup as={Col} md="12" controlId="country">
              <SLabel></SLabel>
              {readOnly ?
                <p>{countryOptions.find(option => option.value === values.country)?.label}</p> :
                <Select 
                  styles={selectStyles}
                  options={countryOptions}
                  placeholder={t('ProfilePersonal.countryPlaceHolder')}
                  onChange={(e:any) => {values.country = e.value; setCountry(e.value)}}
                  value={countryOptions.filter(option => option.value === values.country)}
                  isOptionSelected={(elem:any) => {return(elem.value === values.country)}}
                />
              }
            </SGroup>          
            {!readOnly ?
              <SGroup as={Col} md="12" xs controlid="button" style={{ justifyContent: "flex-end" }}>
                <SCol md="9" style={{ alignItems: "baseline", display: "flex", margin: "0px", padding: "0px"}}>
                  <SButton primary={true} type="submit">{t('Base.submit')}</SButton>
                </SCol> 
              </SGroup>
              : <></>
            }
          </SCol>
        </SForm>
      )}  
    </Formik>
  )
}
