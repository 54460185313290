import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components'

import useIsMobile from '../hooks/isMobile';


interface IUserFormMenu {
  pos: number,
  contact_url: string,
  dreamjob_url: string,
  skills_url: string,
  portfolio_url: string,
  personal_url: string,
}

export const SMenu = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const SMenuItem = styled("div")<{active:boolean, checked: boolean}>`
 align-items: center;
 background: ${props => props.active ? "#111111": "#FFFFFF"};
 border-radius: 12px;
 box-shadow: ${props => props.checked ? "0 4px 8px 0 rgb(0 0 0 / 12%)" : "none"};
 color: #000000;
 cursor: pointer;
 display: flex;
 flex-direction: column;
 height: 74px;
 justify-content: space-evenly;
 text-align: center;
 width: 106px;
//  height: 74px;
//  padding-left: 20px;
//  padding-right: 20px;
//  padding-top: 12px;
//  padding-bottom: 12px;

@media (max-width: 769px) {
  width: 48px;
  height: 48px;
}
`

const SCheckCircle = styled.span`
 height: 24px;
 width: 24px;
`

const SItemLabel = styled("span")<{active:boolean}>`
  color: ${props => props.active ? "#FFFFFF": "#111111"};
  display: inline-block;
  font-family: Ubuntu;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  height: 28px;
  :hover{
    cursor: pointer;
  }
  @media (max-width: 769px) {
    display: none;
  }
`

const SignUpMenuDividerImg = styled.img`
  height: 2px;
  margin-left: 16px;
  margin-right: 16px;
  width: 34px;
  @media (max-width: 769px) {
    margin-left: 4px;
    margin-right: 4px;
    width: 16px;
  }
`

interface IIcon {
  active: boolean
}

const ContactIcon = ({...props}:IIcon) => {
  return (
      <svg
          fill="none"
          height="20"
          viewBox="0 0 20 20"
          width="20"
          xmlns="http://www.w3.org/2000/svg"
      >
          <path
              clipRule="evenodd"
              d="M18.6295 2.22322C19.0586 2.57089 19.1247 3.2006 18.777 3.62972L15.5363 7.62972C15.3509 7.85856 15.074 7.99404 14.7795 8C14.4851 8.00597 14.2029 7.88183 14.0084 7.66068L12.2492 5.66068C11.8844 5.246 11.9249 4.61413 12.3395 4.24936C12.7542 3.88459 13.3861 3.92506 13.7509 4.33974L14.7279 5.45044L17.223 2.3707C17.5707 1.94158 18.2004 1.87555 18.6295 2.22322Z"
              fillRule="evenodd" 
              fill={props.active ? "#FFFFFF" : "#000000"}
          />
          <path 
              clipRule="evenodd"
              d="M4.29791 2.19704L4.29858 2.19788L4.29791 2.19704ZM4.34092 2.26457L6.34545 5.42311C6.10322 5.65052 5.83901 5.87578 5.55466 6.11821C5.31027 6.32657 5.051 6.54761 4.77803 6.79361C4.10146 7.40333 3.97078 8.38393 4.40865 9.14707C6.7241 13.1826 9.8822 15.0188 11.2953 15.6781C12.1466 16.0753 13.0716 15.7619 13.5865 15.1128L14.9736 13.3642C14.9736 13.3642 14.9748 13.3633 14.9769 13.3629C14.979 13.3625 14.9828 13.3629 14.9828 13.3629C14.9828 13.3629 14.9858 13.3636 14.9887 13.3654C15.797 13.8594 16.9247 14.5307 17.9605 15.0991C17.7745 16.2114 17.3471 16.8593 16.8759 17.2554C16.3812 17.6714 15.7413 17.8903 15.0277 17.9693C13.6028 18.1271 12.1563 17.699 11.8354 17.5947C11.4814 17.4155 9.54055 16.4038 7.467 14.7643C5.32883 13.0737 3.18484 10.8206 2.36486 8.23655C1.7656 6.34803 1.99156 5.01909 2.46257 4.10241C2.9179 3.21625 3.65473 2.61479 4.34092 2.26457ZM3.61578 0.395212C4.56043 -0.0484841 5.53722 0.414131 5.99221 1.13105L8.08923 4.43534C8.51318 5.10335 8.54224 6.07988 7.85303 6.74884C7.55766 7.03554 7.14671 7.38791 6.76429 7.71581C6.55438 7.89581 6.35307 8.06843 6.18419 8.21876C8.17699 11.6551 10.8412 13.245 12.053 13.8263L13.4019 12.126C14.0387 11.3232 15.1727 11.1355 16.0362 11.6633C16.835 12.1515 17.9394 12.8087 18.9402 13.3574C19.6076 13.7233 20.0851 14.4862 19.9498 15.3476C19.7068 16.8945 19.0722 18.0213 18.1679 18.7816C17.2786 19.5291 16.2193 19.8463 15.249 19.9538C13.3403 20.1651 11.5148 19.5928 11.1814 19.4822C11.097 19.4543 11.027 19.4247 10.9517 19.3868C10.5938 19.2068 8.48474 18.1178 6.22168 16.3284C3.98216 14.5577 1.45652 11.9963 0.454518 8.83856C-0.281506 6.51906 -0.063776 4.63835 0.679187 3.19239C1.40913 1.77178 2.59122 0.876445 3.61578 0.395212Z"
              fill={props.active ? "#FFFFFF" : "#000000"}
              fillRule="evenodd"
          />
      </svg>
  )
};

const DreamJobIcon = ({...props}:IIcon) => {
  return (
      <svg 
          fill="none"
          height="20"
          viewBox="0 0 20 20"
          width="20"
          xmlns="http://www.w3.org/2000/svg"
      >
          <path
              clipRule="evenodd"
              d="M4 0C4.55228 0 5 0.436795 5 0.97561V3.70387L7.57711 2.53055C7.88682 2.38955 8.2489 2.41262 8.53718 2.59174C8.82545 2.77085 9 3.0812 9 3.41463V7.31707H11V1.46341C11 0.9246 11.4477 0.487805 12 0.487805H17C17.5523 0.487805 18 0.9246 18 1.46341V18.0488H19C19.5523 18.0488 20 18.4856 20 19.0244C20 19.5632 19.5523 20 19 20H1C0.447715 20 0 19.5632 0 19.0244C0 18.4856 0.447715 18.0488 1 18.0488H2V5.69106C2 5.31201 2.22504 4.96727 2.57711 4.80698L3 4.61444V0.97561C3 0.436795 3.44772 0 4 0ZM16 18.0488V2.43902H13V7.31707C13.5523 7.31707 14 7.75387 14 8.29268V18.0488H16ZM12 9.26829H8V18.0488H12V9.26829ZM6 18.0488V8.29268C6 7.75387 6.44772 7.31707 7 7.31707V4.94653L4 6.31239V18.0488H6Z"
              fill={props.active ? "#FFFFFF" : "#000000"}
              fillRule="evenodd"
          />
      </svg>
  )
};

const PersonalIcon = ({...props}:IIcon) => {
  return (
      <svg
          fill="none"
          height="20"
          viewBox="0 0 20 20"
          width="20"
          xmlns="http://www.w3.org/2000/svg"
      >
          <path
              clipRule="evenodd"
              d="M0 1C0 0.447715 0.447715 0 1 0H5C5.55228 0 6 0.447715 6 1C6 1.55228 5.55228 2 5 2H2V5C2 5.55228 1.55228 6 1 6C0.447715 6 0 5.55228 0 5V1ZM10 11H9.85714C8.27919 11 7 12.2792 7 13.8571V15C7 15.5523 6.55228 16 6 16C5.44772 16 5 15.5523 5 15V13.8571C5 12.1725 5.85766 10.6882 7.16016 9.81697C6.44301 9.09403 6 8.09875 6 7C6 4.79086 7.79086 3 10 3C12.2091 3 14 4.79086 14 7C14 8.09875 13.557 9.09403 12.8398 9.81697C14.1423 10.6882 15 12.1725 15 13.8571V15C15 15.5523 14.5523 16 14 16C13.4477 16 13 15.5523 13 15V13.8571C13 12.2792 11.7208 11 10.1429 11H10ZM10 9C8.89543 9 8 8.10457 8 7C8 5.89543 8.89543 5 10 5C11.1046 5 12 5.89543 12 7C12 8.10457 11.1046 9 10 9ZM19 0C19.5523 0 20 0.447715 20 1V5C20 5.55228 19.5523 6 19 6C18.4477 6 18 5.55228 18 5V2H15C14.4477 2 14 1.55228 14 1C14 0.447715 14.4477 0 15 0H19ZM0 19C0 19.5523 0.447715 20 1 20H5C5.55228 20 6 19.5523 6 19C6 18.4477 5.55228 18 5 18H2V15C2 14.4477 1.55228 14 1 14C0.447715 14 0 14.4477 0 15V19ZM19 20C19.5523 20 20 19.5523 20 19V15C20 14.4477 19.5523 14 19 14C18.4477 14 18 14.4477 18 15V18H15C14.4477 18 14 18.4477 14 19C14 19.5523 14.4477 20 15 20H19Z"
              fill={props.active ? "#FFFFFF" : "#000000"}
              fillRule="evenodd"
          />
      </svg>
  )
};

const PortfolioIcon = ({...props}:IIcon) => {
  return (
      <svg
          fill="none"
          height="18"
          viewBox="0 0 20 18"
          width="20"
          xmlns="http://www.w3.org/2000/svg"
      >
          <path
              clipRule="evenodd"
              d="M3.99988 6H5.99988H7.99988L11.9999 6H13.9999L15.9999 6H17.9999V8L13.9999 8H5.99988H1.99988L1.99988 6H3.99988ZM12.9999 10V11C12.9999 11.5523 13.4476 12 13.9999 12C14.5522 12 14.9999 11.5523 14.9999 11V10L17.9999 10V14V16H15.9999L3.99988 16H1.99988L1.99988 14L1.99988 9.99999L4.99988 9.99999V11C4.99988 11.5523 5.44759 12 5.99988 12C6.55216 12 6.99988 11.5523 6.99988 11V9.99999L12.9999 10ZM17.9999 4L13.9999 4V3C13.9999 1.34315 12.6567 0 10.9999 0H8.99988C7.34302 0 5.99988 1.34315 5.99988 3V4H1.99988C0.895306 4 -0.00012207 4.89543 -0.00012207 6V16C-0.00012207 17.1046 0.895308 18 1.99988 18L17.9999 18C19.1044 18 19.9999 17.1046 19.9999 16V6C19.9999 4.89543 19.1044 4 17.9999 4ZM10.9999 4H11.9999V3C11.9999 2.44795 11.5525 2.00038 11.0006 2L10.9999 2L8.99988 2L8.99918 2C8.44722 2.00038 7.99988 2.44795 7.99988 3V4H8.99988L9.99988 4H10.9999Z"
              fill={props.active ? "#FFFFFF" : "#000000"}
              fillRule="evenodd"
          />
      </svg>
  )
};

const SkillsIcon = ({...props}:IIcon) => {
  return (
      <svg
          fill="none"
          height="20"
          viewBox="0 0 20 20"
          width="20"
          xmlns="http://www.w3.org/2000/svg"
      >
          <path
              clipRule="evenodd"
              d="M19.9755 4.73604C19.9753 5.28833 19.5275 5.7359 18.9752 5.73572C18.4229 5.73554 17.9753 5.28768 17.9755 4.7354L17.9759 3.41946L10.7082 10.7061C10.3361 11.0792 9.73829 11.0995 9.34171 10.7525L6.04576 7.86856L1.70693 12.2071C1.31639 12.5976 0.683225 12.5976 0.292715 12.207C-0.097795 11.8165 -0.0977723 11.1833 0.292767 10.7928L5.29313 5.79281C5.6654 5.42057 6.26252 5.40069 6.65872 5.74736L9.95372 8.63049L16.5669 2L15.0011 2C14.4489 2 14.0011 1.55229 14.0011 1C14.0011 0.447716 14.4489 2.11254e-06 15.0011 0H18.9767C19.242 1.94396e-06 19.4964 0.105403 19.6839 0.293009C19.8715 0.480614 19.9768 0.735052 19.9767 1.00032L19.9755 4.73604ZM15.0001 11C15.0001 10.4477 15.4478 10 16.0001 10H19.0001C19.5524 10 20.0001 10.4477 20.0001 11C20.0001 11.5523 19.5524 12 19.0001 12H16.0001C15.4478 12 15.0001 11.5523 15.0001 11ZM8.50009 14C7.94781 14 7.50009 14.4477 7.50009 15C7.50009 15.5523 7.94781 16 8.50009 16H11.5001C12.0524 16 12.5001 15.5523 12.5001 15C12.5001 14.4477 12.0524 14 11.5001 14H8.50009ZM9.15457e-05 19C9.15457e-05 18.4477 0.447807 18 1.00009 18H4.00009C4.55238 18 5.00009 18.4477 5.00009 19C5.00009 19.5523 4.55238 20 4.00009 20H1.00009C0.447807 20 9.15457e-05 19.5523 9.15457e-05 19ZM7.50009 19C7.50009 18.4477 7.94781 18 8.50009 18H11.5001C12.0524 18 12.5001 18.4477 12.5001 19C12.5001 19.5523 12.0524 20 11.5001 20H8.50009C7.94781 20 7.50009 19.5523 7.50009 19ZM15.0001 19C15.0001 18.4477 15.4478 18 16.0001 18H19.0001C19.5524 18 20.0001 18.4477 20.0001 19C20.0001 19.5523 19.5524 20 19.0001 20H16.0001C15.4478 20 15.0001 19.5523 15.0001 19ZM16.0001 14C15.4478 14 15.0001 14.4477 15.0001 15C15.0001 15.5523 15.4478 16 16.0001 16H19.0001C19.5524 16 20.0001 15.5523 20.0001 15C20.0001 14.4477 19.5524 14 19.0001 14H16.0001Z"
              fill={props.active ? "#FFFFFF" : "#000000"}
              fillRule="evenodd"
          />
      </svg>
  )
};

export default function UserFormMenu({...props}:IUserFormMenu) {
  const navigate = useNavigate();
  const t = useTranslation().t;
  const isMobileView = useIsMobile()

  const getDashImage = (status: string) => {
    if (status === 'complete'){
      return isMobileView ? "/Assets/dashesCompleteMob.png" : "/Assets/dashesComplete.png"
    }
    return isMobileView ? "/Assets/dashesIncompleteMob.png" : "/Assets/dashesIncomplete.png"
  }

  const getDashes = (index:number) => {
      if(props.pos > (index)){
        return(window.location.origin + getDashImage('complete'))
      }
      else{
        return(window.location.origin + getDashImage('incomplete'))
      }
  }

  return(
    <SMenu>
      <SMenuItem active={props.pos === 1} checked={props.pos > 1} onClick={() => navigate(props.contact_url)}>
        <ContactIcon active={props.pos === 1}/>            
        <SItemLabel active={props.pos === 1}>{t("Menu.Contact")}</SItemLabel>
      </SMenuItem>

      <SignUpMenuDividerImg src={getDashes(1)}/>
        
      <SMenuItem active={props.pos === 2} checked={props.pos > 2} onClick={() => navigate(props.dreamjob_url)}>
        <DreamJobIcon active={props.pos === 2}/>
        <SItemLabel active={props.pos === 2}>{t("Menu.DreamJob")}</SItemLabel>
      </SMenuItem>
        
        <SignUpMenuDividerImg src={getDashes(2)}/>

      <SMenuItem active={props.pos === 3} checked={props.pos > 3} onClick={() => navigate(props.skills_url)}>
        <SkillsIcon active={props.pos === 3}/>
        <SItemLabel active={props.pos === 3}>{t("Menu.Skills")}</SItemLabel>
      </SMenuItem>

      <SignUpMenuDividerImg src={getDashes(3)}/>

      <SMenuItem active={props.pos === 4} checked={props.pos > 4} onClick={() => navigate(props.portfolio_url)}>
        <PortfolioIcon active={props.pos === 4}/>
        <SItemLabel active={props.pos === 4}>{t("Menu.Portfolio")}</SItemLabel>
      </SMenuItem>

      <SignUpMenuDividerImg src={getDashes(4)}/>
      
      <SMenuItem active={props.pos === 5} checked={props.pos > 5} onClick={() => navigate(props.personal_url)} >
        <PersonalIcon active={props.pos === 5}/>
        <SItemLabel active={props.pos === 5}>{t("Menu.Personal")}</SItemLabel>
      </SMenuItem>
    </SMenu>
  )
}