import React, { useContext } from 'react';
import { Col } from 'react-bootstrap';
import styled from 'styled-components';
import SignUpBanner from '../_Menu/SignUpBanner';
import {
  MobiRocket,
  RocketWithTrail,
  StarsMessagePageMob,
  StarsMessagePageWeb,
} from '../svgs'

import { SButton } from "../_UserForm/FormStyle";

const MessagePage = styled.div`
  background: #111111;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: scroll;
  width: 100%;
`

const SMessageWrapper = styled(Col)`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  padding: 0 20px;
  z-index: 2;

  @media (max-width: 769px) {
    text-align: center;
    margin-bottom: 140px;
  }
`

const STitle = styled.div`
  margin-top: 100px;

  font-family: Ubuntu;
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  color: #FFFFFF;
  @media (max-width: 769px) {
    font-size: 24px;
  }
`
const SSubTitleHolder = styled.div`
  display: flex;
  @media (max-width: 769px) {
    flex-direction: column;
  }
`

const SSubTitle = styled.div`
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  color: #FFFFFF;

  @media (max-width: 769px) {
    font-size: 24px;
  }
  
  &:first-child {
    margin-right: 10px;
  }
`
const SMessageHolder = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 42px;
  margin-bottom: 24px;

  @media (max-width: 769px) {
    margin-top: 8px;
  }
`

const SMessage = styled.div`
  font-family: Ubuntu;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: #F2F2F2;
  width: 448px;
  @media (max-width: 769px) {
    font-size: 14px;
    width: 100%;
  }
`

const SMessageButton = styled (SButton)`
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  width: 448px;
  height: 50px;
  
  @media (max-width: 769px) {
    width: 100%;
  }
`

const RocketWithTrailWrapper = styled.div`
  bottom: 150px;
  left: 0;
  position: absolute;
  @media (max-width: 769px) {
    display:  none;
  }
`
const MobiRocketWrapper = styled.div`
  margin-bottom: 45px;
  @media (min-width: 769px) {
    display:  none;
  }
`
const StarsMessagePageWebWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  svg {
    width: 100%;
    height: 100%;
  }
  
  @media (max-width: 769px) {
    display:  none;
  }
`
const StarsMessagePageMobWrapper = styled.div`
  position: absolute;
  
  @media (min-width: 769px) {
    display:  none;
  }
`


export default function MessageContainer({...props}:any) {
  // this is a test comment
  return(
    <MessagePage>
      <StarsMessagePageWebWrapper>
        <StarsMessagePageWeb color={'#FFFFFF'}/>
      </StarsMessagePageWebWrapper>
      <StarsMessagePageMobWrapper>
        <StarsMessagePageMob color={'#FFFFFF'}/>
      </StarsMessagePageMobWrapper>
      <SignUpBanner color={'#FFFFFF'}/>
        {props.children}
      <RocketWithTrailWrapper>
        <RocketWithTrail color={"#FFFFFF"}/>
      </RocketWithTrailWrapper>
    </MessagePage>
  )
}