import React from 'react'
import styled from 'styled-components';
import SignUpBanner from '../_Menu/SignUpBanner';
import UserFormMenu from '../_UserForm/Menu';

const SignUpContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  max-height: 100Vh;
`
const SignUpContainerHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 64px;
  width: 100%;
`

const SignUpContainerCloudsWrapper = styled.div`
  bottom: 0;
  position: fixed;
  width: 100%;

  @media (max-width: 768px) {
    display: none;  
  }
`

const SignUpContainerContent = styled.div`
  // min-height: 604px;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 2;

`

const SignUpContainerCloudLeftOne = styled.img`
  bottom: 0;
  left: 0;
  position: absolute;
  width: 29%;
`
const SignUpContainerCloudLeftTwo = styled.img`
  bottom: 0;
  left: 0;
  position: absolute;
  width: 18%;
`
const SignUpContainerCloudLeftThree = styled.img`
  bottom: 0;
  left: 0;
  position: absolute;
  width: 23%;
`
const SignUpContainerCloudRightOne = styled.img`
  bottom: 0;
  position: absolute;
  right: 0;
  width: 32%;
`
const SignUpContainerCloudRightTwo = styled.img`
  bottom: 0;
  position: absolute;
  right: 0;
  width: 22%;
`
const SignUpContainerCloudRightThree = styled.img`
  bottom: 0;
  position: absolute;
  right: 0;
  width: 20%;
`
const SignUpContainerCloudRightFour = styled.img`
  bottom: 0;
  position: absolute;
  right: 0;
  width: 22%;
`

export default function SignUpContainer({...props}:any) {

  return(
    <>
      <SignUpContainerWrapper>
        <SignUpContainerHeader>
          <SignUpBanner/>  
          <UserFormMenu 
            pos={props.pos}
            contact_url="/signup/contact"
            dreamjob_url="/signup/dreamjob"
            skills_url="/signup/skills"
            portfolio_url="/signup/portfolio"
            personal_url="/signup/personal"
          />
        </SignUpContainerHeader>
        <SignUpContainerContent>
            {props.children}
        </SignUpContainerContent>

        <SignUpContainerCloudsWrapper>

          <SignUpContainerCloudLeftOne src={window.location.origin + "/Assets/signupContainerClouds/leftOne.svg"}/>
          <SignUpContainerCloudLeftTwo src={window.location.origin + "/Assets/signupContainerClouds/leftTwo.svg"}/>
          <SignUpContainerCloudLeftThree src={window.location.origin + "/Assets/signupContainerClouds/leftThree.svg"}/>

          <SignUpContainerCloudRightOne src={window.location.origin + "/Assets/signupContainerClouds/rightOne.svg"}/>
          <SignUpContainerCloudRightTwo src={window.location.origin + "/Assets/signupContainerClouds/rightTwo.svg"}/>
          <SignUpContainerCloudRightThree src={window.location.origin + "/Assets/signupContainerClouds/rightThree.svg"}/>
          <SignUpContainerCloudRightFour src={window.location.origin + "/Assets/signupContainerClouds/rightFour.svg"}/>

        </SignUpContainerCloudsWrapper>
      </SignUpContainerWrapper>
    </>
  )
}