import styled from 'styled-components'
import { Form, Button, InputGroup } from 'react-bootstrap';

export const SInputGroup = styled(InputGroup)`
  background: none;
  border: 0px;
  height: 32px;
  margin-top: 8px;
  width: 100%;

  @media (max-width: 769px) {
    flex-direction: row;
  }
`

export const SControl = styled(Form.Control)<{readOnly:boolean}>`
  background: none; 
  border: none;
  border-bottom: 1px solid ${props => props.readOnly ? "#272751" : "#474747"};
  box-sizing: border-box;
  border-radius: 0px;
  height: 30px;

  padding: 0px 0px 0px 15px; 
  font-family: Ubuntu;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: ${props => props.readOnly ? "#272751" : "#474747"};
  
  margin-bottom: 24px;
  
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  :hover{
    ${props => props.readOnly ? "" : "border-bottom: 1px solid #111111;"}
    ${props => props.readOnly ? "" : "cursor: pointer;"}
    ${props => props.readOnly ? "" : "color: #111111;"}
  }

  :active{
    ${props => props.readOnly ? "" : "border-bottom: 1px solid #111111;"}
    ${props => props.readOnly ? "" : "color: #111111;"}
  }

  :focus{
    ${props => props.readOnly ? "outline: none;" : ""}
  }

  width: 450px;
  @media (max-width: 769px) {
    width: 100%;
  }
  
`

export const SGroupControl = styled(SControl)`
  background: none;
  border: 0px;
  border-bottom: 1px solid #474747;
  height: 48px;
`

export const SGroupIcon = styled(InputGroup.Text)`
  background: none;
  border: 0px;
  height: 32px;
  width: 32px;
  margin-right:4px;
  cursor: pointer;
  font-size: 16px;
  color: #666666;
`

export const SLabel = styled(Form.Label)`
  color: #111111;
  font-family: Ubuntu;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 143.2%;
  margin-right: 32px;
  width: 125px;
  margin-bottom: 0px;
  
  @media (max-width: 769px) {
    width: 100%;
  }
`

export const SFeedback = styled(Form.Control.Feedback)`
  // background: rgba(220,53,69,.9);
  // color: #FFFFFF;
  background-color: transparent;
  color: #FC4236;
  // display: block;
  right: 0;
  top: 25px;
  width: 450px;

  @media (max-width: 769px) {
    top: 45px;
    left: 0px;
  }
`

export const SButton = styled(Button)<{primary:boolean, type:string}>`
  background: ${props => props.primary ? "#111111" : "#FFFFFF"};
  border-radius: 2px;
  border: none;
  color: ${props => props.primary ? "#FFFFFF" : "#111111"};
  font-family: Ubuntu;  
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  height: 50px;
  
  margin-left: auto;
  padding: 12px 0px 12px 0px;
  width: 100%;
  max-width: 610px;
  
  :hover,:active,:focus {
    box-shadow: none !Important;
    outline: none !Important;
  }

  :hover {
    background-color: ${props => props.primary ? "#666666" : "#666666"} !Important;
    border-color: ${props => props.primary ? "#FFFFFF" : "#FFFFFF"} !Important;
    color: ${props => props.primary ? "#FFFFFF" : "#FFFFFF"} !Important;
  }

  :active {
    background-color: ${props => props.primary ? "#111111" : "#FFFFFF"} !Important;
    border-color: ${props => props.primary ? "#FFFFFF" : "#111111"} !Important;
    color: ${props => props.primary ? "#FFFFFF" : "#111111"} !Important;
  }
  :focus {
    background-color: ${props => props.primary ? "#111111" : "#FFFFFF"} !Important;
    border-color: ${props => props.primary ? "#FFFFFF" : "#111111"} !Important;
    color: ${props => props.primary ? "#FFFFFF" : "#111111"} !Important;
  }

  @media (max-width: 769px) {
    margin-left: 0;
    width: 100%;
  }
`


export const SGroup = styled(Form.Group)`
  align-items: baseline;
  display: flex;
  margin-bottom: 32px;
  padding: 0px 10px 0px 10px;
  position: relative;

  @media (max-width: 769px) {
    flex-direction: column;
  }
`