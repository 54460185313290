import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Row, Button, Col } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from "yup";
import { TwitterPicker } from 'react-color';
import { SGroup, SLabel, SControl, SFeedback, SButton } from './FormStyle'
import styled from 'styled-components'
import { useFetchAuth } from '../_Core/AuthHandling';
import { ICard, ICardInfo } from '../Applications/Index'

interface IApplicationInfo {
  id: string,
  item: ICard,
  newItem: Boolean,
  onAddItem: Function
}

export const SInfoInputContainer = styled(Form)`
  margin: 15px;
  padding: 10px 20px 10px;
  
  text-align: left;
`

export const SColorPickerDisplay = styled(Button)`
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 4px;
  height: 30px;
  width: 450px;

  font-family: Ubuntu;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #798CA3;

  @media (max-width: 769px) {
    width: 100%;
  }
`

export const SColorPickerOverlayContainer = styled.div`
  z-index:99;
  position: absolute;
  top: 40px;
  left: 97px;
  width: 209px;

  display: flex;
  flex: 1;

  @media (max-width: 769px) {
    top: 60px;
    left: 12px;
    width: 100%;
  }
`

const SSuccessfulSubmit = styled('span')`
  font-family: Ubuntu;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #00ba32;
`

export default function ApplicationInfo({...props}:IApplicationInfo){
  const t = useTranslation().t;
  const [submitted, setSubmitted] = useState(false)
  const [cPickr, setCPickr] = useState(false);
  const fetchAuth = useFetchAuth();

  const card = props.newItem ? {
    company_name: "",
    title: "",
    url: "",
    salary: "",
    location: "",
    background: "",
    description: ""} as ICardInfo : props.item;
  const opportunity = props.newItem ? false : props.item.type === "Opportunity";

  return(
    <Formik
      initialValues={{
        company_name: card.company_name,
        title: card.title,
        url: card.url,
        salary: card.salary,
        location: card.location,
        background: card.background,
        description: card.description,
      }}
      onSubmit={async (values) => {
        card.company_name = values.company_name
        card.title = values.title
        card.url = values.url
        card.salary = values.salary
        card.location = values.location
        card.background = values.background
        card.description = values.description 

        if(props.newItem) {
          const res = props.onAddItem(card)
          if(res){
            setSubmitted(true)
          }
        }
        else {
          fetchAuth(
            window.__API_URL__ + `/wish`, 
            {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(card),
            }
          )
          .then(res => setSubmitted(true))
        }
      }}
      validationSchema={yup.object().shape({ 
        company_name: yup.string().required(t('Application.Info.CompanyRequiredError')),
        title: yup.string().required(t('Application.Info.TitleRequiredError')),
        url: yup.string().matches(
                /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
                t('Application.Info.UrlInvalidError'))
      })}
    >
      {({
      handleSubmit,
      handleChange,
      handleBlur,
      values,
      touched,
      isValid,
      errors,
      setFieldValue
      }) => (  
        <SInfoInputContainer noValidate onSubmit={handleSubmit}>
          <Row>
            <SGroup as={Col} md="7" controlId="title">
              <SLabel>{t('Application.Info.title')}</SLabel>
              <SControl 
                type="text" 
                name="title" 
                placeholder={opportunity ? t('Base.unknown') : t('Application.Info.titlePlaceholder')}
                value={values.title}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.title && !!errors.title}
                plaintext={opportunity}
                readOnly={opportunity}
                />
              <SFeedback type="invalid" tooltip>{errors.title}</SFeedback>
            </SGroup>
            <SGroup as={Col} md="5" controlId="company_name">
              <SLabel>{t('Application.Info.company_name')}</SLabel>
              <SControl
                type="text" 
                name="company_name" 
                placeholder={opportunity ? t('Base.unknown') : t('Application.Info.companyPlaceholder')}
                value={values.company_name}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.company_name && !!errors.company_name}
                plaintext={opportunity}
                readOnly={opportunity}
                />
              <SFeedback type="invalid" tooltip>{errors.company_name}</SFeedback>
            </SGroup>
          </Row>
          <Row>
            <SGroup as={Col} md="7" controlId="url">
              <SLabel>{t('Application.Info.url')}</SLabel>
              <SControl 
                type="text" 
                name="url" 
                placeholder={opportunity ? t('Base.unknown') : t('Application.Info.urlPlaceholder')}
                value={values.url}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.url && !!errors.url}
                plaintext={opportunity}
                readOnly={opportunity}
                />
              <SFeedback type="invalid" tooltip>{errors.url}</SFeedback>
            </SGroup>
            <SGroup as={Col} md="5" controlId="salary">
              <SLabel>{t('Application.Info.salary')}</SLabel>
              <SControl
                type="text" 
                name="salary" 
                placeholder={opportunity ? t('Base.unknown') : t('Application.Info.salaryPlaceholder')}
                value={values.salary}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.salary && !!errors.salary}
                plaintext={opportunity}
                readOnly={opportunity}
                />
              <SFeedback type="invalid" tooltip>{errors.salary}</SFeedback>
            </SGroup>
          </Row>
          <Row>
            <SGroup as={Col} md="7" controlId="url">
              <SLabel>{t('Application.Info.location')}</SLabel>
              <SControl 
                type="text" 
                name="location" 
                placeholder={opportunity ? t('Base.unknown') : t('Application.Info.locationPlaceholder')}
                value={values.location}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.location && !!errors.location}
                plaintext={opportunity}
                readOnly={opportunity}
                />
              <SFeedback type="invalid" tooltip>{errors.location}</SFeedback>
            </SGroup>
            <SGroup as={Col} md="5" controlId="background">
              <SLabel>{t('Application.Info.background')}</SLabel>
              <SColorPickerDisplay 
                style={{background:values.background}}
                onClick={() => setCPickr(true)}
              >
                {values.background}
              </SColorPickerDisplay>
              {cPickr ? 
                <SColorPickerOverlayContainer>
                  <TwitterPicker 
                  color={values.background}
                  onChange={e => {setFieldValue("background",e.hex)}}
                  onChangeComplete={() => setCPickr(false)}/>
                </SColorPickerOverlayContainer> : <></>}
              <SFeedback type="invalid" tooltip>{errors.salary}</SFeedback>
            </SGroup>
          </Row>
          <Row>
            <SGroup as={Col} md="12" controlId="description">
                <SLabel style={{width:"83.7px"}}>{t('Application.Info.description')}</SLabel>
                <Form.Control 
                  style={{width:"654.3px"}}
                  as="textarea" 
                  rows={5}
                  name="description" 
                  placeholder={opportunity ? t('Base.unknown') : t('Application.Info.descriptionPlaceholder')}
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.description && !!errors.description}
                  plaintext={opportunity}
                  readOnly={opportunity}
                  />
                <SFeedback type="invalid" tooltip>{errors.description}</SFeedback>
              </SGroup>
          </Row>
          <Row>
            <SGroup as={Col} md="6" >
              {submitted ? <SSuccessfulSubmit>{t('Base.submitted')}</SSuccessfulSubmit> : <></>}
            </SGroup>
            <SGroup as={Col} md="6" >
              <SButton primary type="submit">{t('Base.submit')}</SButton>
            </SGroup>
          </Row>
        </SInfoInputContainer>
      )}  
    </Formik>
  )
}