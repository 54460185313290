import React  from 'react'
import _Select from 'react-select';
import { useTranslation } from 'react-i18next';

export const Select = ({...props}:any) => {
  const t = useTranslation().t;

  if(props.readOnly && props.value === ""){
    return(
      <div>{t("Base.empty")}</div>
    )
  }
  else if (props.readOnly) {
    return(
    <div>{props.value}</div>
    )
  }
  else {
    return(
      <_Select {...props}/>
    )
  }
}
