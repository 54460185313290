import React, { useState, useCallback, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Row, Col } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from "yup";
import { SButton, SGroup, SLabel, SControl, SFeedback} from './FormStyle'
import styled from 'styled-components'
import { useFetchAuth } from '../_Core/AuthHandling';
import { ToastContext } from '../_Core/Toast'
import { ICard } from '../Applications/Index'

//test comment merge

interface IActivityData {
  id: string
  title: string
  description: string
  date: string
  done: boolean
}

interface IActivity {
  id: string
  title: string
  description: string
  date: string
  done: boolean
  onDelete: Function
  onDone: Function
}

interface IApplicationActivity {
  id: string
  item: ICard
}

const SActivityInputContainer = styled(Form)`
  margin: 15px;
  padding: 10px 20px 10px;

  background: none; 
  border: none;
  box-sizing: border-box;
  border-radius: 0px;
  
  text-align: left;
`

const SFormListSeperator = styled.div`
  height: 1px;
  background-color: #333333;
  margin: 0px 20px 0px 20px;
`

const SActivityContainer = styled.div`
  margin: 20px 20px 20px; 
  padding: 5px 5px 5px;
  width: calc(100% - 40px);

  display: inline-grid;
  grid-template-columns:  28px auto 90px 90px;
  grid-template-rows: 20px auto;
  grid-template-areas: 
    "check title date delete" 
    "desc desc desc desc";

  background: none; 
  border: solid 1px #000000;
  box-sizing: border-box;
  border-radius: 4px;
`

const SActivityCheck = styled(Form.Check)`
  grid-area: check;
  position: relative;
  margin: 0px 6px 0px;
  font-size: 16px;
`

const SActivityTitle = styled("div")<{done:boolean}>`
  grid-area: title;

  font-family: Ubuntu;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: ${props => props.done ? "#555555" : "#000000"};
  ${props => props.done ? "text-decoration: line-through;" : ""}
`

const SActivityDate = styled("div")<{late:boolean,done:boolean}>`
  grid-area: date;

  background: ${props => props.done ? "#555555" : props.late ? "#eb4034" : "#00CD69"};
  border-radius: 4px;

  font-family: Ubuntu;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #000000;
  text-align: center;
`

const SActivityDelete = styled("div")`
  grid-area: delete;
  margin: 0px 5px 0px;

  background: #b5b5b5;
  border-radius: 4px;

  font-family: Ubuntu;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #000000;
  text-align: center;

  :hover{
    cursor: pointer;
  }
`

const SActivityDescription = styled("div")<{done:boolean}>`
  grid-area: desc;
  margin-top: 5px;
  padding: 0px 28px 0px;

  font-family: Ubuntu;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: ${props => props.done ? "#555555" : "#000000"};
`

const SListSeperator = styled.div`
  height: 34px;
  margin-bottom: 7px;
  margin-top: 7px;
  background-color: #000000;
  width: 1px;
  box-sizing: border-box;
`

const DateRegex = [
  /^([0-3])$/,
  /^$/,
  /^(0[1-9])|([1-2][0-9])|(3[0-1])\.$/,
  /^(((0[1-9])|([1-2][0-9])|(3[0-1]))\.[0-1])$/,
  /^$/,
  /^(((0[1-9])|([1-2][0-9])|(3[0-1]))\.(0[1-9]|1[0-2])\.)$/,
  /^(((0[1-9])|([1-2][0-9])|(3[0-1]))\.(0[1-9]|1[0-2])\.[1-2])$/,
  /^(((0[1-9])|([1-2][0-9])|(3[0-1]))\.(0[1-9]|1[0-2])\.((19)|(20)))$/,
  /^(((0[1-9])|([1-2][0-9])|(3[0-1]))\.(0[1-9]|1[0-2])\.((19)|(20))[0-9])$/,
  /^(((0[1-9])|([1-2][0-9])|(3[0-1]))\.(0[1-9]|1[0-2])\.((19)|(20))[0-9][0-9])$/,
]

const Activity = ({...props}:IActivity) => {
  const t = useTranslation().t;

  return(
    <SActivityContainer>
      <SActivityCheck 
        value={props.done}
        onChange={() => props.onDone()}
        />
      <SActivityTitle 
        done={props.done}
      >
        {props.title}
      </SActivityTitle>
      <SActivityDate 
        done={props.done}
        late={false}
      >
        {props.date}
      </SActivityDate>
      <SActivityDelete onClick={() => props.onDelete()}>
        {t("Base.delete")}
      </SActivityDelete>
      <SActivityDescription 
        done={props.done}
      >
        {props.description}
      </SActivityDescription>
    </SActivityContainer>
  )
}

export default function ApplicationActivities({...props}:IApplicationActivity){
  const t = useTranslation().t;
  const toastHandler = useContext(ToastContext);
  const [activities, setActivities] = useState([] as IActivityData[])
  const [countDone, setCountDone] = useState(props.item.activities.count_done)
  const [countTotal, setCountTotal] = useState(props.item.activities.count_total)
  const fetchAuth = useFetchAuth()

  useEffect(() => {
    const effectAsync = async () => {
      fetchAuth(
        window.__API_URL__ + `/activities?applicationId=${props.id}`, 
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        }
      )
      .then((data: IActivityData[]) => {
        setActivities(data)
      })
    }
    effectAsync()
  }, [])

  const dateSort = (a: any,b: any) => {
    if (a.date > b.date){
      return 1
    }
    else if (a.date < b.date){
      return -1
    }
    else {
      return 0
    }
  } 

  const deleteAsync = async (id:string, list:IActivityData[]) => {
    const listClone = Array.from(list) as IActivityData[];
    const idx = listClone.findIndex((elem) => elem.id === id);
    const [removed] = list.splice(idx, 1);

    return await fetchAuth(
      window.__API_URL__ + `/activity/${id}`, 
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        }
      }
    )
    .then((data:any) => {
      return({code:true, error:"", result:listClone})
    })
    .catch(error => {
      return({code:false, error:"Deleting didn't work please try again ...", result:[]})
    })
  }

  const onDelete = useCallback(async (elem:IActivityData) => {
    const result = await deleteAsync(elem.id, activities)

    if (!result.code){
      toastHandler.showMsg(result.error, true)
    }
    else{
      setActivities(result.result);
      setCountTotal(countTotal - 1)
      props.item.activities.count_total -= 1
      if (elem.done){
        setCountDone(countDone - 1)
        props.item.activities.count_done -= 1
      }
    }

  }, [activities])

  const updateAsync = async (activity:IActivityData) => {
    return await fetchAuth(
      window.__API_URL__ + `/activity/${activity.id}`, 
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(activity),
      }
    )
    .then((data:any) => {
      return({code:true, error:"", result:activity})
    })
    .catch(error => {
      return({code:false, error:"Updating didn't work please try again ...", result:null})
    })
  }

  const onDone = useCallback(async (activity:IActivityData) => {
    activity.done = !activity.done
    
    const result = await updateAsync(activity)

    if (!result.code){
      toastHandler.showMsg(result.error, true)
    }
    else{
      setActivities(activities)
      if (activity.done){
        setCountDone(countDone + 1)
        props.item.activities.count_done += 1
      }
      else {
        setCountDone(countDone - 1)
        props.item.activities.count_done -= 1
      }
    }
  }, [])

  const onSubmit = useCallback(
    (activity:IActivityData) => {
      const activitiesClone = Array.from(activities) as IActivityData[]; 
      activitiesClone.push(activity)
      setActivities(activitiesClone)
      setCountTotal(countTotal + 1)
      props.item.activities.count_total += 1
  }, [activities])

  const openActivitiesDisplay = activities.filter(elem => !elem.done).sort(dateSort).map(elem => {
    return(
      <Activity 
        id={elem.id}
        done={elem.done}
        date={elem.date}
        title={elem.title}
        description={elem.description}
        onDelete = {() => onDelete(elem)}
        onDone = {() => onDone(elem)}/>)
  })

  const doneActivitiesDisplay = activities.filter(elem => elem.done).map(elem => {
    return(
      <Activity 
        id={elem.id}
        done={elem.done}
        date={elem.date}
        title={elem.title}
        description={elem.description}
        onDelete = {() => onDelete(elem)}
        onDone = {() => onDone(elem)}/>)
  })

  return(
    <>
      <Formik
        initialValues={{
          title: '',
          description: '',
          date: '',
        }}
        onSubmit={(values) => {
          const body = {
            applicationId: props.id,
            title: values.title,
            description: values.description,
            date: values.date,
            done: false
          }

          fetchAuth(
            window.__API_URL__ + `/activity/`, 
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(body),
            }
          )
          .then((data:any) => {
            const activity = {
              id: data.id,
              title: values.title,
              description: values.description,
              date: values.date,
              done: false
            } as IActivityData
            
            onSubmit(activity)
          })
        }}
        validationSchema={yup.object().shape({ 
          title: yup.string().required(t('Application.Activities.TitleRequiredError')),
          date: yup.string().required(t('Application.Activities.DateRequiredError'))
                .matches(/^(((0[1-9])|([1-2][0-9])|(3[0-1]))\.(0[1-9]|1[0-2])\.((19)|(20))[0-9][0-9])$/, t('Application.Activities.DateMatchError.'))
        })}
      >
        {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue
        }) => (  
          <SActivityInputContainer noValidate onSubmit={handleSubmit}>
            <Row>
              <SGroup as={Col} md="7" controlId="title">
                <SLabel>{t('Application.Activity.title')}</SLabel>
                <SControl 
                  type="text" 
                  name="title" 
                  placeholder={t('Application.Activity.titlePlaceholder')}
                  value={values.title}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.title && !!errors.title}
                />
                <SFeedback type="invalid" tooltip>{errors.title}</SFeedback>
              </SGroup>
              <SGroup as={Col} md="5" controlId="title">
                <SLabel>{t('Application.Activity.date')}</SLabel>
                <SControl 
                  type="text" 
                  name="date" 
                  placeholder={t('Application.Activity.datePlaceholder')}
                  value={values.date}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.date && !!errors.date}
                  />
                <SFeedback type="invalid" tooltip>{errors.description}</SFeedback>
              </SGroup>
            </Row>
            <Row>
              <SGroup as={Col} md="12" controlId="description">
                <SLabel style={{width:"83.7px"}}>{t('Application.Activity.description')}</SLabel>
                <Form.Control 
                  style={{width:"654.3px"}}
                  as="textarea" 
                  rows={7}
                  name="description" 
                  placeholder={t('Application.Activity.descriptionPlaceholder')}
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  />
              </SGroup>
            </Row>
            <Row>
              <SGroup as={Col} md="6" />
              <SGroup as={Col} md="6" >
                <SButton primary type="submit">{t('Base.submit')} </SButton>
              </SGroup>
            </Row>
          </SActivityInputContainer>
        )}  
      </Formik>
      {countTotal > 0 ?
        <>
          <SFormListSeperator/>
          {openActivitiesDisplay}
        </>
        : <div style={{height:"30px"}}/>
      }
      {countDone > 0 ?
        <>
          <SListSeperator>{t("Application.Activity.Completed")}</SListSeperator>
          {doneActivitiesDisplay}
        </>
        : <></>
      }
      
    </>
  )
}