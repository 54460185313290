import React from 'react'
import Select from 'react-select';
import CountryCodes from './CountryCodes.json'
import { useTranslation } from 'react-i18next';
import { SInputGroup, SGroupText, SGroupIcon, SSelectResult, selectStyles, SSelectContainer } from '../FormStyle';

type MyOptionType = { label: string, value: string }

interface INationality {
  country : string
  label : string
  onDelete : Function
  readOnly : boolean
}

interface INationalitySelectField {
  nationalities: string[]
  setNationalities: Function
  readOnly: boolean
}

const Nationality = ({...props}:INationality) => {
  return(
    <SSelectResult>
      <SInputGroup className="mb-2">
        <SGroupIcon style={{width:"54px"}}>
          <img 
            width="28" 
            height="28" 
            src={window.location.origin + `/Assets/CountryFlags/${props.country}.svg`} 
            onError={(e:any) => {e.target.onerror = null; e.target.src=window.location.origin + `/Assets/CountryFlags/Unknown.svg`}}
            alt=""
          />
        </SGroupIcon>
        <SGroupText style={{width:"calc(100% - 54px - 40px)"}}>{props.label}</SGroupText>
        {!props.readOnly ?
          <SGroupIcon onClick={() => props.onDelete()}><span className="fas fa-times"/></SGroupIcon>
          : <></>
        }
      </SInputGroup>
    </SSelectResult>
  )
}

export const NationalitySelectField = ({...props}:INationalitySelectField) => {
  const t = useTranslation().t;

  const options = CountryCodes.map((code)=>{
    return({ value: code, label: t(`Country.${code}`) })
  })
  
  const nationsDisplay = (props.nationalities.length === 0 && props.readOnly) ? [<div>{t("Base.empty")}</div>] 
    : props.nationalities.map((nationality:string, index:number) => {
      return(
        <Nationality
          key={`nationality_${nationality}`}
          country={nationality}
          label={t(`Country.${nationality}`)} 
          onDelete={async () => {
            let nationality_tmp = [...props.nationalities]
            nationality_tmp.splice(index, 1)
            props.setNationalities(nationality_tmp)
            }
          }
          readOnly={props.readOnly}
        />
      )
    }
  )

  const distabledOptions = new Set()
  props.nationalities.map((elem:string) => {
    distabledOptions.add(elem)
    return true
  })

  return(
    <SSelectContainer style={{marginBottom:"18px"}}>
      {!props.readOnly ?
        <Select 
          styles={selectStyles}
          options={options}
          placeholder={t("ProfilePersonal.nationalityPlaceHolder") as string}
          value={{value:"", label:t('ProfilePersonal.nationalityPlaceHolder')}}
          onChange={
            async (s: any, action: any) => {
              if(action.action==="select-option"){
                let nationality_tmp = [...props.nationalities]
                nationality_tmp.push((s as MyOptionType).value);
                props.setNationalities(nationality_tmp);
              }
            }
          } 
          isOptionDisabled={(option) => distabledOptions.has(option.value)}
        />
        : <></>
      } 
      {nationsDisplay}
    </SSelectContainer>
  )
}