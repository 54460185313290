import React, { useState, useEffect } from 'react'
import { Col, Row } from 'react-bootstrap';
import { SForm, SCol, SGroup, STitle, SSubTitle, SEditButton, SLabel, SControl, SFeedback, SButton } from './FormStyle'
import { Formik } from 'formik';
import * as yup from "yup";
import { useTranslation } from 'react-i18next';
import { FileUploadField, ServerFile, ClientFile } from './Fields/FileUploadField'
import './FormStyle.css';
import { useFetchAuth } from '../_Core/AuthHandling';

//const FILE_SIZE = 10485760
//const FILE_SIZE_MB = Math.round(FILE_SIZE/1048576)
//const SUPPORTED_FORMATS = ["application/pdf"]

interface IUserDetail {
  firstName: string,
  lastName: string,
  telephone: string,
  email: string,
  password?: string,
  files: ServerFile[],
}

const toBase64 = (file:File) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

export default function UserFormContact({...props}:any) {
  const t = useTranslation().t;
  const fetchAuth = useFetchAuth()
  const [readOnly, setReadOnly] = useState(props.readinit === true)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [telephone, setTelephone] = useState('')
  const [email, setEmail] = useState('')
  const [serverFiles, setServerFiles] = useState([] as ServerFile[])
  const [clientFiles, setClientFiles] = useState([] as ClientFile[])


  useEffect(() => {
    const effectAsync = async () => {
      fetchAuth(
        window.__API_URL__ + `/user/0/detail`, 
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        }
      )
      .then(
        (data: IUserDetail) => {
          setFirstName(data.firstName)
          setLastName(data.lastName)
          setTelephone(data.telephone)
          setEmail(data.email)
          setServerFiles(data.files)
        }
      )
      .catch(error => {setReadOnly(true)})
    }
    effectAsync()
  }, [])

  const submitFile = async (file:ClientFile, candidate_id:number) => {
    const body = {
      candidate_id: candidate_id,
      name: file.name,
      content_type: file.type,
      data: await toBase64(file)  
    }
    fetchAuth(
      window.__API_URL__ + '/file', 
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      }
    )
  }

  return (  
    <Formik
      initialValues={{
        firstName: firstName,
        lastName: lastName,
        telephone: telephone,
        email: email,
        files: clientFiles 
      }}
      enableReinitialize={true}
      onSubmit={
        async (values) => {
          const files = values.files  

          const data = await fetchAuth(
            window.__API_URL__ + `/user/0/detail`, 
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(values),
            }
          )
          files.map((file) => submitFile(file, data.id))  
          setReadOnly(true)
          props.submitCallback()
        }
      }
      validationSchema={yup.object().shape({ 
        firstName: yup.string()
          .required(t('ProfileContact.firstNameValidationErrorRequired')), 
        lastName: yup.string()
          .required(t('ProfileContact.lastNameValidationErrorRequired')),   
        telephone: yup.string()
          .required(t('ProfileContact.telephoneValidationErrorRequired'))
          .matches(/(^[+0{2}][0-9\-\s/]+$)/, t('ProfileContact.telephoneValidationErrorMatch')),   
        email: yup.string()
          .required(t('ProfileContact.emailValidationErrorRequired'))
          .email(t('ProfileContact.emailValdationErrorFormat')), 
        //file: yup.mixed().required("You need to provide a CV")
        //  .test('fileSize', t('ProfileContact.cvUploadValidationErrorFileSizeSmall'), function(value){return(value.size > 16)})
        //  .test('fileSize', t('ProfileContact.cvUploadValidationErrorFileSizeLarge') + `${FILE_SIZE_MB}Mb`, function(value){value.size <= FILE_SIZE})
        //  .test('fileType', t('ProfileContact.cvUploadValidationErrorFileType') + `${SUPPORTED_FORMATS}`, function(value){SUPPORTED_FORMATS.includes(value.type)}),
      })}
    >
      {({
      handleSubmit,
      handleChange,
      handleBlur,
      values,
      touched,
      isValid,
      errors,
      setFieldValue,
      setFieldError
      }) => (
        <SForm noValidate onSubmit={handleSubmit}>
          <SCol md="5">
            <STitle>{t('ProfileContact.title')}</STitle>
            <SSubTitle>{t('ProfileContact.subTitle')}</SSubTitle>
            {props.readinit === true ?
              <Row>
                  <SEditButton 
                    className="fas fa-pen"
                    onClick={() => setReadOnly(false)}/> 
              </Row> : 
              <></>
            }
            <SGroup as={Col} md="12" controlId="firstName">
              <SLabel>{t('ProfileContact.firstName')}</SLabel>
              <SControl
                type="text" 
                name="firstName" 
                placeholder={readOnly ? t('Base.empty') : t('ProfileContact.firstNamePlaceHolder')}
                value={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.firstName && !!errors.firstName && !readOnly}
                readOnly={readOnly}
                plaintext={readOnly}
                />
              <SFeedback type="invalid" tooltip>{errors.firstName}</SFeedback>
            </SGroup> 
            <SGroup as={Col} md="12" controlId="lastName">
              <SLabel>{t('ProfileContact.lastName')}</SLabel>
              <SControl 
                type="text" 
                name="lastName" 
                placeholder={readOnly ? t('Base.empty') : t('ProfileContact.lastNamePlaceHolder')}
                value={values.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.lastName && !!errors.lastName && !readOnly}
                readOnly={readOnly}
                plaintext={readOnly}
                />
              <SFeedback type="invalid" tooltip>{errors.lastName}</SFeedback>
            </SGroup>
            <SGroup as={Col} md="12" controlId="telephone">
              <SLabel>{t('ProfileContact.telephone')}</SLabel>
              <SControl 
                type="text" 
                name="telephone" 
                placeholder={readOnly ? t('Base.empty') : t('ProfileContact.telephonePlaceHolder')}
                value={values.telephone}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.telephone && !!errors.telephone && !readOnly}
                readOnly={readOnly}
                plaintext={readOnly}
                />
              <SFeedback type="invalid" tooltip>{errors.telephone}</SFeedback>
            </SGroup>        
            <SGroup as={Col} md="12" controlId="email">
              <SLabel>{t('ProfileContact.email')}</SLabel>
              <SControl
                type="text"
                name="email"
                placeholder={readOnly ? t('Base.empty') : t('ProfileContact.emailPlaceHolder')}
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.email && !!errors.email && !readOnly}
                readOnly={readOnly}
                plaintext={readOnly}
              />
              <SFeedback type="invalid" tooltip>{errors.email}</SFeedback>
            </SGroup>
            <SGroup as={Col} md="12" xs controlid="files">
                <SCol md="3" style={{ alignItems: "baseline", display: "flex", flexWrap: "wrap", margin: "0px", padding: "0px"}}>
                  <SLabel>{t('ProfileContact.cv')}</SLabel>
                </SCol>
                <SCol md="9" style={{ alignItems: "baseline", display: "flex", flexWrap: "wrap", margin: "0px", padding: "0px"}}>
                  <FileUploadField 
                    serverFiles={serverFiles} 
                    setServerFiles={(files:ServerFile[]) => {setServerFiles(files); setFieldValue("files", values.files)}} 
                    clientFiles={clientFiles} 
                    setClientFiles={(files:ClientFile[]) => {setClientFiles(files); setFieldValue("files", files)}} 
                    invalid={touched.files && !!errors.files}
                    error={errors.files as string}
                    readOnly={readOnly}/>
                </SCol>
              </SGroup>
            {!readOnly ?
              <SGroup as={Col} md="12" xs controlid="button" style={{ justifyContent: "flex-end" }}>
                <SCol md="9" style={{ alignItems: "baseline", display: "flex", margin: "0px", padding: "0px"}}>
                  <SButton primary={true} type="submit">{t('Base.submit')}</SButton>
                </SCol> 
              </SGroup>
              :<></>
            }
          </SCol>
        </SForm>
      )}  
    </Formik>
  )
}