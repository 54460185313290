import React, { useContext } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useUserProvider, UserContext } from './_Core/AuthHandling';
import { useToastProvider, ToastContext } from './_Core/Toast'

import VLogin from './SignUp/Login'
import VResetPasswordRequest from './SignUp/ResetPasswordRequest'
import VResetPassword from './SignUp/ResetPassword'
import VSetPassword from './SignUp/SetPassword'
import VSignUp from './SignUp/Index'
import VSignUpContinue from './SignUp/Continue'
import VSignUpContact from './SignUp/Contact'
import VSignUpPortfolio from './SignUp/Portfolio'
import VSignUpDreamJob from './SignUp/DreamJob'
import VSignUpSkills from './SignUp/Skills'
import VSignUpPersonal from './SignUp/Personal'
import VSignUpSuccess from './SignUp/Success';
import VProfileContact from './Profile/Contact'
import VProfilePortfolio from './Profile/Portfolio'
import VProfileDreamJob from './Profile/DreamJob'
import VProfileSkills from './Profile/Skills'
import VProfilePersonal from './Profile/Personal'
import VApplications from './Applications/Index';
import VActivities from './Activities/Index'

export default function App(){
  const userValue = useUserProvider()
  const {Toast, toastValue} = useToastProvider()
  
  return(
    <ToastContext.Provider value={toastValue}>
      <UserContext.Provider value={userValue}>
        <Routing/>
      </UserContext.Provider>
      {Toast}
    </ToastContext.Provider>
  )
}

export function Routing(){
  const user = useContext(UserContext);

  if (!user.isLoggedIn){
    return(
      <Router>
        <Routes>
          <Route path="/resetpasswordrequest" element={<VResetPasswordRequest />} />
          <Route path="/resetpassword" element={<VResetPassword />} />
          <Route path="/setpassword" element={<VSetPassword />} />
          <Route path="/signup" element={<VSignUp/>} />
          <Route path="/*" element={<VLogin/>} />
        </Routes>
      </Router>
    )    
  }

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<VLogin/>} />
        <Route path="/resetpasswordrequest" element={<VResetPasswordRequest />} />
        <Route path="/resetpassword" element={<VResetPassword />} />
        <Route path="/signup/continue" element={<VSignUpContinue />} />
        <Route path="/signup/contact" element={<VSignUpContact />} />  
        <Route path="/signup/dreamJob" element={<VSignUpDreamJob />} />
        <Route path="/signup/skills" element={<VSignUpSkills />} />
        <Route path="/signup/portfolio" element={<VSignUpPortfolio />} />
        <Route path="/signup/personal" element={<VSignUpPersonal />} />
        <Route path="/signup/success" element={<VSignUpSuccess />} /> 
        <Route path="/signup" element={<VSignUp/>} />
        <Route path="/applications" element={<VApplications/>} />
        <Route path="/activities" element={<VActivities />} />
        <Route path="/profile/contact" element={<VProfileContact />} />
        <Route path="/profile/dreamjob" element={<VProfileDreamJob />} />
        <Route path="/profile/skills" element={<VProfileSkills />} />
        <Route path="/profile/portfolio" element={<VProfilePortfolio />} />
        <Route path="/profile/personal" element={<VProfilePersonal />} />
        <Route path="/profile" element={<VProfileContact />} />
        <Route path="/*" element={<VApplications/>} />
      </Routes>
    </Router>
  )
}
