import React, { useContext } from 'react'
import { Col, Row } from 'react-bootstrap';
import { SForm, SCol, SGroup, STitle, SLabel, SControl, SButton } from './FormStyle'
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { ToastContext } from '../_Core/Toast';
import './FormStyle.css';

export default function UserFormResetPasswordRequest({...props}:any) {
  const t = useTranslation().t;
  const toastHandler = useContext(ToastContext);

  return (
    <Formik
      initialValues={{
        email: '',
      }}
      onSubmit={
        async (values) => {
          await fetch(window.__API_URL__ + '/resetPasswordMail', {
            method: 'post',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(values),
          })
          .then(() => {
            toastHandler.showMsg("We send you an email with instructions to change your password. Please check your email.", false, true);
            props.callback();
          })
        }
      }
    >
      {({
      handleSubmit,
      handleChange,
      handleBlur,
      values,
      touched,
      isValid,
      errors,
      setFieldValue
      }) => (  
        <SForm noValidate onSubmit={handleSubmit}>
          <SCol md="5">
            <Row>
              <STitle>{t('ResetPasswordRequest.title')}</STitle>
            </Row>
            <Row>       
              <SGroup as={Col} md="12" controlid="email">
                <SLabel>{t('ProfileContact.email')}</SLabel>
                <SControl
                  type="text"
                  name="email"
                  placeholder={t('ProfileContact.emailPlaceHolder')}
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </SGroup>
              <SGroup as={Col} md="12" >
                <SButton primary={true} type="submit">{t('Base.submit')}</SButton>
              </SGroup>
            </Row>
          </SCol>
        </SForm>
      )}  
    </Formik>
  )
}