import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../_Core/AuthHandling';
import { Col } from 'react-bootstrap';
import styled from 'styled-components';
import { MobiRocket } from '../svgs'
import { SButton } from "../_UserForm/FormStyle";
import MessageContainer from './MessageContainer';

const SContinueWrapper = styled(Col)`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  padding: 0 20px;
  z-index: 2;

  @media (max-width: 769px) {
    text-align: center;
    margin-bottom: 140px;
  }
`

const STitle = styled.div`
  margin-top: 100px;

  font-family: Ubuntu;
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  color: #FFFFFF;
  @media (max-width: 769px) {
    font-size: 24px;
  }
`

const SMessageHolder = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 42px;
  margin-bottom: 24px;

  @media (max-width: 769px) {
    margin-top: 8px;
  }
`

const SMessage = styled.div`
  font-family: Ubuntu;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: #F2F2F2;
  width: 448px;
  @media (max-width: 769px) {
    font-size: 18px;
    width: 100%;
  }
`

const SContinueButton = styled (SButton)`
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  width: 448px;
  height: 50px;
  
  @media (max-width: 769px) {
    width: 100%;
  }
`

const MobiRocketWrapper = styled.div`
  margin-bottom: 45px;
  @media (min-width: 769px) {
    display:  none;
  }
`
const SContinueButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 448px;

  @media (max-width: 769px) {
    width: 100%;
  }
`


export default function VSignUpContinue() {
  const t = useTranslation().t;
  const navigate = useNavigate();
  const user = useContext(UserContext);
// this is a test comment
  return(
    <MessageContainer>
      <SContinueWrapper md="10" >
        <STitle>{user.firstName} {t('ProfileSuccess.title')}</STitle>
        <SMessageHolder>
          <SMessage>{t('ProfileSuccess.message')}</SMessage>
          <SMessage>{t('ProfileSuccess.message2')}</SMessage>
        </SMessageHolder>
        <MobiRocketWrapper>
          <MobiRocket color={"#FFFFFF"}/>
        </MobiRocketWrapper>
        <SContinueButtonWrapper>
          <SContinueButton  onClick={() => navigate('/')}>{t("ProfileSuccess.next")}</SContinueButton>
        </SContinueButtonWrapper>
      </SContinueWrapper>
    </MessageContainer>
  )
}