import React from 'react'
import { SFeedback, SFileLabel, SSelectResult, SInputGroup, SGroupIcon, SGroupText } from '../FormStyle'
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import { useFetchAuth } from '../../_Core/AuthHandling';

export type ServerFile = {
    id: number,
    content_type: string,
    name: string,
}

export type ClientFile = File

interface IFileUploadField {
    serverFiles: ServerFile[],
    setServerFiles: Function,
    clientFiles: ClientFile [],
    setClientFiles: Function,
    readOnly: boolean,
    invalid?: boolean,
    error?: string,
  }

interface IFileUpload {
    label: string
    onDelete: Function
    readOnly: boolean
}

const FileTypes = [
    "application/pdf",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/msword",
    "application/vnd.oasis.opendocument.text",
    "application/x-iwork-pages-sffpages"
]


const FileUpload = ({...props}:IFileUpload) => {
    return (
        <SSelectResult>
            <SInputGroup className="mb-2">
                <SGroupText style={{width:"calc(100% - 40px)"}}>{props.label}</SGroupText>
                {!props.readOnly ?
                    <SGroupIcon onClick={() => props.onDelete()}><span className="fas fa-times"/></SGroupIcon>
                    : <></>
                }
            </SInputGroup>
        </SSelectResult>
    )
}    



export const FileUploadField = ({...props}:IFileUploadField) => {
    const t = useTranslation().t;
    const fetchAuth = useFetchAuth()

    const deleteServerFile = async (file_id:number) => {
        return await fetchAuth(
          window.__API_URL__ + `/file/${file_id}`, 
          {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({id:file_id})
          }
        ).then(() => {return (true)}, () => {return (false)})
    }

    const onDeleteServerFile = async (file_id:number, index:number) => {
        const deleted = await deleteServerFile(file_id)

        if (deleted){
            const files = props.serverFiles
            files.splice(index,1)
            props.setServerFiles(files)
        }
    }

    const onDeleteClientFile = (index:number) => {
        const files = props.clientFiles
        files.splice(index,1)
        props.setClientFiles(files)
    }

    const ServerFilesList = props.serverFiles.map((elem:any, index:number) => {return(
        <FileUpload label={elem.name} onDelete={() => {onDeleteServerFile(elem.id, index)}} readOnly={props.readOnly}/>
    )})
    const ClientFilesList = props.clientFiles.map((elem:any, index:number) => {return(
        <FileUpload label={elem.name} onDelete={() => {onDeleteClientFile(elem.id)}} readOnly={props.readOnly}/>
    )})

    const onChangeClientFiles = (event:React.ChangeEvent<HTMLInputElement>) => {
        const newFiles = props.clientFiles
        const length = event.currentTarget.files?.length === undefined ? 0 : event.currentTarget.files?.length
        for (let fix=0; fix < length; fix++){
            const _file = event.currentTarget.files?.item(fix)
            if (FileTypes.includes(_file?.type as string)){
                newFiles.push(_file as File)
            }
            else {
                //let ers: FormikErrors<IValues> = {};
                //ers.files = `${_file?.name} is not an allowed file type. Please use a file of type .pdf, .doc, .docx, .pages, .odt.`;
                //setErrors(ers);
            }
        }
        props.setClientFiles(newFiles)
    }

    //
    return(
        <>
            {props.readOnly ? <></> :
            <SFileLabel>
                <Form.Control
                    type="file"
                    name="files"
                    multiple={true}
                    placeholder={t('ProfileContact.filePlaceHolder')}
                    onChange={onChangeClientFiles}
                />
                {t('ProfileContact.files')}
            </SFileLabel> 
            }
            {props.invalid ? <SFeedback style={{"display":"block", "top":"45px"}} type="invalid" tooltip>{props.error}</SFeedback> : <></>}
            {ServerFilesList}
            {ClientFilesList}
        </>
    )
}
