import React from 'react';
import { useNavigate } from 'react-router-dom';
import UserFormDreamJob from '../_UserForm/DreamJob'
import SignUpContainer from './SignUpContainer';

export default function VSignUpDreamJob({...props}:any) {
  const navigate = useNavigate();

  return(
    <>
      <SignUpContainer pos={2}>
        <UserFormDreamJob 
          submitCallback={() => navigate(`/signup/skills`)}
          returnCallback={() => navigate(`/signup/contact`)}/>
      </SignUpContainer>
    </>
  )
}