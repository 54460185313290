import React from 'react';
import { useNavigate } from 'react-router-dom';
import UserFormSkills from '../_UserForm/Skills'
import SignUpContainer from './SignUpContainer';

export default function VSignUpSkills({...props}:any) {
  const navigate = useNavigate();

  return(
    <>
      <SignUpContainer pos={3}>
        <UserFormSkills 
          submitCallback={() => navigate(`/signup/portfolio`)}
          returnCallback={() => navigate(`/signup/dreamjob`)}/>
      </SignUpContainer>
    </>
  )
}