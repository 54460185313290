import React, { useState, useEffect } from 'react'
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap'
import { SForm, SCol, SGroup, STitle, SSubTitle, SEditButton, SButton, SLabel,  SControl, SCheckControl, SFeedback, SCheckContainer } from './FormStyle'
import styled from 'styled-components'
import './FormStyle.css';
import { useFetchAuth } from '../_Core/AuthHandling';


interface IPortfolio {
  type: string
  url: string
}
interface IUserPortfolios {
  profiles: Array<IPortfolio>
}

const SColIcon = styled(Col)`
  margin-right: auto;
  padding-left: 20px;
  padding-right: 25px;
`

const SIcon =styled.div`
  font-size: 28px;
  color: #b5b5b5;
  height: 48px;
  width: 36px; 
  margin-right:24px;
`

export default function UserFormPortfolios({...props}:any){
  const t = useTranslation().t;
  const fetchAuth = useFetchAuth()
  const [readOnly, setReadOnly] = useState(props.readinit === true)
  const [gitActive, setGitActive] = useState(true)
  const [git, setGit] = useState("")
  const [linkedinActive, setLinkedinActive] = useState(true)
  const [linkedin, setLinkedin] = useState("")
  const [stackoverflowActive, setStackoverflowActive] = useState(true)
  const [stackoverflow, setStackoverflow] = useState("")
  const [researchgateActive, setResearchgateActive] = useState(false)
  const [researchgate, setResearchgate] = useState("")
  const [websiteActive, setWebsiteActive] = useState(false)
  const [website, setWebsite] = useState("")
  const [otherActive, setOtherActive] = useState(false)
  const [other, setOther] = useState("")

  useEffect(() => {
    const effectAsync = async () => {
      fetchAuth(
        window.__API_URL__ + `/user/0/profiles`, 
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        }
      )
      .then(
        (data: IUserPortfolios) => {
          const _git = data.profiles.find(elem => {return elem.type === "git"});
          setGit(_git ? _git?.url : "");
          
          const _linkedin = data.profiles.find(elem => {return elem.type === "linkedin"});
          setLinkedin(_linkedin ? _linkedin?.url : "");
          
          const _stackoverflow = data.profiles.find(elem => {return elem.type === "stackoverflow"});
          setStackoverflow(_stackoverflow ? _stackoverflow?.url : "");
          
          const _researchgate = data.profiles.find(elem => {return elem.type === "researchgate"});
          setResearchgateActive(_researchgate ? _researchgate?.url !== "" : false);
          setResearchgate(_researchgate ? _researchgate?.url : "");
          
          const _website = data.profiles.find(elem => {return elem.type === "website"});
          setWebsiteActive(_website ? _website?.url !== "" : false);
          setWebsite(_website ? _website?.url : "");
          
          const _other = data.profiles.find(elem => {return elem.type === "other"});
          setOtherActive(_other ? _other?.url !== "" : false);
          setOther(_other ? _other?.url : "");
        }
      )
      .catch(error => {setReadOnly(true)})
    }
    effectAsync()
  }, [])

  //TODO check if url is linkedin,github, ...
  return(
    <Formik
      initialValues={{
        git: git,
        linkedin: linkedin,
        stackoverflow: stackoverflow,
        researchgate: researchgate,
        website: website,
        other: other
      }}
      enableReinitialize={true}
      onSubmit={async (values) => { 
        await fetchAuth(
          window.__API_URL__ + `/user/0/profiles`, 
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            profiles:[
              {type:"git", url:values.git},
              {type:"linkedin", url:values.linkedin},
              {type:"stackoverflow", url:values.stackoverflow},
              {type:"researchgate", url:values.researchgate},
              {type:"website", url:values.website},
              {type:"other", url:values.other},
            ]
          }),
        })
        .then(data => {setReadOnly(true); props.submitCallback(); return data})
        }
      }
    >
      {({
      handleSubmit,
      handleChange,
      handleBlur,
      values,
      touched,
      isValid,
      errors,
      setFieldValue
      }) => (  
        <SForm noValidate onSubmit={handleSubmit}>
          <SCol md="5">
            <STitle>{t('ProfileProfiles.title')}</STitle>
            <SSubTitle>{t('ProfileProfiles.subTitle')}</SSubTitle>
            {props.readinit === true ? 
              <Row>
                <SEditButton 
                  className="fas fa-pen"
                  onClick={() => setReadOnly(false)}/> 
              </Row> : <></>
            }
            {readOnly ? <></> :
              <SCol style={{display: "flex", flexDirection: "row", flexWrap:"wrap", justifyContent:"space-between", padding:"0px", marginBottom:"36px"}} md="12">
                <SCheckContainer>
                  <SCheckControl 
                    type="checkbox"
                    name="gitActive"
                    checked={gitActive}
                    onChange={() => {setGitActive(!gitActive); if(gitActive){setGit(""); values.git=git}}}
                    readOnly={readOnly}
                    />
                  <SIcon className="fab fa-github"/>
                </SCheckContainer>
                <SCheckContainer>
                  <SCheckControl 
                    type="checkbox"
                    name="linkedinActive"
                    checked={linkedinActive}
                    onChange={() => {setLinkedinActive(!linkedinActive); if(linkedinActive){setLinkedin(""); values.linkedin=linkedin}}}
                    readOnly={readOnly}
                    />
                  <SIcon className="fab fa-linkedin"/>
                </SCheckContainer>
                <SCheckContainer>
                  <SCheckControl 
                    type="checkbox"
                    name="stackoverflowActive"
                    checked={stackoverflowActive}
                    onChange={() => {setStackoverflowActive(!stackoverflowActive); if(stackoverflowActive){setStackoverflow(""); values.stackoverflow=stackoverflow}}}
                    readOnly={readOnly}
                    />
                  <SIcon className="fab fa-stack-overflow"/>
                </SCheckContainer>
                <SCheckContainer>
                  <SCheckControl 
                    type="checkbox"
                    name="researchgateActive"
                    checked={researchgateActive}
                    onChange={() => {if(researchgateActive){setResearchgate("")}; setResearchgateActive(!researchgateActive); values.researchgate=researchgate}}
                    readOnly={readOnly}
                    />
                  <SIcon className="fab fa-researchgate"/>
                </SCheckContainer>
                <SCheckContainer>
                  <SCheckControl 
                    type="checkbox"
                    name="websiteActive"
                    checked={websiteActive}
                    onChange={() => {setWebsiteActive(!websiteActive); if(websiteActive){setWebsite(""); values.website=website}}}
                    readOnly={readOnly}
                    />
                  <SIcon className="fab fa-chrome"/>
                </SCheckContainer>
                <SCheckContainer>
                  <SCheckControl 
                    type="checkbox"
                    name="otherActive"
                    checked={otherActive}
                    onChange={() => {setOtherActive(!otherActive); if(otherActive){setOther(""); values.other=other}}}
                    readOnly={readOnly}
                    />
                  <SIcon className="fas fa-question"/>
                </SCheckContainer>
              </SCol>
            }
            {!gitActive ? <></> :
              <SGroup as={Col} md="12" controlid="git">
                <SLabel>{t('ProfileProfiles.git')}</SLabel>
                <SControl 
                  type="text"
                  name="git"
                  placeholder={t('ProfileProfiles.gitPlaceHolder')}
                  value={values.git}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.git && !!errors.git}
                  readOnly={readOnly}
                  plaintext={readOnly}
                  />
                <SFeedback type="invalid" tooltip>{errors.git}</SFeedback>
              </SGroup>
            }
            {!linkedinActive ? <></> :
              <SGroup as={Col} md="12" controlid="linkedin">
                <SLabel>{t('ProfileProfiles.linkedin')}</SLabel>
                <SControl 
                  type="text"
                  name="linkedin"
                  placeholder={t('ProfileProfiles.linkedinPlaceHolder')}
                  value={values.linkedin}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.linkedin && !!errors.linkedin}
                  readOnly={readOnly}
                  plaintext={readOnly}
                  />
                <SFeedback type="invalid" tooltip>{errors.linkedin}</SFeedback>
              </SGroup>
            }
            {!stackoverflowActive ? <></> :
              <SGroup as={Col} md="12" controlid="stackoverflow">
                <SLabel>{t('ProfileProfiles.stackoverflow')}</SLabel>
                <SControl 
                  type="text"
                  name="stackoverflow"
                  placeholder={t('ProfileProfiles.stackoverflowPlaceHolder')}
                  value={values.stackoverflow}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.stackoverflow && !!errors.stackoverflow}
                  readOnly={readOnly}
                  plaintext={readOnly}
                  />
                <SFeedback type="invalid" tooltip>{errors.stackoverflow}</SFeedback>
              </SGroup>
            }
            {!researchgateActive ? <></> :
              <SGroup as={Col} md="12" controlid="researchgate">
                <SLabel>{t('ProfileProfiles.researchgate')}</SLabel>
                <SControl 
                  type="text"
                  name="researchgate"
                  placeholder={t('ProfileProfiles.researchgatePlaceHolder')}
                  value={values.researchgate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.researchgate && !!errors.researchgate}
                  readOnly={readOnly}
                  plaintext={readOnly}
                  />
                <SFeedback type="invalid" tooltip>{errors.researchgate}</SFeedback>
              </SGroup>
            }
            {!websiteActive ? <></> :
              <SGroup as={Col} md="12" controlid="website">
                <SLabel>{t('ProfileProfiles.website')}</SLabel>
                <SControl 
                  type="text"
                  name="website"
                  placeholder={t('ProfileProfiles.websitePlaceHolder')}
                  value={values.website}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.website && !!errors.website}
                  readOnly={readOnly}
                  plaintext={readOnly}
                  />
                <SFeedback type="invalid" tooltip>{errors.website}</SFeedback>
              </SGroup>
            }
            {!otherActive ? <></> :
              <SGroup as={Col} md="12" controlid="other">
                <SLabel>{t('ProfileProfiles.other')}</SLabel>
                <SControl 
                  type="text"
                  name="other"
                  placeholder={t('ProfileProfiles.otherPlaceHolder')}
                  value={values.other}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.other && !!errors.other}
                  readOnly={readOnly}
                  plaintext={readOnly}
                  />
                <SFeedback type="invalid" tooltip>{errors.other}</SFeedback>
              </SGroup>
            }
            {!readOnly ?
              <SGroup as={Col} md="12" xs controlid="button" style={{ justifyContent: "flex-end" }}>
                <SCol md="9" style={{ alignItems: "baseline", display: "flex", margin: "0px", padding: "0px"}}>
                  <SButton primary={true} type="submit">{t('Base.submit')}</SButton>
                </SCol> 
              </SGroup>
              :<></>
            }
          </SCol> 
        </SForm>
      )}  
    </Formik>
  )

}