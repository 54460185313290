import React from 'react'

const StarsMessagePageMob = ({ color }) => {
    return (
        <svg width="370" height="848" viewBox="0 0 370 848" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.5">
            <path d="M330.329 84.2771C330.734 84.69 331.1 85.0624 331.426 85.4024C331.751 85.7425 332.093 86.1554 332.451 86.6331L331.441 87.378C331.275 87.1351 331.124 86.9003 330.988 86.6817C330.853 86.455 330.726 86.2364 330.607 86.0097C330.488 85.783 330.376 85.5563 330.257 85.3215C330.146 85.0867 330.019 84.8438 329.892 84.5847C329.765 84.8357 329.637 85.0867 329.526 85.3215C329.415 85.5563 329.296 85.7911 329.176 86.0097C329.057 86.2364 328.93 86.455 328.795 86.6817C328.66 86.9084 328.509 87.1351 328.342 87.378L327.332 86.6331C327.507 86.3902 327.682 86.1716 327.849 85.9692C328.016 85.7668 328.183 85.5806 328.35 85.3944C328.517 85.2162 328.692 85.03 328.874 84.8519C329.057 84.6738 329.248 84.4795 329.455 84.269C328.898 84.188 328.382 84.0989 327.913 84.018C327.444 83.937 326.935 83.7994 326.387 83.6213L326.76 82.4392C327.046 82.5363 327.309 82.6335 327.547 82.7307C327.786 82.8278 328.016 82.9331 328.239 83.0383C328.461 83.1436 328.692 83.2569 328.914 83.3703C329.137 83.4836 329.375 83.6051 329.629 83.7265C329.582 83.435 329.534 83.1679 329.494 82.9088C329.455 82.6578 329.415 82.4068 329.391 82.1558C329.359 81.9048 329.335 81.6538 329.319 81.3948C329.304 81.1357 329.296 80.8523 329.296 80.5527H330.504C330.504 80.8523 330.496 81.1357 330.48 81.3948C330.464 81.6538 330.44 81.9048 330.408 82.1558C330.376 82.4068 330.345 82.6578 330.305 82.9088C330.265 83.1598 330.218 83.435 330.17 83.7265C330.416 83.6051 330.655 83.4917 330.885 83.3703C331.108 83.2569 331.338 83.1436 331.561 83.0383C331.783 82.9331 332.014 82.8278 332.252 82.7307C332.491 82.6335 332.753 82.5363 333.039 82.4392L333.413 83.6213C332.856 83.7994 332.34 83.937 331.871 84.018C331.402 84.107 330.885 84.188 330.329 84.2771Z" fill="#F2F2F2"/>
            <path d="M3.94208 251.027C4.34741 251.44 4.713 251.812 5.03885 252.152C5.36471 252.492 5.70647 252.905 6.06412 253.383L5.05475 254.128C4.88785 253.885 4.73685 253.65 4.60174 253.432C4.46663 253.205 4.33945 252.986 4.22024 252.76C4.10102 252.533 3.98975 252.306 3.87054 252.071C3.75927 251.837 3.63211 251.594 3.50495 251.335C3.37778 251.586 3.25062 251.837 3.13935 252.071C3.02808 252.306 2.90887 252.541 2.78966 252.76C2.67044 252.986 2.54327 253.205 2.40816 253.432C2.27305 253.658 2.12205 253.885 1.95515 254.128L0.945781 253.383C1.12063 253.14 1.29548 252.922 1.46238 252.719C1.62928 252.517 1.79619 252.331 1.96309 252.144C2.12999 251.966 2.30484 251.78 2.48764 251.602C2.67044 251.424 2.86118 251.229 3.06782 251.019C2.51148 250.938 1.99488 250.849 1.52596 250.768C1.05705 250.687 0.548393 250.549 0 250.371L0.373544 249.189C0.659662 249.286 0.921941 249.383 1.16037 249.481C1.3988 249.578 1.62928 249.683 1.85182 249.788C2.07436 249.894 2.30485 250.007 2.52738 250.12C2.74992 250.234 2.98834 250.355 3.24267 250.477C3.19499 250.185 3.1473 249.918 3.10756 249.659C3.06782 249.408 3.02808 249.157 3.00424 248.906C2.97245 248.655 2.94861 248.404 2.93271 248.145C2.91682 247.886 2.90887 247.602 2.90887 247.303H4.11693C4.11693 247.602 4.10898 247.886 4.09308 248.145C4.07719 248.404 4.05334 248.655 4.02155 248.906C3.98975 249.157 3.95797 249.408 3.91823 249.659C3.87849 249.91 3.83081 250.185 3.78312 250.477C4.0295 250.355 4.26793 250.242 4.49841 250.12C4.72095 250.007 4.95144 249.894 5.17397 249.788C5.39651 249.683 5.62699 249.578 5.86542 249.481C6.10385 249.383 6.36612 249.286 6.65224 249.189L7.02578 250.371C6.46944 250.549 5.95285 250.687 5.48394 250.768C5.01502 250.857 4.49842 250.938 3.94208 251.027Z" fill="#F2F2F2"/>
            <path d="M15.5192 366.532C15.9246 366.945 16.2901 367.317 16.616 367.657C16.9419 367.997 17.2836 368.41 17.6413 368.888L16.6319 369.633C16.465 369.39 16.314 369.155 16.1789 368.937C16.0438 368.71 15.9166 368.491 15.7974 368.265C15.6782 368.038 15.5669 367.811 15.4477 367.576C15.3364 367.342 15.2093 367.099 15.0821 366.84C14.9549 367.091 14.8278 367.342 14.7165 367.576C14.6052 367.811 14.486 368.046 14.3668 368.265C14.2476 368.491 14.1204 368.71 13.9853 368.937C13.8502 369.163 13.6992 369.39 13.5323 369.633L12.5229 368.888C12.6978 368.645 12.8726 368.426 13.0395 368.224C13.2064 368.022 13.3733 367.835 13.5402 367.649C13.7071 367.471 13.882 367.285 14.0648 367.107C14.2476 366.929 14.4383 366.734 14.645 366.524C14.0886 366.443 13.572 366.354 13.1031 366.273C12.6342 366.192 12.1255 366.054 11.5771 365.876L11.9507 364.694C12.2368 364.791 12.4991 364.888 12.7375 364.986C12.976 365.083 13.2064 365.188 13.429 365.293C13.6515 365.398 13.882 365.512 14.1045 365.625C14.3271 365.738 14.5655 365.86 14.8198 365.981C14.7721 365.69 14.7244 365.423 14.6847 365.164C14.645 364.913 14.6052 364.662 14.5814 364.411C14.5496 364.16 14.5258 363.909 14.5099 363.65C14.494 363.391 14.486 363.107 14.486 362.808H15.6941C15.6941 363.107 15.6861 363.391 15.6702 363.65C15.6543 363.909 15.6305 364.16 15.5987 364.411C15.5669 364.662 15.5351 364.913 15.4954 365.164C15.4556 365.415 15.408 365.69 15.3603 365.981C15.6067 365.86 15.8451 365.747 16.0756 365.625C16.2981 365.512 16.5286 365.398 16.7511 365.293C16.9737 365.188 17.2041 365.083 17.4426 364.986C17.681 364.888 17.9433 364.791 18.2294 364.694L18.6029 365.876C18.0466 366.054 17.53 366.192 17.0611 366.273C16.5922 366.362 16.0756 366.443 15.5192 366.532Z" fill="#F2F2F2"/>
            <path d="M116.71 596.951C117.115 597.364 117.481 597.736 117.806 598.076C118.132 598.416 118.474 598.829 118.832 599.307L117.822 600.052C117.655 599.809 117.504 599.574 117.369 599.355C117.234 599.129 117.107 598.91 116.988 598.683C116.869 598.457 116.757 598.23 116.638 597.995C116.527 597.761 116.4 597.518 116.273 597.259C116.145 597.51 116.018 597.761 115.907 597.995C115.796 598.23 115.676 598.465 115.557 598.683C115.438 598.91 115.311 599.129 115.176 599.355C115.041 599.582 114.89 599.809 114.723 600.052L113.713 599.307C113.888 599.064 114.063 598.845 114.23 598.643C114.397 598.441 114.564 598.254 114.731 598.068C114.898 597.89 115.072 597.704 115.255 597.526C115.438 597.348 115.629 597.153 115.835 596.943C115.279 596.862 114.762 596.773 114.294 596.692C113.825 596.611 113.316 596.473 112.768 596.295L113.141 595.113C113.427 595.21 113.69 595.307 113.928 595.404C114.166 595.502 114.397 595.607 114.619 595.712C114.842 595.817 115.072 595.931 115.295 596.044C115.517 596.157 115.756 596.279 116.01 596.4C115.963 596.109 115.915 595.842 115.875 595.583C115.835 595.332 115.796 595.081 115.772 594.83C115.74 594.579 115.716 594.328 115.7 594.069C115.684 593.81 115.676 593.526 115.676 593.227H116.885C116.885 593.526 116.877 593.81 116.861 594.069C116.845 594.328 116.821 594.579 116.789 594.83C116.757 595.081 116.726 595.332 116.686 595.583C116.646 595.834 116.598 596.109 116.551 596.4C116.797 596.279 117.036 596.166 117.266 596.044C117.489 595.931 117.719 595.817 117.942 595.712C118.164 595.607 118.395 595.502 118.633 595.404C118.871 595.307 119.134 595.21 119.42 595.113L119.793 596.295C119.237 596.473 118.72 596.611 118.252 596.692C117.783 596.781 117.266 596.862 116.71 596.951Z" fill="#F2F2F2"/>
            <path d="M231.353 347.395C231.582 347.591 231.789 347.768 231.973 347.929C232.157 348.09 232.351 348.286 232.553 348.513L231.982 348.866C231.888 348.751 231.802 348.64 231.726 348.536C231.649 348.429 231.577 348.325 231.51 348.217C231.443 348.11 231.38 348.002 231.312 347.891C231.249 347.779 231.177 347.664 231.105 347.541C231.034 347.66 230.962 347.779 230.899 347.891C230.836 348.002 230.768 348.113 230.701 348.217C230.633 348.325 230.562 348.429 230.485 348.536C230.409 348.644 230.323 348.751 230.229 348.866L229.658 348.513C229.757 348.398 229.856 348.294 229.95 348.198C230.045 348.102 230.139 348.014 230.233 347.925C230.328 347.841 230.427 347.752 230.53 347.668C230.633 347.583 230.741 347.491 230.858 347.391C230.544 347.353 230.251 347.311 229.986 347.272C229.721 347.234 229.433 347.168 229.123 347.084L229.334 346.523C229.496 346.569 229.645 346.615 229.779 346.661C229.914 346.707 230.045 346.757 230.17 346.807C230.296 346.857 230.427 346.911 230.553 346.965C230.678 347.019 230.813 347.076 230.957 347.134C230.93 346.996 230.903 346.869 230.881 346.746C230.858 346.627 230.836 346.508 230.822 346.389C230.804 346.269 230.791 346.15 230.782 346.027C230.773 345.905 230.768 345.77 230.768 345.628H231.452C231.452 345.77 231.447 345.905 231.438 346.027C231.429 346.15 231.416 346.269 231.398 346.389C231.38 346.508 231.362 346.627 231.339 346.746C231.317 346.865 231.29 346.996 231.263 347.134C231.402 347.076 231.537 347.022 231.667 346.965C231.793 346.911 231.924 346.857 232.05 346.807C232.175 346.757 232.306 346.707 232.441 346.661C232.575 346.615 232.724 346.569 232.886 346.523L233.097 347.084C232.782 347.168 232.49 347.234 232.225 347.272C231.96 347.314 231.667 347.353 231.353 347.395Z" fill="#F2F2F2"/>
            <path d="M368.256 520.209C368.485 520.405 368.692 520.581 368.876 520.743C369.061 520.904 369.254 521.1 369.456 521.327L368.885 521.68C368.791 521.565 368.706 521.453 368.629 521.35C368.553 521.242 368.481 521.138 368.413 521.031C368.346 520.923 368.283 520.816 368.216 520.704C368.153 520.593 368.081 520.477 368.009 520.355C367.937 520.474 367.865 520.593 367.802 520.704C367.739 520.816 367.672 520.927 367.604 521.031C367.537 521.138 367.465 521.242 367.388 521.35C367.312 521.457 367.227 521.565 367.132 521.68L366.561 521.327C366.66 521.211 366.759 521.108 366.854 521.011C366.948 520.915 367.042 520.827 367.137 520.739C367.231 520.654 367.33 520.566 367.433 520.481C367.537 520.397 367.645 520.305 367.762 520.205C367.447 520.166 367.155 520.124 366.889 520.086C366.624 520.047 366.337 519.982 366.026 519.897L366.238 519.337C366.399 519.383 366.548 519.429 366.683 519.475C366.818 519.521 366.948 519.571 367.074 519.621C367.2 519.671 367.33 519.725 367.456 519.778C367.582 519.832 367.717 519.89 367.86 519.947C367.833 519.809 367.807 519.682 367.784 519.559C367.762 519.44 367.739 519.321 367.726 519.202C367.708 519.083 367.694 518.964 367.685 518.841C367.676 518.718 367.672 518.584 367.672 518.441H368.355C368.355 518.584 368.35 518.718 368.341 518.841C368.332 518.964 368.319 519.083 368.301 519.202C368.283 519.321 368.265 519.44 368.243 519.559C368.22 519.678 368.193 519.809 368.166 519.947C368.305 519.89 368.44 519.836 368.571 519.778C368.697 519.725 368.827 519.671 368.953 519.621C369.079 519.571 369.209 519.521 369.344 519.475C369.479 519.429 369.627 519.383 369.789 519.337L370 519.897C369.686 519.982 369.393 520.047 369.128 520.086C368.863 520.128 368.571 520.166 368.256 520.209Z" fill="#F2F2F2"/>
            <path d="M239.288 515.156C239.518 515.352 239.724 515.528 239.909 515.69C240.093 515.851 240.286 516.047 240.489 516.274L239.918 516.627C239.823 516.512 239.738 516.401 239.661 516.297C239.585 516.189 239.513 516.086 239.446 515.978C239.378 515.87 239.315 515.763 239.248 515.651C239.185 515.54 239.113 515.425 239.041 515.302C238.969 515.421 238.897 515.54 238.834 515.651C238.771 515.763 238.704 515.874 238.636 515.978C238.569 516.086 238.497 516.189 238.421 516.297C238.344 516.404 238.259 516.512 238.164 516.627L237.594 516.274C237.692 516.159 237.791 516.055 237.886 515.959C237.98 515.863 238.075 515.774 238.169 515.686C238.263 515.601 238.362 515.513 238.466 515.429C238.569 515.344 238.677 515.252 238.794 515.152C238.479 515.114 238.187 515.071 237.922 515.033C237.656 514.994 237.369 514.929 237.059 514.845L237.27 514.284C237.432 514.33 237.58 514.376 237.715 514.422C237.85 514.468 237.98 514.518 238.106 514.568C238.232 514.618 238.362 514.672 238.488 514.726C238.614 514.779 238.749 514.837 238.893 514.895C238.866 514.756 238.839 514.63 238.816 514.507C238.794 514.388 238.771 514.268 238.758 514.149C238.74 514.03 238.726 513.911 238.717 513.788C238.708 513.665 238.704 513.531 238.704 513.389H239.387C239.387 513.531 239.383 513.665 239.374 513.788C239.365 513.911 239.351 514.03 239.333 514.149C239.315 514.268 239.297 514.388 239.275 514.507C239.252 514.626 239.225 514.756 239.198 514.895C239.338 514.837 239.473 514.783 239.603 514.726C239.729 514.672 239.859 514.618 239.985 514.568C240.111 514.518 240.241 514.468 240.376 514.422C240.511 514.376 240.659 514.33 240.821 514.284L241.032 514.845C240.718 514.929 240.426 514.994 240.16 515.033C239.895 515.075 239.603 515.114 239.288 515.156Z" fill="#F2F2F2"/>
            <path d="M104.369 245.323C104.599 245.519 104.805 245.695 104.99 245.857C105.174 246.018 105.367 246.214 105.57 246.441L104.999 246.794C104.904 246.679 104.819 246.568 104.742 246.464C104.666 246.356 104.594 246.253 104.527 246.145C104.459 246.037 104.396 245.93 104.329 245.818C104.266 245.707 104.194 245.592 104.122 245.469C104.05 245.588 103.978 245.707 103.915 245.818C103.852 245.93 103.785 246.041 103.718 246.145C103.65 246.253 103.578 246.356 103.502 246.464C103.425 246.571 103.34 246.679 103.246 246.794L102.675 246.441C102.773 246.326 102.872 246.222 102.967 246.126C103.061 246.03 103.156 245.941 103.25 245.853C103.344 245.768 103.443 245.68 103.547 245.596C103.65 245.511 103.758 245.419 103.875 245.319C103.56 245.281 103.268 245.238 103.003 245.2C102.738 245.161 102.45 245.096 102.14 245.012L102.351 244.451C102.513 244.497 102.661 244.543 102.796 244.589C102.931 244.635 103.061 244.685 103.187 244.735C103.313 244.785 103.443 244.839 103.569 244.893C103.695 244.946 103.83 245.004 103.974 245.062C103.947 244.923 103.92 244.797 103.897 244.674C103.875 244.555 103.852 244.435 103.839 244.316C103.821 244.197 103.807 244.078 103.798 243.955C103.789 243.832 103.785 243.698 103.785 243.556H104.468C104.468 243.698 104.464 243.832 104.455 243.955C104.446 244.078 104.432 244.197 104.414 244.316C104.396 244.435 104.378 244.555 104.356 244.674C104.333 244.793 104.306 244.923 104.279 245.062C104.419 245.004 104.554 244.95 104.684 244.893C104.81 244.839 104.94 244.785 105.066 244.735C105.192 244.685 105.322 244.635 105.457 244.589C105.592 244.543 105.74 244.497 105.902 244.451L106.114 245.012C105.799 245.096 105.507 245.161 105.241 245.2C104.976 245.242 104.684 245.281 104.369 245.323Z" fill="#F2F2F2"/>
            <path d="M41.8693 616.047C42.0986 616.243 42.3054 616.42 42.4897 616.581C42.674 616.743 42.8673 616.939 43.0696 617.165L42.4987 617.519C42.4043 617.404 42.3189 617.292 42.2424 617.188C42.166 617.081 42.0941 616.977 42.0267 616.87C41.9592 616.762 41.8963 616.654 41.8289 616.543C41.7659 616.432 41.694 616.316 41.6221 616.193C41.5502 616.313 41.4782 616.432 41.4153 616.543C41.3524 616.654 41.2849 616.766 41.2175 616.87C41.1501 616.977 41.0782 617.081 41.0017 617.188C40.9253 617.296 40.8399 617.404 40.7455 617.519L40.1746 617.165C40.2735 617.05 40.3724 616.946 40.4668 616.85C40.5612 616.754 40.6556 616.666 40.75 616.578C40.8444 616.493 40.9433 616.405 41.0467 616.32C41.1501 616.236 41.258 616.143 41.3748 616.044C41.0602 616.005 40.768 615.963 40.5027 615.925C40.2375 615.886 39.9498 615.821 39.6396 615.736L39.8509 615.175C40.0128 615.221 40.1611 615.268 40.296 615.314C40.4308 615.36 40.5612 615.41 40.6871 615.46C40.8129 615.51 40.9433 615.563 41.0692 615.617C41.195 615.671 41.3299 615.729 41.4737 615.786C41.4468 615.648 41.4198 615.521 41.3973 615.398C41.3748 615.279 41.3524 615.16 41.3389 615.041C41.3209 614.922 41.3074 614.803 41.2984 614.68C41.2894 614.557 41.2849 614.422 41.2849 614.28H41.9682C41.9682 614.422 41.9637 614.557 41.9547 614.68C41.9458 614.803 41.9323 614.922 41.9143 615.041C41.8963 615.16 41.8783 615.279 41.8558 615.398C41.8334 615.517 41.8064 615.648 41.7794 615.786C41.9188 615.729 42.0536 615.675 42.184 615.617C42.3099 615.563 42.4402 615.51 42.5661 615.46C42.692 615.41 42.8223 615.36 42.9572 615.314C43.0921 615.268 43.2404 615.221 43.4022 615.175L43.6135 615.736C43.2988 615.821 43.0066 615.886 42.7414 615.925C42.4762 615.967 42.184 616.005 41.8693 616.047Z" fill="#F2F2F2"/>
            <path d="M177.387 827.118C177.616 827.314 177.823 827.49 178.007 827.652C178.192 827.813 178.385 828.009 178.587 828.236L178.016 828.589C177.922 828.474 177.836 828.362 177.76 828.259C177.684 828.151 177.612 828.047 177.544 827.94C177.477 827.832 177.414 827.725 177.346 827.613C177.284 827.502 177.212 827.387 177.14 827.264C177.068 827.383 176.996 827.502 176.933 827.613C176.87 827.725 176.803 827.836 176.735 827.94C176.668 828.047 176.596 828.151 176.519 828.259C176.443 828.366 176.357 828.474 176.263 828.589L175.692 828.236C175.791 828.12 175.89 828.017 175.984 827.921C176.079 827.825 176.173 827.736 176.268 827.648C176.362 827.563 176.461 827.475 176.564 827.391C176.668 827.306 176.776 827.214 176.892 827.114C176.578 827.076 176.286 827.033 176.02 826.995C175.755 826.956 175.467 826.891 175.157 826.807L175.369 826.246C175.53 826.292 175.679 826.338 175.814 826.384C175.948 826.43 176.079 826.48 176.205 826.53C176.331 826.58 176.461 826.634 176.587 826.687C176.713 826.741 176.847 826.799 176.991 826.857C176.964 826.718 176.937 826.591 176.915 826.469C176.892 826.349 176.87 826.23 176.856 826.111C176.838 825.992 176.825 825.873 176.816 825.75C176.807 825.627 176.803 825.493 176.803 825.351H177.486C177.486 825.493 177.481 825.627 177.472 825.75C177.463 825.873 177.45 825.992 177.432 826.111C177.414 826.23 177.396 826.349 177.373 826.469C177.351 826.588 177.324 826.718 177.297 826.857C177.436 826.799 177.571 826.745 177.702 826.687C177.827 826.634 177.958 826.58 178.084 826.53C178.21 826.48 178.34 826.43 178.475 826.384C178.61 826.338 178.758 826.292 178.92 826.246L179.131 826.807C178.816 826.891 178.524 826.956 178.259 826.995C177.994 827.037 177.702 827.076 177.387 827.118Z" fill="#F2F2F2"/>
            <path d="M236.944 163.383C237.238 163.682 237.501 163.957 237.739 164.2C237.977 164.443 238.224 164.743 238.486 165.099L237.755 165.641C237.636 165.463 237.524 165.293 237.421 165.131C237.326 164.969 237.23 164.808 237.143 164.646C237.055 164.484 236.976 164.314 236.889 164.144C236.809 163.974 236.722 163.796 236.626 163.609C236.531 163.796 236.444 163.974 236.364 164.144C236.285 164.314 236.197 164.484 236.11 164.646C236.022 164.808 235.927 164.969 235.832 165.131C235.736 165.293 235.625 165.463 235.498 165.641L234.767 165.099C234.894 164.921 235.021 164.759 235.14 164.621C235.259 164.476 235.378 164.338 235.506 164.208C235.625 164.079 235.752 163.941 235.887 163.812C236.022 163.682 236.157 163.536 236.308 163.391C235.903 163.326 235.53 163.269 235.188 163.204C234.846 163.14 234.48 163.043 234.083 162.913L234.353 162.055C234.56 162.128 234.751 162.192 234.925 162.265C235.1 162.338 235.267 162.411 235.434 162.492C235.601 162.573 235.76 162.654 235.927 162.735C236.094 162.816 236.261 162.905 236.443 162.994C236.412 162.783 236.38 162.589 236.348 162.403C236.316 162.217 236.292 162.039 236.269 161.852C236.245 161.674 236.229 161.488 236.213 161.302C236.197 161.116 236.197 160.905 236.197 160.687H237.079C237.079 160.905 237.071 161.108 237.063 161.302C237.048 161.488 237.032 161.674 237.016 161.852C236.992 162.031 236.968 162.217 236.936 162.403C236.904 162.589 236.873 162.783 236.841 162.994C237.024 162.905 237.191 162.824 237.357 162.735C237.524 162.654 237.683 162.573 237.85 162.492C238.017 162.411 238.184 162.338 238.359 162.265C238.534 162.192 238.724 162.128 238.931 162.055L239.201 162.913C238.796 163.043 238.422 163.14 238.081 163.204C237.723 163.261 237.35 163.318 236.944 163.383Z" fill="#F2F2F2"/>
            <path d="M307.787 12.8025C308.081 13.1021 308.343 13.3774 308.582 13.6203C308.82 13.8632 309.067 14.1627 309.329 14.519L308.598 15.0614C308.478 14.8833 308.367 14.7133 308.264 14.5513C308.168 14.3894 308.073 14.2275 307.986 14.0656C307.898 13.9036 307.819 13.7336 307.731 13.5636C307.652 13.3936 307.564 13.2154 307.469 13.0292C307.374 13.2154 307.286 13.3936 307.207 13.5636C307.127 13.7336 307.04 13.9036 306.952 14.0656C306.865 14.2275 306.77 14.3894 306.674 14.5513C306.579 14.7133 306.468 14.8833 306.34 15.0614L305.609 14.519C305.736 14.3408 305.864 14.1789 305.983 14.0413C306.102 13.8955 306.221 13.7579 306.348 13.6284C306.468 13.4988 306.595 13.3612 306.73 13.2316C306.865 13.1021 307 12.9564 307.151 12.8106C306.746 12.7459 306.372 12.6892 306.031 12.6244C305.689 12.5596 305.323 12.4625 304.926 12.3329L305.196 11.4747C305.403 11.5476 305.593 11.6124 305.768 11.6852C305.943 11.7581 306.11 11.831 306.277 11.9119C306.444 11.9929 306.603 12.0739 306.77 12.1548C306.937 12.2358 307.103 12.3248 307.286 12.4139C307.254 12.2034 307.223 12.0091 307.191 11.8229C307.159 11.6367 307.135 11.4585 307.111 11.2723C307.088 11.0942 307.072 10.908 307.056 10.7218C307.04 10.5356 307.04 10.325 307.04 10.1064H307.922C307.922 10.325 307.914 10.5275 307.906 10.7218C307.89 10.908 307.874 11.0942 307.858 11.2723C307.835 11.4504 307.811 11.6367 307.779 11.8229C307.747 12.0091 307.715 12.2034 307.684 12.4139C307.866 12.3248 308.033 12.2439 308.2 12.1548C308.367 12.0739 308.526 11.9929 308.693 11.9119C308.86 11.831 309.027 11.7581 309.202 11.6852C309.377 11.6124 309.567 11.5476 309.774 11.4747L310.044 12.3329C309.639 12.4625 309.265 12.5596 308.924 12.6244C308.566 12.6811 308.192 12.7378 307.787 12.8025Z" fill="#F2F2F2"/>
            <path d="M306.657 632.816C306.951 633.116 307.213 633.391 307.452 633.634C307.69 633.877 307.937 634.176 308.199 634.533L307.468 635.075C307.349 634.897 307.237 634.727 307.134 634.565C307.039 634.403 306.943 634.241 306.856 634.079C306.768 633.917 306.689 633.747 306.601 633.577C306.522 633.407 306.435 633.229 306.339 633.043C306.244 633.229 306.156 633.407 306.077 633.577C305.997 633.747 305.91 633.917 305.823 634.079C305.735 634.241 305.64 634.403 305.544 634.565C305.449 634.727 305.338 634.897 305.211 635.075L304.479 634.533C304.607 634.355 304.734 634.193 304.853 634.055C304.972 633.909 305.091 633.772 305.219 633.642C305.338 633.512 305.465 633.375 305.6 633.245C305.735 633.116 305.87 632.97 306.021 632.824C305.616 632.76 305.242 632.703 304.901 632.638C304.559 632.573 304.193 632.476 303.796 632.347L304.066 631.488C304.273 631.561 304.464 631.626 304.638 631.699C304.813 631.772 304.98 631.845 305.147 631.926C305.314 632.007 305.473 632.088 305.64 632.168C305.807 632.249 305.974 632.339 306.156 632.428C306.125 632.217 306.093 632.023 306.061 631.837C306.029 631.65 306.005 631.472 305.982 631.286C305.958 631.108 305.942 630.922 305.926 630.735C305.91 630.549 305.91 630.339 305.91 630.12H306.792C306.792 630.339 306.784 630.541 306.776 630.735C306.76 630.922 306.745 631.108 306.729 631.286C306.705 631.464 306.681 631.65 306.649 631.837C306.617 632.023 306.586 632.217 306.554 632.428C306.737 632.339 306.903 632.258 307.07 632.168C307.237 632.088 307.396 632.007 307.563 631.926C307.73 631.845 307.897 631.772 308.072 631.699C308.247 631.626 308.437 631.561 308.644 631.488L308.914 632.347C308.509 632.476 308.135 632.573 307.794 632.638C307.436 632.695 307.062 632.751 306.657 632.816Z" fill="#F2F2F2"/>
            <path d="M123.127 503.458C123.421 503.757 123.683 504.033 123.922 504.276C124.16 504.518 124.406 504.818 124.669 505.174L123.937 505.717C123.818 505.539 123.707 505.369 123.604 505.207C123.508 505.045 123.413 504.883 123.326 504.721C123.238 504.559 123.159 504.389 123.071 504.219C122.992 504.049 122.904 503.871 122.809 503.685C122.714 503.871 122.626 504.049 122.547 504.219C122.467 504.389 122.38 504.559 122.292 504.721C122.205 504.883 122.11 505.045 122.014 505.207C121.919 505.369 121.808 505.539 121.68 505.717L120.949 505.174C121.076 504.996 121.203 504.834 121.323 504.697C121.442 504.551 121.561 504.413 121.688 504.284C121.807 504.154 121.935 504.016 122.07 503.887C122.205 503.757 122.34 503.612 122.491 503.466C122.086 503.401 121.712 503.344 121.37 503.28C121.029 503.215 120.663 503.118 120.266 502.988L120.536 502.13C120.743 502.203 120.933 502.268 121.108 502.341C121.283 502.413 121.45 502.486 121.617 502.567C121.784 502.648 121.943 502.729 122.11 502.81C122.276 502.891 122.443 502.98 122.626 503.069C122.594 502.859 122.563 502.664 122.531 502.478C122.499 502.292 122.475 502.114 122.451 501.928C122.427 501.749 122.412 501.563 122.396 501.377C122.38 501.191 122.38 500.98 122.38 500.762H123.262C123.262 500.98 123.254 501.183 123.246 501.377C123.23 501.563 123.214 501.749 123.198 501.928C123.174 502.106 123.151 502.292 123.119 502.478C123.087 502.664 123.055 502.859 123.023 503.069C123.206 502.98 123.373 502.899 123.54 502.81C123.707 502.729 123.866 502.648 124.033 502.567C124.2 502.486 124.367 502.413 124.542 502.341C124.716 502.268 124.907 502.203 125.114 502.13L125.384 502.988C124.979 503.118 124.605 503.215 124.263 503.28C123.906 503.336 123.532 503.393 123.127 503.458Z" fill="#F2F2F2"/>
            <path d="M271.935 752.068C272.229 752.368 272.492 752.643 272.73 752.886C272.969 753.129 273.215 753.428 273.477 753.785L272.746 754.327C272.627 754.149 272.516 753.979 272.412 753.817C272.317 753.655 272.222 753.493 272.134 753.331C272.047 753.169 271.967 752.999 271.88 752.829C271.8 752.659 271.713 752.481 271.617 752.295C271.522 752.481 271.435 752.659 271.355 752.829C271.276 752.999 271.188 753.169 271.101 753.331C271.013 753.493 270.918 753.655 270.823 753.817C270.727 753.979 270.616 754.149 270.489 754.327L269.758 753.785C269.885 753.606 270.012 753.445 270.131 753.307C270.25 753.161 270.37 753.024 270.497 752.894C270.616 752.764 270.743 752.627 270.878 752.497C271.013 752.368 271.149 752.222 271.3 752.076C270.894 752.011 270.521 751.955 270.179 751.89C269.837 751.825 269.472 751.728 269.074 751.599L269.344 750.74C269.551 750.813 269.742 750.878 269.917 750.951C270.092 751.024 270.258 751.097 270.425 751.178C270.592 751.259 270.751 751.339 270.918 751.42C271.085 751.501 271.252 751.59 271.435 751.68C271.403 751.469 271.371 751.275 271.339 751.088C271.308 750.902 271.284 750.724 271.26 750.538C271.236 750.36 271.22 750.174 271.204 749.987C271.188 749.801 271.188 749.591 271.188 749.372H272.071C272.071 749.591 272.063 749.793 272.055 749.987C272.039 750.174 272.023 750.36 272.007 750.538C271.983 750.716 271.959 750.902 271.927 751.088C271.896 751.275 271.864 751.469 271.832 751.68C272.015 751.59 272.182 751.51 272.349 751.42C272.516 751.339 272.675 751.259 272.841 751.178C273.008 751.097 273.175 751.024 273.35 750.951C273.525 750.878 273.716 750.813 273.922 750.74L274.193 751.599C273.787 751.728 273.414 751.825 273.072 751.89C272.714 751.947 272.341 752.003 271.935 752.068Z" fill="#F2F2F2"/>
            <path d="M287.026 256.97C287.163 257.117 287.285 257.252 287.396 257.371C287.508 257.49 287.622 257.637 287.744 257.811L287.404 258.077C287.348 257.99 287.296 257.907 287.248 257.827C287.204 257.748 287.159 257.669 287.119 257.589C287.078 257.51 287.041 257.426 287 257.343C286.963 257.26 286.923 257.172 286.878 257.081C286.834 257.172 286.793 257.26 286.756 257.343C286.719 257.426 286.678 257.51 286.637 257.589C286.597 257.669 286.552 257.748 286.508 257.827C286.463 257.907 286.412 257.99 286.352 258.077L286.012 257.811C286.071 257.724 286.13 257.645 286.186 257.577C286.241 257.506 286.297 257.438 286.356 257.375C286.412 257.311 286.471 257.244 286.534 257.18C286.597 257.117 286.66 257.045 286.73 256.974C286.541 256.942 286.367 256.914 286.208 256.883C286.049 256.851 285.878 256.803 285.693 256.74L285.819 256.319C285.916 256.355 286.004 256.387 286.086 256.422C286.167 256.458 286.245 256.494 286.323 256.533C286.401 256.573 286.475 256.613 286.552 256.653C286.63 256.692 286.708 256.736 286.793 256.78C286.778 256.676 286.763 256.581 286.749 256.49C286.734 256.399 286.723 256.311 286.712 256.22C286.7 256.133 286.693 256.041 286.686 255.95C286.678 255.859 286.678 255.756 286.678 255.648H287.089C287.089 255.756 287.085 255.855 287.082 255.95C287.074 256.041 287.067 256.133 287.06 256.22C287.048 256.307 287.037 256.399 287.023 256.49C287.008 256.581 286.993 256.676 286.978 256.78C287.063 256.736 287.141 256.696 287.219 256.653C287.296 256.613 287.371 256.573 287.448 256.533C287.526 256.494 287.604 256.458 287.685 256.422C287.767 256.387 287.856 256.355 287.952 256.319L288.078 256.74C287.889 256.803 287.715 256.851 287.556 256.883C287.389 256.911 287.215 256.938 287.026 256.97Z" fill="#F2F2F2"/>
            <path d="M293.541 50.2952C293.678 50.4421 293.8 50.577 293.911 50.6961C294.022 50.8152 294.137 50.962 294.259 51.1366L293.919 51.4025C293.863 51.3152 293.811 51.2319 293.763 51.1525C293.719 51.0731 293.674 50.9938 293.633 50.9144C293.593 50.835 293.556 50.7517 293.515 50.6683C293.478 50.585 293.437 50.4977 293.393 50.4064C293.348 50.4977 293.308 50.585 293.271 50.6683C293.234 50.7517 293.193 50.835 293.152 50.9144C293.111 50.9938 293.067 51.0731 293.023 51.1525C292.978 51.2319 292.926 51.3152 292.867 51.4025L292.526 51.1366C292.586 51.0493 292.645 50.9699 292.7 50.9025C292.756 50.831 292.811 50.7636 292.871 50.7001C292.926 50.6366 292.986 50.5691 293.048 50.5056C293.111 50.4421 293.174 50.3707 293.245 50.2992C293.056 50.2675 292.882 50.2397 292.723 50.2079C292.563 50.1762 292.393 50.1286 292.208 50.0651L292.334 49.6444C292.43 49.6801 292.519 49.7118 292.6 49.7475C292.682 49.7833 292.76 49.819 292.837 49.8587C292.915 49.8984 292.989 49.9381 293.067 49.9777C293.145 50.0174 293.222 50.0611 293.308 50.1047C293.293 50.0016 293.278 49.9063 293.263 49.815C293.248 49.7237 293.237 49.6364 293.226 49.5451C293.215 49.4578 293.208 49.3665 293.2 49.2753C293.193 49.184 293.193 49.0808 293.193 48.9736H293.604C293.604 49.0808 293.6 49.18 293.596 49.2753C293.589 49.3665 293.582 49.4578 293.574 49.5451C293.563 49.6325 293.552 49.7237 293.537 49.815C293.522 49.9063 293.508 50.0016 293.493 50.1047C293.578 50.0611 293.656 50.0214 293.733 49.9777C293.811 49.9381 293.885 49.8984 293.963 49.8587C294.041 49.819 294.118 49.7833 294.2 49.7475C294.281 49.7118 294.37 49.6801 294.466 49.6444L294.592 50.0651C294.404 50.1286 294.23 50.1762 294.07 50.2079C293.904 50.2357 293.73 50.2635 293.541 50.2952Z" fill="#F2F2F2"/>
            <path d="M309.82 704.288C309.957 704.435 310.079 704.57 310.19 704.689C310.301 704.808 310.416 704.955 310.538 705.13L310.198 705.396C310.142 705.308 310.09 705.225 310.042 705.146C309.998 705.066 309.953 704.987 309.913 704.908C309.872 704.828 309.835 704.745 309.794 704.661C309.757 704.578 309.716 704.491 309.672 704.4C309.628 704.491 309.587 704.578 309.55 704.661C309.513 704.745 309.472 704.828 309.431 704.908C309.391 704.987 309.346 705.066 309.302 705.146C309.257 705.225 309.206 705.308 309.146 705.396L308.806 705.13C308.865 705.042 308.924 704.963 308.98 704.896C309.035 704.824 309.091 704.757 309.15 704.693C309.206 704.63 309.265 704.562 309.328 704.499C309.391 704.435 309.454 704.364 309.524 704.292C309.335 704.261 309.161 704.233 309.002 704.201C308.843 704.169 308.672 704.122 308.487 704.058L308.613 703.638C308.709 703.673 308.798 703.705 308.88 703.741C308.961 703.776 309.039 703.812 309.117 703.852C309.194 703.892 309.269 703.931 309.346 703.971C309.424 704.011 309.502 704.054 309.587 704.098C309.572 703.995 309.557 703.899 309.542 703.808C309.528 703.717 309.517 703.63 309.505 703.538C309.494 703.451 309.487 703.36 309.48 703.268C309.472 703.177 309.472 703.074 309.472 702.967H309.883C309.883 703.074 309.879 703.173 309.876 703.268C309.868 703.36 309.861 703.451 309.853 703.538C309.842 703.626 309.831 703.717 309.816 703.808C309.802 703.899 309.787 703.995 309.772 704.098C309.857 704.054 309.935 704.015 310.013 703.971C310.09 703.931 310.164 703.892 310.242 703.852C310.32 703.812 310.398 703.776 310.479 703.741C310.561 703.705 310.649 703.673 310.746 703.638L310.872 704.058C310.683 704.122 310.509 704.169 310.35 704.201C310.183 704.229 310.009 704.257 309.82 704.288Z" fill="#F2F2F2"/>
            <path d="M30.0623 730.695C30.3564 730.995 30.6187 731.27 30.8571 731.513C31.0955 731.756 31.3419 732.055 31.6042 732.412L30.873 732.954C30.7538 732.776 30.6425 732.606 30.5392 732.444C30.4438 732.282 30.3485 732.12 30.261 731.958C30.1736 731.796 30.0941 731.626 30.0067 731.456C29.9272 731.286 29.8398 731.108 29.7444 730.922C29.6491 731.108 29.5616 731.286 29.4822 731.456C29.4027 731.626 29.3153 731.796 29.2278 731.958C29.1404 732.12 29.045 732.282 28.9497 732.444C28.8543 732.606 28.743 732.776 28.6159 732.954L27.8847 732.412C28.0118 732.233 28.139 732.071 28.2582 731.934C28.3774 731.796 28.4966 731.65 28.6238 731.521C28.743 731.391 28.8702 731.254 29.0053 731.124C29.1404 730.995 29.2755 730.849 29.4265 730.703C29.0212 730.638 28.6476 730.582 28.3059 730.517C27.9641 730.452 27.5986 730.355 27.2012 730.226L27.4714 729.367C27.678 729.44 27.8688 729.505 28.0436 729.578C28.2185 729.651 28.3854 729.724 28.5523 729.805C28.7192 729.885 28.8781 729.966 29.045 730.047C29.2119 730.128 29.3788 730.217 29.5616 730.306C29.5299 730.096 29.498 729.902 29.4663 729.715C29.4345 729.529 29.4106 729.351 29.3868 729.165C29.363 728.987 29.3471 728.801 29.3312 728.614C29.3153 728.428 29.3153 728.218 29.3153 727.999H30.1975C30.1975 728.218 30.1895 728.42 30.1816 728.614C30.1657 728.801 30.1498 728.987 30.1339 729.165C30.11 729.343 30.0862 729.529 30.0544 729.715C30.0226 729.902 29.9908 730.096 29.959 730.306C30.1418 730.217 30.3087 730.136 30.4756 730.047C30.6425 729.966 30.8015 729.885 30.9684 729.805C31.1353 729.724 31.3022 729.651 31.4771 729.578C31.6519 729.505 31.8426 729.44 32.0493 729.367L32.3195 730.226C31.9142 730.355 31.5406 730.452 31.1989 730.517C30.8412 730.574 30.4677 730.63 30.0623 730.695Z" fill="#F2F2F2"/>
            <path d="M336.823 627.252C337.117 627.551 337.379 627.827 337.618 628.069C337.856 628.312 338.103 628.612 338.365 628.968L337.634 629.511C337.515 629.333 337.403 629.162 337.3 629.001C337.205 628.839 337.109 628.677 337.022 628.515C336.934 628.353 336.855 628.183 336.767 628.013C336.688 627.843 336.601 627.665 336.505 627.478C336.41 627.665 336.322 627.843 336.243 628.013C336.163 628.183 336.076 628.353 335.989 628.515C335.901 628.677 335.806 628.839 335.71 629.001C335.615 629.162 335.504 629.333 335.377 629.511L334.645 628.968C334.773 628.79 334.9 628.628 335.019 628.49C335.138 628.353 335.257 628.207 335.385 628.078C335.504 627.948 335.631 627.81 335.766 627.681C335.901 627.551 336.036 627.406 336.187 627.26C335.782 627.195 335.408 627.138 335.067 627.074C334.725 627.009 334.359 626.912 333.962 626.782L334.232 625.924C334.439 625.997 334.63 626.062 334.804 626.134C334.979 626.207 335.146 626.28 335.313 626.361C335.48 626.442 335.639 626.523 335.806 626.604C335.973 626.685 336.14 626.774 336.322 626.863C336.291 626.653 336.259 626.458 336.227 626.272C336.195 626.086 336.171 625.908 336.148 625.722C336.124 625.543 336.108 625.357 336.092 625.171C336.076 624.985 336.076 624.774 336.076 624.556H336.958C336.958 624.774 336.95 624.977 336.942 625.171C336.926 625.357 336.911 625.543 336.895 625.722C336.871 625.9 336.847 626.086 336.815 626.272C336.783 626.458 336.752 626.653 336.72 626.863C336.903 626.774 337.069 626.693 337.236 626.604C337.403 626.523 337.562 626.442 337.729 626.361C337.896 626.28 338.063 626.207 338.238 626.134C338.413 626.062 338.603 625.997 338.81 625.924L339.08 626.782C338.675 626.912 338.301 627.009 337.96 627.074C337.602 627.13 337.228 627.187 336.823 627.252Z" fill="#F2F2F2"/>
            <path d="M153.292 497.893C153.586 498.193 153.848 498.468 154.087 498.711C154.325 498.954 154.571 499.254 154.834 499.61L154.103 500.152C153.983 499.974 153.872 499.804 153.769 499.642C153.673 499.48 153.578 499.318 153.491 499.156C153.403 498.994 153.324 498.824 153.236 498.654C153.157 498.484 153.069 498.306 152.974 498.12C152.879 498.306 152.791 498.484 152.712 498.654C152.632 498.824 152.545 498.994 152.457 499.156C152.37 499.318 152.275 499.48 152.179 499.642C152.084 499.804 151.973 499.974 151.845 500.152L151.114 499.61C151.241 499.432 151.368 499.27 151.488 499.132C151.607 498.994 151.726 498.849 151.853 498.719C151.973 498.59 152.1 498.452 152.235 498.322C152.37 498.193 152.505 498.047 152.656 497.901C152.251 497.837 151.877 497.78 151.535 497.715C151.194 497.65 150.828 497.553 150.431 497.424L150.701 496.566C150.908 496.638 151.098 496.703 151.273 496.776C151.448 496.849 151.615 496.922 151.782 497.003C151.949 497.084 152.108 497.165 152.275 497.246C152.441 497.327 152.608 497.416 152.791 497.505C152.759 497.294 152.728 497.1 152.696 496.914C152.664 496.727 152.64 496.549 152.616 496.363C152.592 496.185 152.577 495.999 152.561 495.813C152.545 495.626 152.545 495.416 152.545 495.197H153.427C153.427 495.416 153.419 495.618 153.411 495.813C153.395 495.999 153.379 496.185 153.363 496.363C153.34 496.541 153.316 496.727 153.284 496.914C153.252 497.1 153.22 497.294 153.189 497.505C153.371 497.416 153.538 497.335 153.705 497.246C153.872 497.165 154.031 497.084 154.198 497.003C154.365 496.922 154.532 496.849 154.707 496.776C154.881 496.703 155.072 496.638 155.279 496.566L155.549 497.424C155.144 497.553 154.77 497.65 154.428 497.715C154.071 497.772 153.697 497.829 153.292 497.893Z" fill="#F2F2F2"/>
            <path d="M302.101 746.504C302.395 746.803 302.658 747.079 302.896 747.321C303.135 747.564 303.381 747.864 303.643 748.22L302.912 748.763C302.793 748.584 302.682 748.414 302.578 748.253C302.483 748.091 302.388 747.929 302.3 747.767C302.213 747.605 302.133 747.435 302.046 747.265C301.966 747.095 301.879 746.917 301.783 746.73C301.688 746.917 301.601 747.095 301.521 747.265C301.442 747.435 301.354 747.605 301.267 747.767C301.179 747.929 301.084 748.091 300.989 748.253C300.893 748.414 300.782 748.584 300.655 748.763L299.924 748.22C300.051 748.042 300.178 747.88 300.297 747.742C300.416 747.605 300.536 747.459 300.663 747.33C300.782 747.2 300.909 747.062 301.044 746.933C301.179 746.803 301.315 746.658 301.466 746.512C301.06 746.447 300.687 746.39 300.345 746.326C300.003 746.261 299.638 746.164 299.24 746.034L299.51 745.176C299.717 745.249 299.908 745.314 300.083 745.386C300.258 745.459 300.424 745.532 300.591 745.613C300.758 745.694 300.917 745.775 301.084 745.856C301.251 745.937 301.418 746.026 301.601 746.115C301.569 745.905 301.537 745.71 301.505 745.524C301.474 745.338 301.45 745.16 301.426 744.973C301.402 744.795 301.386 744.609 301.37 744.423C301.354 744.237 301.354 744.026 301.354 743.808H302.237C302.237 744.026 302.229 744.229 302.221 744.423C302.205 744.609 302.189 744.795 302.173 744.973C302.149 745.152 302.125 745.338 302.093 745.524C302.062 745.71 302.03 745.905 301.998 746.115C302.181 746.026 302.348 745.945 302.515 745.856C302.682 745.775 302.841 745.694 303.007 745.613C303.174 745.532 303.341 745.459 303.516 745.386C303.691 745.314 303.882 745.249 304.088 745.176L304.359 746.034C303.953 746.164 303.58 746.261 303.238 746.326C302.88 746.382 302.507 746.439 302.101 746.504Z" fill="#F2F2F2"/>
            <path d="M197.262 34.6648C197.556 34.9644 197.818 35.2397 198.056 35.4826C198.295 35.7255 198.541 36.025 198.803 36.3813L198.072 36.9237C197.953 36.7456 197.842 36.5756 197.738 36.4136C197.643 36.2517 197.548 36.0898 197.46 35.9279C197.373 35.7659 197.293 35.5959 197.206 35.4259C197.126 35.2559 197.039 35.0777 196.944 34.8915C196.848 35.0777 196.761 35.2559 196.681 35.4259C196.602 35.5959 196.514 35.7659 196.427 35.9279C196.34 36.0898 196.244 36.2517 196.149 36.4136C196.054 36.5756 195.942 36.7456 195.815 36.9237L195.084 36.3813C195.211 36.2031 195.338 36.0412 195.457 35.9036C195.577 35.7659 195.696 35.6202 195.823 35.4907C195.942 35.3611 196.069 35.2235 196.205 35.0939C196.34 34.9644 196.475 34.8187 196.626 34.6729C196.22 34.6082 195.847 34.5515 195.505 34.4867C195.163 34.4219 194.798 34.3248 194.4 34.1952L194.671 33.337C194.877 33.4099 195.068 33.4747 195.243 33.5475C195.418 33.6204 195.585 33.6933 195.751 33.7742C195.918 33.8552 196.077 33.9362 196.244 34.0171C196.411 34.0981 196.578 34.1871 196.761 34.2762C196.729 34.0657 196.697 33.8714 196.665 33.6852C196.634 33.499 196.61 33.3208 196.586 33.1346C196.562 32.9565 196.546 32.7703 196.53 32.5841C196.514 32.3979 196.514 32.1874 196.514 31.9688H197.397C197.397 32.1874 197.389 32.3898 197.381 32.5841C197.365 32.7703 197.349 32.9565 197.333 33.1346C197.309 33.3127 197.285 33.499 197.254 33.6852C197.222 33.8714 197.19 34.0657 197.158 34.2762C197.341 34.1871 197.508 34.1062 197.675 34.0171C197.842 33.9362 198.001 33.8552 198.168 33.7742C198.335 33.6933 198.501 33.6204 198.676 33.5475C198.851 33.4747 199.042 33.4099 199.248 33.337L199.519 34.1952C199.113 34.3248 198.74 34.4219 198.398 34.4867C198.04 34.5434 197.667 34.6001 197.262 34.6648Z" fill="#F2F2F2"/>
            <path d="M70.2791 2.69608C70.5732 2.99565 70.8355 3.27093 71.0739 3.51382C71.3123 3.75671 71.5587 4.05627 71.821 4.41251L71.0898 4.95496C70.9706 4.77684 70.8593 4.60682 70.756 4.44489C70.6606 4.28297 70.5652 4.12104 70.4778 3.95911C70.3904 3.79719 70.3109 3.62716 70.2235 3.45714C70.144 3.28712 70.0566 3.109 69.9612 2.92278C69.8659 3.109 69.7784 3.28712 69.699 3.45714C69.6195 3.62716 69.5321 3.79719 69.4446 3.95911C69.3572 4.12104 69.2618 4.28297 69.1665 4.44489C69.0711 4.60682 68.9598 4.77684 68.8327 4.95496L68.1015 4.41251C68.2286 4.23439 68.3558 4.07246 68.475 3.93483C68.5942 3.79719 68.7134 3.65145 68.8406 3.52191C68.9598 3.39237 69.087 3.25473 69.2221 3.12519C69.3572 2.99565 69.4923 2.84991 69.6433 2.70418C69.238 2.63941 68.8644 2.58274 68.5227 2.51796C68.1809 2.45319 67.8154 2.35604 67.418 2.2265L67.6882 1.36828C67.8948 1.44115 68.0856 1.50592 68.2604 1.57879C68.4353 1.65165 68.6022 1.72452 68.7691 1.80549C68.936 1.88645 69.0949 1.96741 69.2618 2.04838C69.4287 2.12934 69.5956 2.2184 69.7784 2.30746C69.7467 2.09695 69.7148 1.90264 69.6831 1.71643C69.6513 1.53021 69.6274 1.35209 69.6036 1.16587C69.5797 0.987753 69.5638 0.801537 69.548 0.615321C69.5321 0.429105 69.5321 0.218601 69.5321 0H70.4143C70.4143 0.218601 70.4063 0.421009 70.3984 0.615321C70.3825 0.801537 70.3666 0.987753 70.3507 1.16587C70.3268 1.34399 70.303 1.53021 70.2712 1.71643C70.2394 1.90264 70.2076 2.09695 70.1758 2.30746C70.3586 2.2184 70.5255 2.13743 70.6924 2.04838C70.8593 1.96741 71.0183 1.88645 71.1852 1.80549C71.3521 1.72452 71.519 1.65165 71.6938 1.57879C71.8687 1.50592 72.0594 1.44115 72.2661 1.36828L72.5363 2.2265C72.131 2.35604 71.7574 2.45319 71.4157 2.51796C71.058 2.57464 70.6845 2.63131 70.2791 2.69608Z" fill="#F2F2F2"/>
            <path d="M318.471 265.067C318.654 265.214 318.817 265.349 318.965 265.468C319.113 265.587 319.266 265.733 319.429 265.908L318.975 266.174C318.901 266.087 318.832 266.003 318.768 265.924C318.708 265.845 318.649 265.765 318.595 265.686C318.541 265.606 318.491 265.523 318.437 265.44C318.388 265.356 318.333 265.269 318.274 265.178C318.215 265.269 318.16 265.356 318.111 265.44C318.062 265.523 318.007 265.606 317.953 265.686C317.899 265.765 317.84 265.845 317.78 265.924C317.721 266.003 317.652 266.087 317.573 266.174L317.119 265.908C317.198 265.821 317.277 265.741 317.351 265.674C317.425 265.606 317.499 265.535 317.578 265.472C317.652 265.408 317.731 265.341 317.815 265.277C317.899 265.214 317.983 265.142 318.077 265.071C317.825 265.039 317.593 265.011 317.38 264.979C317.168 264.948 316.941 264.9 316.694 264.837L316.862 264.416C316.991 264.452 317.109 264.483 317.218 264.519C317.326 264.555 317.43 264.59 317.534 264.63C317.637 264.67 317.736 264.71 317.84 264.749C317.943 264.789 318.047 264.833 318.16 264.876C318.141 264.773 318.121 264.678 318.101 264.587C318.081 264.495 318.067 264.408 318.052 264.317C318.037 264.229 318.027 264.138 318.017 264.047C318.007 263.955 318.007 263.852 318.007 263.745H318.555C318.555 263.852 318.55 263.951 318.546 264.047C318.536 264.138 318.526 264.229 318.516 264.317C318.501 264.404 318.486 264.495 318.467 264.587C318.447 264.678 318.427 264.773 318.407 264.876C318.521 264.833 318.624 264.793 318.728 264.749C318.832 264.71 318.931 264.67 319.034 264.63C319.138 264.59 319.242 264.555 319.35 264.519C319.459 264.483 319.577 264.452 319.706 264.416L319.873 264.837C319.622 264.9 319.39 264.948 319.177 264.979C318.955 265.007 318.723 265.035 318.471 265.067Z" fill="#F2F2F2"/>
            <path d="M222.964 804.378C223.146 804.525 223.309 804.66 223.457 804.779C223.605 804.898 223.758 805.045 223.921 805.22L223.467 805.486C223.393 805.398 223.324 805.315 223.26 805.236C223.201 805.156 223.141 805.077 223.087 804.997C223.033 804.918 222.983 804.835 222.929 804.751C222.88 804.668 222.825 804.581 222.766 804.489C222.707 804.581 222.653 804.668 222.603 804.751C222.554 804.835 222.5 804.918 222.445 804.997C222.391 805.077 222.332 805.156 222.273 805.236C222.213 805.315 222.144 805.398 222.065 805.486L221.611 805.22C221.69 805.132 221.769 805.053 221.843 804.985C221.917 804.918 221.991 804.847 222.07 804.783C222.144 804.72 222.223 804.652 222.307 804.589C222.391 804.525 222.475 804.454 222.569 804.382C222.317 804.35 222.085 804.323 221.873 804.291C221.66 804.259 221.433 804.212 221.187 804.148L221.354 803.727C221.483 803.763 221.601 803.795 221.71 803.831C221.818 803.866 221.922 803.902 222.026 803.942C222.129 803.981 222.228 804.021 222.332 804.061C222.435 804.1 222.539 804.144 222.653 804.188C222.633 804.085 222.613 803.989 222.593 803.898C222.574 803.807 222.559 803.719 222.544 803.628C222.529 803.541 222.519 803.45 222.509 803.358C222.5 803.267 222.5 803.164 222.5 803.057H223.048C223.048 803.164 223.043 803.263 223.038 803.358C223.028 803.45 223.018 803.541 223.008 803.628C222.993 803.715 222.978 803.807 222.959 803.898C222.939 803.989 222.919 804.085 222.899 804.188C223.013 804.144 223.117 804.104 223.22 804.061C223.324 804.021 223.423 803.981 223.526 803.942C223.63 803.902 223.734 803.866 223.842 803.831C223.951 803.795 224.069 803.763 224.198 803.727L224.366 804.148C224.114 804.212 223.882 804.259 223.67 804.291C223.447 804.319 223.215 804.346 222.964 804.378Z" fill="#F2F2F2"/>
            <path d="M258.677 655.028C258.859 655.175 259.022 655.309 259.17 655.429C259.318 655.548 259.471 655.694 259.634 655.869L259.18 656.135C259.106 656.048 259.037 655.964 258.973 655.885C258.913 655.806 258.854 655.726 258.8 655.647C258.746 655.567 258.696 655.484 258.642 655.401C258.593 655.317 258.538 655.23 258.479 655.139C258.42 655.23 258.366 655.317 258.316 655.401C258.267 655.484 258.213 655.567 258.158 655.647C258.104 655.726 258.045 655.806 257.985 655.885C257.926 655.964 257.857 656.048 257.778 656.135L257.324 655.869C257.403 655.782 257.482 655.702 257.556 655.635C257.63 655.567 257.704 655.496 257.783 655.432C257.857 655.369 257.936 655.302 258.02 655.238C258.104 655.175 258.188 655.103 258.282 655.032C258.03 655 257.798 654.972 257.586 654.94C257.373 654.909 257.146 654.861 256.899 654.797L257.067 654.377C257.196 654.413 257.314 654.444 257.423 654.48C257.531 654.516 257.635 654.551 257.739 654.591C257.842 654.631 257.941 654.67 258.045 654.71C258.148 654.75 258.252 654.794 258.366 654.837C258.346 654.734 258.326 654.639 258.306 654.547C258.287 654.456 258.272 654.369 258.257 654.278C258.242 654.19 258.232 654.099 258.222 654.008C258.213 653.916 258.213 653.813 258.213 653.706H258.76C258.76 653.813 258.756 653.912 258.751 654.008C258.741 654.099 258.731 654.19 258.721 654.278C258.706 654.365 258.691 654.456 258.672 654.547C258.652 654.639 258.632 654.734 258.612 654.837C258.726 654.794 258.83 654.754 258.933 654.71C259.037 654.67 259.136 654.631 259.239 654.591C259.343 654.551 259.447 654.516 259.555 654.48C259.664 654.444 259.782 654.413 259.911 654.377L260.079 654.797C259.827 654.861 259.595 654.909 259.382 654.94C259.16 654.968 258.928 654.996 258.677 655.028Z" fill="#F2F2F2"/>
            <path d="M75.1463 525.669C75.3289 525.816 75.4918 525.951 75.6399 526.07C75.788 526.189 75.941 526.336 76.1039 526.511L75.6498 526.777C75.5757 526.689 75.5066 526.606 75.4424 526.527C75.3832 526.447 75.324 526.368 75.2697 526.288C75.2154 526.209 75.166 526.126 75.1117 526.042C75.0623 525.959 75.008 525.872 74.9488 525.78C74.8896 525.872 74.8353 525.959 74.7859 526.042C74.7365 526.126 74.6822 526.209 74.6279 526.288C74.5736 526.368 74.5144 526.447 74.4552 526.527C74.3959 526.606 74.3268 526.689 74.2478 526.777L73.7937 526.511C73.8727 526.423 73.9516 526.344 74.0257 526.276C74.0997 526.209 74.1738 526.138 74.2528 526.074C74.3268 526.011 74.4058 525.943 74.4897 525.88C74.5736 525.816 74.6576 525.745 74.7513 525.673C74.4996 525.641 74.2676 525.614 74.0553 525.582C73.843 525.55 73.616 525.503 73.3691 525.439L73.537 525.018C73.6653 525.054 73.7838 525.086 73.8924 525.122C74.001 525.157 74.1047 525.193 74.2083 525.233C74.312 525.272 74.4107 525.312 74.5144 525.352C74.6181 525.391 74.7217 525.435 74.8353 525.479C74.8155 525.376 74.7958 525.28 74.776 525.189C74.7563 525.098 74.7415 525.01 74.7267 524.919C74.7119 524.832 74.702 524.741 74.6921 524.649C74.6822 524.558 74.6822 524.455 74.6822 524.348H75.2302C75.2302 524.455 75.2253 524.554 75.2203 524.649C75.2104 524.741 75.2006 524.832 75.1907 524.919C75.1759 525.006 75.1611 525.098 75.1413 525.189C75.1216 525.28 75.1018 525.376 75.0821 525.479C75.1956 525.435 75.2993 525.395 75.403 525.352C75.5066 525.312 75.6054 525.272 75.709 525.233C75.8127 525.193 75.9164 525.157 76.025 525.122C76.1336 525.086 76.252 525.054 76.3804 525.018L76.5482 525.439C76.2965 525.503 76.0645 525.55 75.8522 525.582C75.63 525.61 75.398 525.638 75.1463 525.669Z" fill="#F2F2F2"/>
            <path d="M223.955 774.279C224.138 774.425 224.3 774.56 224.448 774.679C224.597 774.799 224.75 774.945 224.913 775.12L224.458 775.386C224.384 775.299 224.315 775.215 224.251 775.136C224.192 775.057 224.133 774.977 224.078 774.898C224.024 774.818 223.975 774.735 223.92 774.652C223.871 774.568 223.817 774.481 223.757 774.39C223.698 774.481 223.644 774.568 223.594 774.652C223.545 774.735 223.491 774.818 223.437 774.898C223.382 774.977 223.323 775.057 223.264 775.136C223.205 775.215 223.135 775.299 223.056 775.386L222.602 775.12C222.681 775.033 222.76 774.953 222.834 774.886C222.908 774.818 222.982 774.747 223.061 774.683C223.135 774.62 223.214 774.552 223.298 774.489C223.382 774.425 223.466 774.354 223.56 774.283C223.308 774.251 223.076 774.223 222.864 774.191C222.652 774.16 222.425 774.112 222.178 774.048L222.346 773.628C222.474 773.663 222.592 773.695 222.701 773.731C222.81 773.767 222.913 773.802 223.017 773.842C223.121 773.882 223.219 773.921 223.323 773.961C223.427 774.001 223.53 774.044 223.644 774.088C223.624 773.985 223.604 773.89 223.585 773.798C223.565 773.707 223.55 773.62 223.535 773.529C223.52 773.441 223.511 773.35 223.501 773.259C223.491 773.167 223.491 773.064 223.491 772.957H224.039C224.039 773.064 224.034 773.163 224.029 773.259C224.019 773.35 224.009 773.441 223.999 773.529C223.984 773.616 223.97 773.707 223.95 773.798C223.93 773.89 223.91 773.985 223.891 774.088C224.004 774.044 224.108 774.005 224.212 773.961C224.315 773.921 224.414 773.882 224.518 773.842C224.621 773.802 224.725 773.767 224.834 773.731C224.942 773.695 225.061 773.663 225.189 773.628L225.357 774.048C225.105 774.112 224.873 774.16 224.661 774.191C224.439 774.219 224.207 774.247 223.955 774.279Z" fill="#F2F2F2"/>
            <path d="M76.0518 202.906C76.5923 203.457 77.0852 203.967 77.5223 204.42C77.9595 204.874 78.4205 205.432 78.8974 206.08L77.5462 207.084C77.3236 206.76 77.1169 206.445 76.9341 206.145C76.7513 205.845 76.5764 205.546 76.4175 205.246C76.2585 204.947 76.0995 204.639 75.9485 204.315C75.7975 203.999 75.6306 203.667 75.4557 203.327C75.2808 203.667 75.1139 203.999 74.9629 204.315C74.8119 204.631 74.6529 204.939 74.4939 205.246C74.3349 205.546 74.1601 205.845 73.9772 206.145C73.7944 206.445 73.5878 206.76 73.3652 207.084L72.0139 206.08C72.2524 205.756 72.4829 205.457 72.7055 205.19C72.928 204.922 73.1506 204.663 73.3732 204.42C73.5957 204.177 73.8342 203.934 74.0806 203.692C74.327 203.449 74.5893 203.19 74.8595 202.914C74.1124 202.801 73.4209 202.688 72.7929 202.574C72.165 202.461 71.4814 202.283 70.7422 202.04L71.2429 200.453C71.6245 200.582 71.9742 200.712 72.3001 200.841C72.626 200.971 72.928 201.109 73.238 201.254C73.5401 201.4 73.8421 201.554 74.1442 201.708C74.4462 201.862 74.7642 202.024 75.106 202.185C75.0424 201.797 74.9788 201.432 74.9231 201.084C74.8675 200.744 74.8198 200.404 74.7801 200.072C74.7403 199.74 74.7085 199.4 74.6847 199.044C74.6608 198.696 74.6529 198.315 74.6529 197.91H76.2823C76.2823 198.315 76.2664 198.696 76.2426 199.044C76.2188 199.392 76.187 199.732 76.1472 200.072C76.1075 200.404 76.0598 200.744 76.0041 201.084C75.9485 201.424 75.8849 201.789 75.8213 202.185C76.1552 202.024 76.4731 201.862 76.7752 201.708C77.0772 201.554 77.3792 201.4 77.6892 201.254C77.9913 201.109 78.3013 200.971 78.6192 200.841C78.9372 200.712 79.2869 200.582 79.6764 200.453L80.1772 202.04C79.43 202.283 78.7384 202.461 78.1026 202.574C77.4985 202.679 76.807 202.793 76.0518 202.906Z" fill="#F2F2F2"/>
            <path d="M259.074 417.832C259.615 418.383 260.108 418.893 260.545 419.346C260.982 419.8 261.443 420.358 261.92 421.006L260.569 422.01C260.346 421.686 260.139 421.37 259.957 421.071C259.774 420.771 259.599 420.472 259.44 420.172C259.281 419.872 259.122 419.565 258.971 419.241C258.82 418.925 258.653 418.593 258.478 418.253C258.303 418.593 258.136 418.925 257.985 419.241C257.834 419.557 257.675 419.864 257.516 420.172C257.357 420.472 257.183 420.771 257 421.071C256.817 421.37 256.61 421.686 256.388 422.01L255.036 421.006C255.275 420.682 255.505 420.383 255.728 420.115C255.95 419.848 256.173 419.589 256.396 419.346C256.618 419.103 256.857 418.86 257.103 418.617C257.349 418.374 257.612 418.115 257.882 417.84C257.135 417.727 256.443 417.613 255.815 417.5C255.187 417.387 254.504 417.208 253.765 416.966L254.265 415.378C254.647 415.508 254.997 415.638 255.323 415.767C255.648 415.897 255.95 416.034 256.26 416.18C256.563 416.326 256.865 416.48 257.167 416.634C257.469 416.787 257.787 416.949 258.128 417.111C258.065 416.723 258.001 416.358 257.946 416.01C257.89 415.67 257.842 415.33 257.803 414.998C257.763 414.666 257.731 414.326 257.707 413.97C257.683 413.621 257.675 413.241 257.675 412.836H259.305C259.305 413.241 259.289 413.621 259.265 413.97C259.241 414.318 259.209 414.658 259.17 414.998C259.13 415.33 259.082 415.67 259.027 416.01C258.971 416.35 258.907 416.715 258.844 417.111C259.178 416.949 259.496 416.787 259.798 416.634C260.1 416.48 260.402 416.326 260.712 416.18C261.014 416.034 261.324 415.897 261.642 415.767C261.96 415.638 262.309 415.508 262.699 415.378L263.2 416.966C262.452 417.208 261.761 417.387 261.125 417.5C260.521 417.605 259.829 417.719 259.074 417.832Z" fill="#F2F2F2"/>
            <path d="M132.091 315.761C132.631 316.311 133.124 316.821 133.561 317.275C133.999 317.728 134.46 318.287 134.936 318.935L133.585 319.939C133.363 319.615 133.156 319.299 132.973 319C132.79 318.7 132.615 318.4 132.457 318.101C132.298 317.801 132.139 317.493 131.988 317.17C131.837 316.854 131.67 316.522 131.495 316.182C131.32 316.522 131.153 316.854 131.002 317.17C130.851 317.485 130.692 317.793 130.533 318.101C130.374 318.4 130.199 318.7 130.016 319C129.833 319.299 129.627 319.615 129.404 319.939L128.053 318.935C128.291 318.611 128.522 318.311 128.745 318.044C128.967 317.777 129.19 317.518 129.412 317.275C129.635 317.032 129.873 316.789 130.12 316.546C130.366 316.303 130.628 316.044 130.899 315.769C130.151 315.655 129.46 315.542 128.832 315.429C128.204 315.315 127.52 315.137 126.781 314.894L127.282 313.307C127.664 313.437 128.013 313.566 128.339 313.696C128.665 313.825 128.967 313.963 129.277 314.109C129.579 314.255 129.881 314.408 130.183 314.562C130.485 314.716 130.803 314.878 131.145 315.04C131.081 314.651 131.018 314.287 130.962 313.939C130.907 313.599 130.859 313.259 130.819 312.927C130.779 312.595 130.748 312.255 130.724 311.898C130.7 311.55 130.692 311.17 130.692 310.765H132.321C132.321 311.17 132.306 311.55 132.282 311.898C132.258 312.246 132.226 312.587 132.186 312.927C132.147 313.259 132.099 313.599 132.043 313.939C131.988 314.279 131.924 314.643 131.86 315.04C132.194 314.878 132.512 314.716 132.814 314.562C133.116 314.408 133.418 314.255 133.728 314.109C134.03 313.963 134.34 313.825 134.658 313.696C134.976 313.566 135.326 313.437 135.715 313.307L136.216 314.894C135.469 315.137 134.778 315.315 134.142 315.429C133.538 315.534 132.846 315.647 132.091 315.761Z" fill="#F2F2F2"/>
            <path d="M312.534 387.14C312.853 387.431 313.144 387.701 313.401 387.941C313.659 388.181 313.931 388.477 314.212 388.82L313.415 389.352C313.284 389.18 313.162 389.013 313.054 388.855C312.947 388.696 312.844 388.537 312.75 388.379C312.656 388.22 312.562 388.057 312.473 387.886C312.384 387.718 312.286 387.543 312.183 387.363C312.08 387.543 311.981 387.718 311.892 387.886C311.803 388.053 311.709 388.216 311.616 388.379C311.522 388.537 311.419 388.696 311.311 388.855C311.203 389.013 311.081 389.18 310.95 389.352L310.153 388.82C310.294 388.649 310.43 388.49 310.561 388.349C310.692 388.207 310.823 388.07 310.955 387.941C311.086 387.813 311.227 387.684 311.372 387.555C311.517 387.427 311.672 387.29 311.831 387.144C311.391 387.084 310.983 387.024 310.613 386.964C310.242 386.904 309.839 386.809 309.403 386.681L309.699 385.84C309.924 385.909 310.13 385.978 310.322 386.046C310.514 386.115 310.692 386.188 310.875 386.265C311.053 386.342 311.231 386.424 311.409 386.505C311.587 386.586 311.775 386.672 311.976 386.758C311.939 386.552 311.901 386.359 311.869 386.175C311.836 385.995 311.808 385.815 311.784 385.639C311.761 385.463 311.742 385.283 311.728 385.094C311.714 384.91 311.709 384.709 311.709 384.494H312.67C312.67 384.709 312.661 384.91 312.647 385.094C312.633 385.279 312.614 385.459 312.59 385.639C312.567 385.815 312.539 385.995 312.506 386.175C312.473 386.355 312.436 386.548 312.398 386.758C312.595 386.672 312.783 386.586 312.961 386.505C313.139 386.424 313.317 386.342 313.5 386.265C313.678 386.188 313.861 386.115 314.048 386.046C314.236 385.978 314.442 385.909 314.671 385.84L314.967 386.681C314.526 386.809 314.118 386.904 313.743 386.964C313.387 387.02 312.979 387.08 312.534 387.14Z" fill="#F2F2F2"/>
            <path d="M209.437 235.085C209.755 235.376 210.046 235.647 210.304 235.887C210.561 236.127 210.833 236.423 211.114 236.766L210.318 237.297C210.186 237.126 210.065 236.959 209.957 236.8C209.849 236.641 209.746 236.483 209.652 236.324C209.558 236.165 209.465 236.002 209.376 235.831C209.287 235.664 209.188 235.488 209.085 235.308C208.982 235.488 208.883 235.664 208.794 235.831C208.705 235.998 208.612 236.161 208.518 236.324C208.424 236.483 208.321 236.641 208.213 236.8C208.105 236.959 207.984 237.126 207.852 237.297L207.056 236.766C207.196 236.594 207.332 236.435 207.463 236.294C207.595 236.152 207.726 236.015 207.857 235.887C207.988 235.758 208.129 235.629 208.274 235.501C208.419 235.372 208.574 235.235 208.733 235.089C208.293 235.029 207.885 234.969 207.515 234.909C207.145 234.849 206.742 234.755 206.306 234.626L206.601 233.786C206.826 233.854 207.032 233.923 207.224 233.992C207.416 234.06 207.595 234.133 207.777 234.21C207.955 234.287 208.134 234.369 208.312 234.45C208.49 234.532 208.677 234.618 208.879 234.703C208.841 234.497 208.804 234.305 208.771 234.12C208.738 233.94 208.71 233.76 208.687 233.584C208.663 233.408 208.644 233.228 208.63 233.04C208.616 232.855 208.612 232.654 208.612 232.439H209.572C209.572 232.654 209.563 232.855 209.549 233.04C209.535 233.224 209.516 233.404 209.493 233.584C209.469 233.76 209.441 233.94 209.408 234.12C209.376 234.3 209.338 234.493 209.301 234.703C209.497 234.618 209.685 234.532 209.863 234.45C210.041 234.369 210.219 234.287 210.402 234.21C210.58 234.133 210.763 234.06 210.95 233.992C211.138 233.923 211.344 233.854 211.574 233.786L211.869 234.626C211.428 234.755 211.021 234.849 210.646 234.909C210.29 234.965 209.882 235.025 209.437 235.085Z" fill="#F2F2F2"/>
            <path d="M279.441 31.3739C279.76 31.6655 280.051 31.9356 280.309 32.1757C280.566 32.4158 280.838 32.7117 281.119 33.0547L280.323 33.5863C280.191 33.4148 280.069 33.2476 279.962 33.089C279.854 32.9303 279.751 32.7717 279.657 32.613C279.563 32.4544 279.47 32.2915 279.38 32.12C279.291 31.9528 279.193 31.777 279.09 31.5969C278.987 31.777 278.888 31.9528 278.799 32.12C278.71 32.2872 278.617 32.4501 278.523 32.613C278.429 32.7717 278.326 32.9303 278.218 33.089C278.11 33.2476 277.988 33.4148 277.857 33.5863L277.06 33.0547C277.201 32.8832 277.337 32.7245 277.468 32.583C277.599 32.4415 277.731 32.3043 277.862 32.1757C277.993 32.0471 278.134 31.9185 278.279 31.7898C278.424 31.6612 278.579 31.524 278.738 31.3782C278.298 31.3182 277.89 31.2582 277.52 31.1982C277.15 31.1381 276.746 31.0438 276.311 30.9152L276.606 30.0748C276.831 30.1434 277.037 30.212 277.229 30.2806C277.421 30.3492 277.599 30.4221 277.782 30.4993C277.96 30.5765 278.138 30.6579 278.317 30.7394C278.495 30.8208 278.682 30.9066 278.884 30.9923C278.846 30.7865 278.809 30.5936 278.776 30.4092C278.743 30.2292 278.715 30.0491 278.692 29.8733C278.668 29.6975 278.649 29.5174 278.635 29.3288C278.621 29.1444 278.617 28.9429 278.617 28.7285H279.577C279.577 28.9429 279.568 29.1444 279.554 29.3288C279.54 29.5131 279.521 29.6932 279.498 29.8733C279.474 30.0491 279.446 30.2292 279.413 30.4092C279.38 30.5893 279.343 30.7823 279.305 30.9923C279.502 30.9066 279.69 30.8208 279.868 30.7394C280.046 30.6579 280.224 30.5765 280.407 30.4993C280.585 30.4221 280.768 30.3492 280.955 30.2806C281.143 30.212 281.349 30.1434 281.579 30.0748L281.874 30.9152C281.433 31.0438 281.026 31.1381 280.651 31.1982C280.294 31.2539 279.887 31.3139 279.441 31.3739Z" fill="#F2F2F2"/>
            <path d="M21.8623 425.372C22.181 425.664 22.4716 425.934 22.7294 426.174C22.9872 426.414 23.259 426.71 23.5402 427.053L22.7435 427.584C22.6122 427.413 22.4904 427.246 22.3826 427.087C22.2748 426.928 22.1717 426.77 22.0779 426.611C21.9842 426.452 21.8904 426.29 21.8014 426.118C21.7123 425.951 21.6139 425.775 21.5108 425.595C21.4077 425.775 21.3093 425.951 21.2202 426.118C21.1312 426.285 21.0374 426.448 20.9437 426.611C20.8499 426.77 20.7468 426.928 20.639 427.087C20.5312 427.246 20.4094 427.413 20.2781 427.584L19.4813 427.053C19.622 426.881 19.7579 426.723 19.8891 426.581C20.0203 426.44 20.1516 426.302 20.2828 426.174C20.4141 426.045 20.5547 425.917 20.7 425.788C20.8453 425.659 20.9999 425.522 21.1593 425.376C20.7187 425.316 20.3109 425.256 19.9407 425.196C19.5704 425.136 19.1673 425.042 18.7314 424.913L19.0267 424.073C19.2517 424.141 19.4579 424.21 19.6501 424.279C19.8422 424.347 20.0203 424.42 20.2031 424.497C20.3812 424.575 20.5594 424.656 20.7375 424.737C20.9156 424.819 21.103 424.905 21.3046 424.99C21.2671 424.785 21.2296 424.592 21.1968 424.407C21.164 424.227 21.1358 424.047 21.1124 423.871C21.089 423.696 21.0702 423.515 21.0562 423.327C21.0421 423.142 21.0374 422.941 21.0374 422.727H21.9982C21.9982 422.941 21.9889 423.142 21.9748 423.327C21.9607 423.511 21.942 423.691 21.9186 423.871C21.8951 424.047 21.867 424.227 21.8342 424.407C21.8014 424.587 21.7639 424.78 21.7264 424.99C21.9232 424.905 22.1107 424.819 22.2888 424.737C22.4669 424.656 22.645 424.575 22.8278 424.497C23.0059 424.42 23.1887 424.347 23.3762 424.279C23.5637 424.21 23.7699 424.141 23.9996 424.073L24.2949 424.913C23.8543 425.042 23.4465 425.136 23.0715 425.196C22.7153 425.252 22.3076 425.312 21.8623 425.372Z" fill="#F2F2F2"/>
            <path d="M123.051 655.79C123.37 656.082 123.66 656.352 123.918 656.592C124.176 656.832 124.448 657.128 124.729 657.471L123.932 658.002C123.801 657.831 123.679 657.664 123.571 657.505C123.463 657.346 123.36 657.188 123.266 657.029C123.173 656.87 123.079 656.707 122.99 656.536C122.901 656.369 122.802 656.193 122.699 656.013C122.596 656.193 122.498 656.369 122.409 656.536C122.32 656.703 122.226 656.866 122.132 657.029C122.038 657.188 121.935 657.346 121.827 657.505C121.72 657.664 121.598 657.831 121.467 658.002L120.67 657.471C120.81 657.299 120.946 657.141 121.078 656.999C121.209 656.858 121.34 656.72 121.471 656.592C121.603 656.463 121.743 656.334 121.888 656.206C122.034 656.077 122.188 655.94 122.348 655.794C121.907 655.734 121.499 655.674 121.129 655.614C120.759 655.554 120.356 655.46 119.92 655.331L120.215 654.491C120.44 654.559 120.646 654.628 120.839 654.697C121.031 654.765 121.209 654.838 121.392 654.915C121.57 654.992 121.748 655.074 121.926 655.155C122.104 655.237 122.292 655.323 122.493 655.408C122.456 655.203 122.418 655.01 122.385 654.825C122.352 654.645 122.324 654.465 122.301 654.289C122.277 654.114 122.259 653.933 122.245 653.745C122.231 653.56 122.226 653.359 122.226 653.145H123.187C123.187 653.359 123.177 653.56 123.163 653.745C123.149 653.929 123.13 654.109 123.107 654.289C123.084 654.465 123.055 654.645 123.023 654.825C122.99 655.005 122.952 655.198 122.915 655.408C123.112 655.323 123.299 655.237 123.477 655.155C123.655 655.074 123.834 654.992 124.016 654.915C124.194 654.838 124.377 654.765 124.565 654.697C124.752 654.628 124.958 654.559 125.188 654.491L125.483 655.331C125.043 655.46 124.635 655.554 124.26 655.614C123.904 655.67 123.496 655.73 123.051 655.79Z" fill="#F2F2F2"/>
            <path d="M257.484 368.438C258.025 368.989 258.518 369.499 258.955 369.953C259.392 370.406 259.853 370.965 260.33 371.612L258.979 372.617C258.756 372.293 258.55 371.977 258.367 371.677C258.184 371.378 258.009 371.078 257.85 370.778C257.691 370.479 257.532 370.171 257.381 369.847C257.23 369.532 257.063 369.2 256.888 368.859C256.713 369.2 256.547 369.532 256.396 369.847C256.244 370.163 256.086 370.471 255.927 370.778C255.768 371.086 255.593 371.378 255.41 371.677C255.227 371.977 255.02 372.293 254.798 372.617L253.447 371.612C253.685 371.289 253.916 370.989 254.138 370.722C254.361 370.455 254.583 370.195 254.806 369.953C255.028 369.71 255.267 369.467 255.513 369.224C255.76 368.981 256.022 368.722 256.292 368.446C255.545 368.333 254.853 368.22 254.226 368.106C253.598 367.993 252.914 367.815 252.175 367.572L252.676 365.985C253.057 366.114 253.407 366.244 253.733 366.374C254.051 366.503 254.361 366.641 254.671 366.787C254.973 366.932 255.275 367.086 255.577 367.24C255.879 367.394 256.197 367.556 256.539 367.718C256.475 367.329 256.411 366.965 256.356 366.616C256.3 366.276 256.252 365.936 256.213 365.604C256.173 365.272 256.141 364.932 256.117 364.576C256.093 364.228 256.085 363.847 256.085 363.442H257.715C257.715 363.847 257.699 364.228 257.675 364.576C257.651 364.924 257.62 365.264 257.58 365.604C257.54 365.936 257.492 366.276 257.437 366.616C257.381 366.957 257.318 367.321 257.254 367.718C257.588 367.556 257.906 367.394 258.208 367.24C258.51 367.086 258.812 366.932 259.122 366.787C259.424 366.641 259.734 366.503 260.052 366.374C260.37 366.244 260.72 366.114 261.109 365.985L261.61 367.572C260.863 367.815 260.171 367.993 259.535 368.106C258.923 368.212 258.232 368.325 257.484 368.438Z" fill="#F2F2F2"/>
            <path d="M265.42 536.198C265.961 536.749 266.453 537.259 266.89 537.712C267.328 538.166 267.789 538.724 268.266 539.372L266.914 540.376C266.692 540.052 266.485 539.737 266.302 539.437C266.119 539.137 265.945 538.838 265.786 538.538C265.627 538.239 265.468 537.931 265.317 537.607C265.166 537.291 264.999 536.959 264.824 536.619C264.649 536.959 264.482 537.291 264.331 537.607C264.18 537.923 264.021 538.231 263.862 538.538C263.703 538.846 263.528 539.137 263.345 539.437C263.163 539.737 262.956 540.052 262.733 540.376L261.382 539.372C261.621 539.048 261.851 538.749 262.074 538.482C262.296 538.214 262.519 537.955 262.741 537.712C262.964 537.469 263.202 537.226 263.449 536.984C263.695 536.741 263.957 536.482 264.228 536.206C263.481 536.093 262.789 535.98 262.161 535.866C261.533 535.753 260.85 535.575 260.11 535.332L260.611 533.745C260.993 533.874 261.342 534.004 261.668 534.133C261.986 534.263 262.296 534.401 262.606 534.546C262.908 534.692 263.21 534.846 263.512 535C263.814 535.154 264.132 535.316 264.474 535.477C264.411 535.089 264.347 534.724 264.291 534.376C264.236 534.036 264.188 533.696 264.148 533.364C264.108 533.032 264.077 532.692 264.053 532.336C264.029 531.988 264.021 531.607 264.021 531.202H265.651C265.651 531.607 265.635 531.988 265.611 532.336C265.587 532.684 265.555 533.024 265.515 533.364C265.476 533.696 265.428 534.036 265.372 534.376C265.317 534.716 265.253 535.081 265.189 535.477C265.523 535.316 265.841 535.154 266.143 535C266.445 534.846 266.747 534.692 267.057 534.546C267.359 534.401 267.669 534.263 267.987 534.133C268.305 534.004 268.655 533.874 269.045 533.745L269.545 535.332C268.798 535.575 268.107 535.753 267.471 535.866C266.859 535.971 266.167 536.085 265.42 536.198Z" fill="#F2F2F2"/>
            <path d="M130.501 266.367C131.042 266.918 131.534 267.428 131.972 267.881C132.409 268.335 132.87 268.893 133.347 269.541L131.995 270.545C131.773 270.221 131.566 269.906 131.383 269.606C131.201 269.306 131.026 269.007 130.867 268.707C130.708 268.408 130.549 268.1 130.398 267.776C130.247 267.46 130.08 267.128 129.905 266.788C129.73 267.128 129.563 267.46 129.412 267.776C129.261 268.092 129.102 268.399 128.943 268.707C128.784 269.015 128.609 269.306 128.426 269.606C128.244 269.906 128.037 270.221 127.814 270.545L126.463 269.541C126.702 269.217 126.932 268.918 127.155 268.651C127.377 268.383 127.6 268.124 127.822 267.881C128.045 267.638 128.283 267.395 128.53 267.153C128.776 266.91 129.039 266.65 129.309 266.375C128.562 266.262 127.87 266.148 127.242 266.035C126.614 265.922 125.931 265.744 125.191 265.501L125.692 263.914C126.074 264.043 126.423 264.173 126.749 264.302C127.067 264.432 127.377 264.57 127.687 264.715C127.989 264.861 128.291 265.015 128.593 265.169C128.895 265.323 129.213 265.484 129.555 265.646C129.492 265.258 129.428 264.893 129.372 264.545C129.317 264.205 129.269 263.865 129.229 263.533C129.19 263.201 129.158 262.861 129.134 262.505C129.11 262.157 129.102 261.776 129.102 261.371H130.732C130.732 261.776 130.716 262.157 130.692 262.505C130.668 262.853 130.636 263.193 130.596 263.533C130.557 263.865 130.509 264.205 130.453 264.545C130.398 264.885 130.334 265.25 130.271 265.646C130.604 265.484 130.922 265.323 131.224 265.169C131.526 265.015 131.828 264.861 132.138 264.715C132.441 264.57 132.751 264.432 133.068 264.302C133.386 264.173 133.736 264.043 134.126 263.914L134.626 265.501C133.879 265.744 133.188 265.922 132.552 266.035C131.94 266.14 131.248 266.254 130.501 266.367Z" fill="#F2F2F2"/>
            <path d="M68.0011 637.091C68.5416 637.641 69.0344 638.151 69.4715 638.605C69.9087 639.058 70.3697 639.617 70.8466 640.265L69.4954 641.269C69.2728 640.945 69.0662 640.629 68.8833 640.33C68.7005 640.03 68.5257 639.73 68.3667 639.431C68.2077 639.131 68.0487 638.824 67.8977 638.5C67.7467 638.184 67.5798 637.852 67.4049 637.512C67.23 637.852 67.0631 638.184 66.9121 638.5C66.7611 638.815 66.6021 639.123 66.4431 639.431C66.2842 639.739 66.1093 640.03 65.9265 640.33C65.7436 640.629 65.537 640.945 65.3144 641.269L63.9632 640.265C64.2016 639.941 64.4321 639.641 64.6547 639.374C64.8773 639.107 65.0998 638.848 65.3224 638.605C65.5449 638.362 65.7834 638.119 66.0298 637.876C66.2762 637.633 66.5385 637.374 66.8088 637.099C66.0616 636.985 65.3701 636.872 64.7421 636.759C64.1142 636.645 63.4306 636.467 62.6914 636.224L63.1921 634.637C63.5737 634.767 63.9234 634.896 64.2493 635.026C64.5673 635.155 64.8773 635.293 65.1872 635.439C65.4893 635.585 65.7914 635.738 66.0934 635.892C66.3954 636.046 66.7134 636.208 67.0552 636.37C66.9916 635.981 66.928 635.617 66.8723 635.269C66.8167 634.929 66.769 634.589 66.7293 634.257C66.6895 633.925 66.6577 633.585 66.6339 633.228C66.6101 632.88 66.6021 632.5 66.6021 632.095H68.2316C68.2316 632.5 68.2157 632.88 68.1918 633.228C68.168 633.577 68.1362 633.917 68.0964 634.257C68.0567 634.589 68.009 634.929 67.9534 635.269C67.8977 635.609 67.8341 635.973 67.7705 636.37C68.1044 636.208 68.4223 636.046 68.7244 635.892C69.0264 635.738 69.3285 635.585 69.6385 635.439C69.9405 635.293 70.2505 635.155 70.5685 635.026C70.8864 634.896 71.2361 634.767 71.6256 634.637L72.1264 636.224C71.3792 636.467 70.6877 636.645 70.0518 636.759C69.4397 636.864 68.7482 636.977 68.0011 637.091Z" fill="#F2F2F2"/>
            <path d="M45.2677 843.822C45.8082 844.373 46.301 844.883 46.7382 845.336C47.1753 845.79 47.6363 846.348 48.1132 846.996L46.762 848C46.5394 847.676 46.3328 847.361 46.1499 847.061C45.9671 846.761 45.7923 846.462 45.6333 846.162C45.4743 845.863 45.3153 845.555 45.1643 845.231C45.0133 844.915 44.8464 844.583 44.6715 844.243C44.4966 844.583 44.3297 844.915 44.1787 845.231C44.0277 845.547 43.8687 845.855 43.7097 846.162C43.5508 846.47 43.3759 846.761 43.1931 847.061C43.0102 847.361 42.8036 847.676 42.581 848L41.2298 846.996C41.4682 846.672 41.6987 846.373 41.9213 846.106C42.1439 845.838 42.3664 845.579 42.589 845.336C42.8115 845.093 43.05 844.851 43.2964 844.608C43.5428 844.365 43.8051 844.106 44.0754 843.83C43.3282 843.717 42.6367 843.604 42.0087 843.49C41.3808 843.377 40.6972 843.199 39.958 842.956L40.4587 841.369C40.8403 841.498 41.19 841.628 41.5159 841.757C41.8339 841.887 42.1439 842.025 42.4539 842.17C42.7559 842.316 43.058 842.47 43.36 842.624C43.662 842.778 43.98 842.94 44.3218 843.102C44.2582 842.713 44.1946 842.348 44.1389 842C44.0833 841.66 44.0356 841.32 43.9959 840.988C43.9561 840.656 43.9243 840.316 43.9005 839.96C43.8767 839.612 43.8687 839.231 43.8687 838.826H45.4982C45.4982 839.231 45.4823 839.612 45.4584 839.96C45.4346 840.308 45.4028 840.648 45.363 840.988C45.3233 841.32 45.2756 841.66 45.22 842C45.1643 842.34 45.1007 842.705 45.0371 843.102C45.371 842.94 45.6889 842.778 45.991 842.624C46.293 842.47 46.5951 842.316 46.9051 842.17C47.2071 842.025 47.5171 841.887 47.8351 841.757C48.153 841.628 48.5027 841.498 48.8922 841.369L49.393 842.956C48.6458 843.199 47.9543 843.377 47.3184 843.49C46.7063 843.595 46.0148 843.709 45.2677 843.822Z" fill="#F2F2F2"/>
            <path d="M248.163 788.329C248.482 788.669 248.772 788.984 249.03 789.264C249.288 789.544 249.56 789.889 249.841 790.289L249.044 790.91C248.913 790.71 248.791 790.514 248.683 790.329C248.576 790.144 248.472 789.959 248.379 789.774C248.285 789.589 248.191 789.399 248.102 789.199C248.013 789.004 247.915 788.799 247.812 788.589C247.708 788.799 247.61 789.004 247.521 789.199C247.432 789.394 247.338 789.584 247.244 789.774C247.151 789.964 247.048 790.144 246.94 790.329C246.832 790.514 246.71 790.71 246.579 790.91L245.782 790.289C245.923 790.089 246.059 789.904 246.19 789.739C246.321 789.574 246.452 789.414 246.584 789.264C246.715 789.114 246.855 788.964 247.001 788.814C247.146 788.664 247.301 788.504 247.46 788.334C247.019 788.263 246.612 788.193 246.241 788.123C245.871 788.053 245.468 787.943 245.032 787.793L245.327 786.813C245.552 786.893 245.759 786.973 245.951 787.053C246.138 787.133 246.321 787.218 246.504 787.308C246.682 787.398 246.86 787.493 247.038 787.588C247.216 787.683 247.404 787.783 247.605 787.883C247.568 787.643 247.53 787.418 247.498 787.203C247.465 786.993 247.437 786.783 247.413 786.578C247.39 786.373 247.371 786.163 247.357 785.942C247.343 785.727 247.338 785.492 247.338 785.242H248.299C248.299 785.492 248.29 785.727 248.276 785.942C248.262 786.158 248.243 786.368 248.219 786.578C248.196 786.783 248.168 786.993 248.135 787.203C248.102 787.413 248.065 787.638 248.027 787.883C248.224 787.783 248.412 787.683 248.59 787.588C248.768 787.493 248.946 787.398 249.129 787.308C249.307 787.218 249.49 787.133 249.677 787.053C249.864 786.973 250.071 786.893 250.3 786.813L250.596 787.793C250.155 787.943 249.747 788.053 249.372 788.123C249.011 788.188 248.604 788.258 248.163 788.329Z" fill="#F2F2F2"/>
            <path d="M349.055 662.355C349.373 662.695 349.664 663.01 349.922 663.29C350.18 663.57 350.451 663.916 350.733 664.316L349.936 664.936C349.805 664.736 349.683 664.541 349.575 664.356C349.467 664.171 349.364 663.986 349.27 663.801C349.177 663.615 349.083 663.425 348.994 663.225C348.905 663.03 348.806 662.825 348.703 662.615C348.6 662.825 348.502 663.03 348.413 663.225C348.324 663.42 348.23 663.61 348.136 663.801C348.042 663.991 347.939 664.171 347.831 664.356C347.724 664.541 347.602 664.736 347.471 664.936L346.674 664.316C346.814 664.116 346.95 663.931 347.082 663.765C347.213 663.6 347.344 663.44 347.475 663.29C347.606 663.14 347.747 662.99 347.892 662.84C348.038 662.69 348.192 662.53 348.352 662.36C347.911 662.29 347.503 662.22 347.133 662.15C346.763 662.08 346.36 661.97 345.924 661.82L346.219 660.839C346.444 660.919 346.65 660.999 346.842 661.079C347.03 661.159 347.213 661.244 347.396 661.334C347.574 661.424 347.752 661.52 347.93 661.615C348.108 661.71 348.295 661.81 348.497 661.91C348.459 661.67 348.422 661.444 348.389 661.229C348.356 661.019 348.328 660.809 348.305 660.604C348.281 660.399 348.263 660.189 348.249 659.969C348.234 659.754 348.23 659.519 348.23 659.269H349.191C349.191 659.519 349.181 659.754 349.167 659.969C349.153 660.184 349.134 660.394 349.111 660.604C349.088 660.809 349.059 661.019 349.027 661.229C348.994 661.439 348.956 661.665 348.919 661.91C349.116 661.81 349.303 661.71 349.481 661.615C349.659 661.52 349.837 661.424 350.02 661.334C350.198 661.244 350.381 661.159 350.569 661.079C350.756 660.999 350.962 660.919 351.192 660.839L351.487 661.82C351.047 661.97 350.639 662.08 350.264 662.15C349.903 662.215 349.495 662.285 349.055 662.355Z" fill="#F2F2F2"/>
            <path d="M209.494 69.768C209.813 70.1081 210.103 70.4233 210.361 70.7034C210.619 70.9835 210.891 71.3286 211.172 71.7288L210.375 72.3491C210.244 72.149 210.122 71.9539 210.014 71.7688C209.907 71.5838 209.803 71.3987 209.71 71.2136C209.616 71.0285 209.522 70.8384 209.433 70.6383C209.344 70.4433 209.246 70.2382 209.143 70.0281C209.04 70.2382 208.941 70.4433 208.852 70.6383C208.763 70.8334 208.669 71.0235 208.576 71.2136C208.482 71.4037 208.379 71.5838 208.271 71.7688C208.163 71.9539 208.041 72.149 207.91 72.3491L207.113 71.7288C207.254 71.5287 207.39 71.3437 207.521 71.1786C207.652 71.0135 207.783 70.8534 207.915 70.7034C208.046 70.5533 208.186 70.4032 208.332 70.2532C208.477 70.1031 208.632 69.943 208.791 69.773C208.351 69.7029 207.943 69.6329 207.573 69.5629C207.202 69.4929 206.799 69.3828 206.363 69.2327L206.659 68.2523C206.884 68.3324 207.09 68.4124 207.282 68.4924C207.469 68.5725 207.652 68.6575 207.835 68.7475C208.013 68.8376 208.191 68.9326 208.369 69.0277C208.547 69.1227 208.735 69.2227 208.936 69.3228C208.899 69.0827 208.861 68.8576 208.829 68.6425C208.796 68.4324 208.768 68.2223 208.744 68.0172C208.721 67.8121 208.702 67.602 208.688 67.3819C208.674 67.1669 208.669 66.9317 208.669 66.6816H209.63C209.63 66.9317 209.621 67.1669 209.607 67.3819C209.593 67.597 209.574 67.8071 209.55 68.0172C209.527 68.2223 209.499 68.4324 209.466 68.6425C209.433 68.8526 209.396 69.0777 209.358 69.3228C209.555 69.2227 209.743 69.1227 209.921 69.0277C210.099 68.9326 210.277 68.8376 210.46 68.7475C210.638 68.6575 210.821 68.5725 211.008 68.4924C211.196 68.4124 211.402 68.3324 211.631 68.2523L211.927 69.2327C211.486 69.3828 211.078 69.4929 210.703 69.5629C210.342 69.6279 209.935 69.6979 209.494 69.768Z" fill="#F2F2F2"/>
            <path d="M82.5108 37.8002C82.8295 38.1403 83.1201 38.4555 83.3778 38.7356C83.6356 39.0157 83.9075 39.3609 84.1887 39.761L83.3919 40.3813C83.2607 40.1812 83.1388 39.9861 83.031 39.8011C82.9232 39.616 82.8201 39.4309 82.7264 39.2458C82.6326 39.0607 82.5389 38.8707 82.4498 38.6706C82.3608 38.4755 82.2624 38.2704 82.1592 38.0603C82.0561 38.2704 81.9577 38.4755 81.8687 38.6706C81.7796 38.8657 81.6859 39.0557 81.5921 39.2458C81.4984 39.4359 81.3953 39.616 81.2875 39.8011C81.1797 39.9861 81.0578 40.1812 80.9266 40.3813L80.1298 39.761C80.2704 39.561 80.4063 39.3759 80.5376 39.2108C80.6688 39.0457 80.8 38.8857 80.9313 38.7356C81.0625 38.5855 81.2031 38.4355 81.3484 38.2854C81.4937 38.1353 81.6484 37.9753 81.8077 37.8052C81.3671 37.7352 80.9594 37.6651 80.5891 37.5951C80.2188 37.5251 79.8158 37.415 79.3799 37.265L79.6751 36.2845C79.9001 36.3646 80.1064 36.4446 80.2985 36.5246C80.486 36.6047 80.6688 36.6897 80.8516 36.7798C81.0297 36.8698 81.2078 36.9648 81.3859 37.0599C81.564 37.1549 81.7515 37.255 81.953 37.355C81.9155 37.1149 81.878 36.8898 81.8452 36.6747C81.8124 36.4646 81.7843 36.2545 81.7608 36.0494C81.7374 35.8444 81.7187 35.6343 81.7046 35.4142C81.6905 35.1991 81.6859 34.964 81.6859 34.7139H82.6467C82.6467 34.964 82.6373 35.1991 82.6232 35.4142C82.6092 35.6293 82.5904 35.8394 82.567 36.0494C82.5436 36.2545 82.5154 36.4646 82.4826 36.6747C82.4498 36.8848 82.4123 37.1099 82.3748 37.355C82.5717 37.255 82.7592 37.1549 82.9373 37.0599C83.1154 36.9648 83.2935 36.8698 83.4763 36.7798C83.6544 36.6897 83.8372 36.6047 84.0247 36.5246C84.2121 36.4446 84.4183 36.3646 84.648 36.2845L84.9433 37.265C84.5027 37.415 84.0949 37.5251 83.72 37.5951C83.3591 37.6601 82.9513 37.7302 82.5108 37.8002Z" fill="#F2F2F2"/>
            </g>
        </svg>
    )
};

export default StarsMessagePageMob;
