import React from 'react'
import AsyncSelect from 'react-select/async';
import { selectStyles, SRow, SSelectContainer } from '../FormStyle';
import { useTranslation } from 'react-i18next';
import { useFetchAuth } from '../../_Core/AuthHandling';
import { StarSlider } from './StarSlider';

export interface ISkill {
  skill_id: string
  title: string
  level: number
}

export const SkillSelectField = ({...props}:any) => {
  const t = useTranslation().t;
  const fetchAuth = useFetchAuth()

  async function usePromiseOptions(inputValue:string){
    return await fetchAuth(
      window.__API_URL__ + `/definitions/skills?q=${inputValue}`, 
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      }
    )
    .then(data => data.map((elem:any) => {return{value:elem.id, label:elem.title}})) as Promise<{value:string, label:string}[]>
  }

  const skillsDisplay = (props.skills.size === 0 && props.readOnly) ? [<div>{t("Base.empty")}</div>] 
  : props.skills.map((elem:ISkill, index:number) => {
      return(
        <SRow>
          <StarSlider 
            key={elem.title}
            name={elem.title}
            readonly = {props.readonly}
            value={elem.level}
            onClick={(value:number) => { 
                let skills_tmp = [...props.skills]
                skills_tmp[index] = {skill_id: elem.skill_id, title: elem.title, level:value}
                props.setSkills(skills_tmp);
              }
            }
            onDelete={() => {
                let skills_tmp = [...props.skills]
                skills_tmp.splice(index, 1)
                props.setSkills(skills_tmp)
              }
            }
          />
        </SRow>
      )
    }
  )

  const distabledOptions = new Set()
  props.skills.map((elem:ISkill) => {
    distabledOptions.add(elem.skill_id)
    return true
  })

  return(
    <>
      <SSelectContainer>
        {! props.readOnly ? 
          <AsyncSelect
            styles={selectStyles}
            cacheOptions
            placeholder={t('ProfileSkills.skillsSelectPlaceholder') as string}
            value={{value:"", label:t('Base.Search')}}
            loadOptions={usePromiseOptions}
            onChange={
              (s: any, action: any) => {
                if(action.action==="select-option"){
                  let skills_tmp = [...props.skills]
                  skills_tmp.push({
                    skill_id:s.value as string, 
                    title:s.label as string, 
                    ranking:0
                  })
                  props.setSkills(skills_tmp);
                }
              }
            }
            isOptionDisabled={(option) => distabledOptions.has(option.value)}
        /> : <></> }
        {skillsDisplay}
      </SSelectContainer>
    </>
  )
}
