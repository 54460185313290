import React from 'react';
import MainMenu from '../_Menu/MainMenu';
import UserFormPortfolio from '../_UserForm/Portfolio'
import ProfileContainer from './ProfileContainer';


export default function VProfileContact({...props}:any) {
  return(
    <>
      <MainMenu pos={3}/>
      <ProfileContainer pos={4}>
        <UserFormPortfolio/>
      </ProfileContainer>
    </>
  )
}