import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import "./FormStyle.css"

const MENUITEMIDS = {
  info: 1,
  activities: 2,
  notes: 3,
  company: 4
}

interface IInfo {
  id: string,
  title: string,
  company: string,
  url: string,
  salary: string,
  location: string,
  background: string,
  description: string
}

interface IHeader {
  title: string,
  company_name: string,
  url: string,
  background: string,
}

interface IMenu {
  pos: number,
  id: string,
  type: string,
  onClickMenuItem: Function,
}

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;

  z-index: 4;
  background: rgba(0,0,0,0.7);
`

const Overlay = styled.div`
  margin: 40px auto auto;
  @media (max-width: 797px) {
    width: 100vw;
  }
  @media (min-width: 798px) {
    width: 798px;
  }

  background: #FFFFFF;
  border-radius: 4px;
`

const SHeaderContainer = styled.div`
  display: inline-grid;
  grid-template-columns:  92px auto;
  grid-template-rows: auto;
  grid-template-areas: "logo title";
`

const SLogoContainer = styled.div`
  grid-area: logo;
  padding-top: 28px;
  padding-left: 48px;
`

const SLogo = styled.img`
  border-radius: 50%;
`

const STitleContainer = styled.div`
  grid-area: title;
  padding-top: 10px;
  padding-left: 25px;
`

const STitle = styled.div`
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  color: #000000;
`

const SCompany = styled.div`
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 143.2%;
  color: #333333;
`

const SMenu = styled.div`
  position: relative;
  margin-top: 40px;
  margin-left: 3px;
  margin-right: 3px;
  height: 36px;
  @media (max-width: 797px) {
    width: calc(100vw-4px); 
  }
  @media (min-width: 798px) {
    width: 792px;
  }
  overflow-x: scroll;
  
  border-bottom: 1px solid #E0E0E0;
`

const SMenuItem = styled("div")<{pos:number, active:boolean}>`
  position: absolute;
  top: 0px;
  left: ${props=> (props.pos-1) * 138}px;
  margin-left: 45px;
  padding-bottom: 13px;
  width: 138px;
  float:left;

  font-family: Ubuntu;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: ${props => props.active ? "#000000" : "#888888"};
  ${props => props.active ? "border-bottom: 2px solid #33333;" : ""}

  :hover {
    border-bottom: 2px solid #007bff;
    cursor: pointer;
  }
`

const SMenuItemIcon = styled.span`
  width: 24px;
`

const SMenuItemTitle = styled.span`
`

const SButton = styled.div`
  position: flex;
  background: none;
  border-radius: 2px;
  border: none;
  color: #111111;
  font-family: Ubuntu;  
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  height: 48px;
  
  margin-left: auto;
  padding: 12px;
  text-align: center;
  width: 100px;
  max-width: 610px;
  
  :hover,:active,:focus {
    box-shadow: none !Important;
    outline: none !Important;
  }

  :hover {
    background-color: #555555 !Important;
    color: #FFFFFF !Important;
  }

  :active {
    background-color: #555555 !Important;
    color: #FFFFFF !Important;
  }
  :focus {
    background-color: #555555 !Important;
    color: #FFFFFF !Important;
  }

  @media (max-width: 769px) {
    margin-left: 0;
    width: 100%;
  }
`


const Header = ({...props}:IHeader) => {
  const _url = (props.url.substring(0,4) === "http" ? props.url : "https://" + props.url) + (props.url[props.url.length - 1] === "/" ?  "" : "/") + "favicon.ico"

  return(
    <SHeaderContainer>
      <SLogoContainer>
        {props.url?.length > 0 ? 
          <SLogo
            width="44px" 
            height="44px" 
            src={_url}
            onError={(e:any) => {e.target.onerror = null; e.target.src=window.location.origin + `/Assets/rstar_solid.svg`}}
          />
          : <></>}
      </SLogoContainer>
      <STitleContainer>
        <STitle>{props.title}</STitle>
        <SCompany>{props.company_name}</SCompany>
      </STitleContainer>
    </SHeaderContainer>
  )
} 

const Menu = ({...props}:IMenu) => {
  const t = useTranslation().t;
  const navigate = useNavigate();

  return(
    <SMenu>
      <SMenuItem
        pos={1}
        active={props.pos===1}
        onClick={() => props.onClickMenuItem(1)}
      >
        <SMenuItemIcon className="fas fa-info"/>
        <SMenuItemTitle>{t("Application.Menu.Info")}</SMenuItemTitle>
      </SMenuItem>
      <SMenuItem 
        pos={2}
        active={props.pos===2}
        onClick={() => props.onClickMenuItem(2)}
      >
        <SMenuItemIcon className="fas fa-calendar-check"/>
        <SMenuItemTitle>{t("Application.Menu.Activities")}</SMenuItemTitle>
      </SMenuItem>
      <SMenuItem 
        pos={3}
        active={props.pos===3}
        onClick={() => props.onClickMenuItem(3)}
      >
        <SMenuItemIcon className="fas fa-file-alt"/>
        <SMenuItemTitle>{t("Application.Menu.Notes")}</SMenuItemTitle>
      </SMenuItem>
      {
        props.type === "Opportunity"? 
          <SMenuItem
            pos={4}
            active={props.pos===4}
            onClick={() => props.onClickMenuItem(4)}
          >
            <SMenuItemIcon className="fas fa-building"/>
            <SMenuItemTitle>{t("Application.Menu.Company")}</SMenuItemTitle>
          </SMenuItem> 
          : <></>
      }
    </SMenu>
  )
}

export default function ApplicationContainer({...props}:any){
  const t = useTranslation().t;

  return(
    <Background>
      <Overlay>
        <SButton 
          onClick={() => props.onClickMenuItem(0)}
        >{t("Base.close")}</SButton>
        <Header 
          title={props.item.title}
          company_name={props.item.company_name}
          url={props.item.url}
          background={props.item.background}/>
        <Menu 
          id={props.item.id} 
          pos={props.pos}
          type={props.item.type}
          onClickMenuItem={props.onClickMenuItem}/>
        {props.children}
      </Overlay>
    </Background>
  )
}