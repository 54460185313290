import React from 'react';
import { useNavigate } from 'react-router-dom';
import UserFormPortfolios from '../_UserForm/Portfolio'
import SignUpContainer from './SignUpContainer';

export default function VSignUpPortfolio({...props}:any) {
  const navigate = useNavigate();

  return(
    <>
      <SignUpContainer pos={4}>
        <UserFormPortfolios 
          submitCallback={() => navigate(`/signup/personal`)}
          returnCallback={() => navigate(`/signup/skills`)}/>
      </SignUpContainer>
    </>
  )
}