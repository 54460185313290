import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components'
import SignUpBanner from '../_Menu/SignUpBanner'
import UserFormSignup from '../_UserForm/Signup'
import {
  StarsWelcomePageWeb,
} from '../svgs'

interface IReferral {
  account: string
  referrer: string
  job: string
}

const SignUpPage = styled.div`
  background: #111111;
  display: flex;
  flex-direction: row;
  height: 100vh;
  overflow-y: scroll;
  width: 100%;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`
const SignUpPageLeft = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 50%;
  @media (max-width: 900px) {
    display: none;
  }
`
const SignUpPageRight = styled.div`
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: scroll;
  width: 50%;
  @media (max-width: 900px) {
    width: 100%;
  }
  padding-left: 20px;
  padding-right: 20px;
`
const SignUpPageLeftRocket = styled.img`
  margin-top: 142px;
  margin-bottom: 76px;
  width: 422px;
  @media (max-height: 830px) {
    margin-top: 20px;
    margin-bottom: 20px;
    // TODO macbook
    // margin-top: 50px;
    // margin-bottom: 40px;
  }
`

const SignUpPageLeftDescription = styled.div`
  color: #FFFFFF;
  font-family: Ubuntu;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  text-align: center;
  width: 561px;
  padding-left: 20px;
  padding-right: 20px;
`
const SignUpPageCloudsWrapper = styled.div`
  bottom: 0;
  position: fixed;
  width: 50%;
`

const SignUpPageRightCloudOne = styled.img`
  bottom: 0;
  position: absolute;
  right: 0;
  width: 42%;
`
const SignUpPageRightCloudTwo = styled.img`
  bottom: 0;
  position: absolute;
  right: 0;
  width: 27%;
`
const SignUpPageRightCloudThree = styled.img`
  bottom: 0;
  position: absolute;
  right: 0;
  width: 28%;
`
const SignUpPageRightCloudFour = styled.img`
  bottom: 0;
  position: absolute;
  right: 0;
  width: 34%;
`
const SignUpPageLeftCloudOne = styled.img`
  bottom: 0;
  left: 0;
  position: absolute;
  width: 44%;
`
const SignUpPageLeftCloudTwo = styled.img`
  bottom: 0;
  left: 0;
  position: absolute;
  width: 26%;
`
const SignUpPageLeftCloudThree = styled.img`
  bottom: 0;
  left: 0;
  position: absolute;
  width: 28%;
`
const SignUpPageRightLink = styled.div`
  display: flex;
  font-family: Ubuntu;
  font-size: 14px;
  justify-content: end;

  // TODO macbook
  // margin-bottom: 60px;
  // margin-right: 40px;
  // margin-top: 60px;

  margin-bottom: 32px;
  margin-right: 32px;
  margin-top: 32px;
  @media (max-width: 769px) {
    display: none;
  }
`
const SignUpPageRightLinkFirst = styled.div`
  font-weight: 400;
  margin-right: 5px;
`

const SignUpPageRightTitleWrap = styled.div`
  margin-bottom: 64px;
  margin-left: auto;
  margin-right: auto;
  width: 610px;

  @media (max-width: 769px) {
    margin-bottom: 40px;
    padding: 0 20px;
    width: 100%;
  }

`
const SignupPageTitle = styled.div`
  font-family: Ubuntu;
  font-size: 24px;
  font-weight: 700;
  
`
const SignupPageInvitedTitle = styled.div`
  display: inline-block;
  font-family: Ubuntu;
  font-size: 15px;
  font-weight: 400;
  margin-right: 5px;
`
const SignupPageInvitedBy = styled.div`
  display: inline-block;
  font-family: Ubuntu;
  font-size: 15px;
  font-weight: 700;
`
const SignUpPageMobiLink = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media (max-width: 769px) {
    margin-bottom: 70px;
  }
  @media (min-width: 769px) {
    display: none;
  }
`
const SignUpPageMobiLinkFirst = styled.div`
  font-weight: 400;
  margin-right: 5px;
`

const StarsWelcomePageWebWrapper = styled.div`
  position: absolute;
  
  @media (max-width: 769px) {
    display:  none;
  }
`
const SignUpBannerHolder = styled.div`
  @media (min-width: 769px) {
    display:  none;
  }
`
interface IVSignUp {
}

export default function VSignUp({...props}:IVSignUp) {
  const navigate = useNavigate();
  const [referrer, setReferrer] = useState("")
  const [job, setJob] = useState("")

  const params = new URLSearchParams(window.location.search)
  const referralKey = params.has('ref') ? params.get('ref') : undefined

  
  useEffect(() => {
    const effectAsync = async () => {
      fetch(
        window.__API_URL__ + `/referral/${referralKey}`, 
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        }
      )
      .then(res => {return res.json()})
      .then((data:IReferral) => {
        setReferrer(data.referrer);
        setJob(data.job);
      })
    }
    
    if (referralKey){
      effectAsync()
    }
  }, [])

  return(
    <SignUpPage>
      <SignUpPageLeft>
        <StarsWelcomePageWebWrapper>
          <StarsWelcomePageWeb color={'#FFFFFF'}/>
        </StarsWelcomePageWebWrapper>
        <SignUpBanner color='#FFFFFF'/>
          <SignUpPageLeftRocket src={window.location.origin + "/Assets/rocket.svg"} />
          <SignUpPageLeftDescription>Join our community today & launch your tech career!</SignUpPageLeftDescription>
          <SignUpPageCloudsWrapper>

            <SignUpPageLeftCloudOne src={window.location.origin + "/Assets/signupScreenClouds/leftOne.svg"}/>
            <SignUpPageLeftCloudTwo src={window.location.origin + "/Assets/signupScreenClouds/leftTwo.svg"}/>
            <SignUpPageLeftCloudThree src={window.location.origin + "/Assets/signupScreenClouds/leftThree.svg"}/>
            
            <SignUpPageRightCloudOne src={window.location.origin + "/Assets/signupScreenClouds/rightOne.svg"}/>
            <SignUpPageRightCloudTwo src={window.location.origin + "/Assets/signupScreenClouds/rightTwo.svg"}/>
            <SignUpPageRightCloudThree src={window.location.origin + "/Assets/signupScreenClouds/rightThree.svg"}/>
            <SignUpPageRightCloudFour src={window.location.origin + "/Assets/signupScreenClouds/rightFour.svg"}/>

          </SignUpPageCloudsWrapper>
      </SignUpPageLeft>

      <SignUpPageRight>
        {/* TODO transform logo img to svg with color changes */}
        <SignUpBannerHolder>
          <SignUpBanner color='#111111'/>
        </SignUpBannerHolder>
          <SignUpPageRightLink>
            <SignUpPageRightLinkFirst>Already a member?</SignUpPageRightLinkFirst>
            {/* <SignUpPageRightLinkSecond>Sign In</SignUpPageRightLinkSecond> */}
            <a style={{ color: "#111111", fontWeight: "700", textDecoration: "underline", textDecorationThickness: "2px", }} href='/login'>Sign In</a>
          </SignUpPageRightLink>
          <SignUpPageRightTitleWrap>
            <SignupPageTitle>Become a Rockstar</SignupPageTitle>
            {referralKey ? <>
              <SignupPageInvitedTitle>You were invited by</SignupPageInvitedTitle>
              <SignupPageInvitedBy>{referrer}</SignupPageInvitedBy>
              {job ?
                <>
                  <br/>
                  <SignupPageInvitedTitle>for</SignupPageInvitedTitle>
                  <SignupPageInvitedBy>{job}</SignupPageInvitedBy>
                </> : <></>
              }
            </> : <></>}
            {/* TODO move strings to en.json file */}
          </SignUpPageRightTitleWrap>
          <UserFormSignup submitCallback={() => {navigate(`/signup/continue`)}}/>
          <SignUpPageMobiLink>
            <SignUpPageMobiLinkFirst>Already a member?</SignUpPageMobiLinkFirst>
            <a style={{ color: "#111111", fontWeight: "700", textDecoration: "underline", textDecorationThickness: "2px", }} href='/login'>Sign In</a>
          </SignUpPageMobiLink>
      </SignUpPageRight>
    </SignUpPage>
  )
}