import React, { useState, useEffect } from 'react'
import { Formik } from 'formik';
import { Col, Form, Row } from 'react-bootstrap';
import { SForm, SCol, SGroup, STitle, SSubTitle, SEditButton, SLabel, SControl, SFeedback, SButton } from './FormStyle'
import { useTranslation } from 'react-i18next';
import * as yup from "yup";
import { SkillSelectField, ISkill } from './Fields/SkillSelectField'
import './FormStyle.css';
import { useFetchAuth } from '../_Core/AuthHandling';
import { LanguageSelectField, ILanguage } from './Fields/LanguageSelectField'

interface IUserSkills {
  skills: ISkill[]
  currentPosition: string
  techBackground: string
  languages: ILanguage[]
}

export default function UserFormSkills({...props}:any) {
  const t = useTranslation().t;
  const fetchAuth = useFetchAuth()
  const [readOnly, setReadOnly] = useState(props.readinit === true)
  const [skills, setSkills] = useState([] as ISkill[])
  const [currentPosition, setCurrentPosition] = useState('')
  const [techBackground, setTechBackground] = useState('')
  const [languages, setLanguages] = useState(new Array<ILanguage>())

  useEffect(() => {
    const effectAsync = async () => {
      fetchAuth(
        window.__API_URL__ + `/user/0/skills`, 
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        }
      )
      .then((data: IUserSkills) => {
          setSkills(data.skills)
          setCurrentPosition(data.currentPosition)
          setTechBackground(data.techBackground)
          setLanguages(data.languages === undefined ? [] : data.languages)
        }
      )
      .catch(error => {setReadOnly(true)})
    }
    effectAsync()
  }, [])

  return(
    <div>
    <Formik
      initialValues={{
        skills: skills,
        techBackground: techBackground,
        currentPosition: currentPosition,
        languages: languages
      }}
      enableReinitialize={true}
      onSubmit={async (values) => {
        const body = {
          techBackground: values.techBackground,
          currentPosition: values.currentPosition,
          skills: skills,
          languages: languages
        }
        await fetchAuth(
          window.__API_URL__ + `/user/0/skills
          `, 
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },  
            body: JSON.stringify(body),
          }
        )
        .then(data => {setReadOnly(true); props.submitCallback(); return data})
      }}
      validationSchema={yup.object().shape({ 
        //workExperience: yup.string()
        //  .required(t('ProfileSkills.workExperienceValidationErrorRequired')),
      })}
    >
      {({
      handleSubmit,
      handleChange,
      handleBlur,
      values,
      touched,
      isValid,
      errors,
      setFieldValue
      }) => (  
        <SForm noValidate onSubmit={handleSubmit}>
          <SCol md="5">
            <STitle>{t('ProfileSkills.title')}</STitle>
            <SSubTitle>{t('ProfileSkills.subTitle')}</SSubTitle>
            {props.readinit === true ?
              <Row>
                  <SEditButton 
                    className="fas fa-pen"
                    onClick={() => setReadOnly(false)}/> 
              </Row> : 
              <></>
            }
            <SGroup as={Col} md="12" controlId="currentPosition">
              <SLabel>{t('ProfileSkills.currentPosition')}</SLabel>
              <SControl
                type="text"
                name="currentPosition"
                placeholder={readOnly ? t('Base.empty') : t('ProfileSkills.currentPositionPlaceHolder')}
                value={values.currentPosition}
                onChange={(e:React.ChangeEvent<any>) => {setCurrentPosition(e.target.value)}}
                isInvalid={touched.currentPosition && !!errors.currentPosition}
                readOnly={readOnly}
                plaintext={readOnly}
              />
              <SFeedback type="invalid" tooltip>{errors.currentPosition}</SFeedback>
            </SGroup>
            <SGroup as={Col} md="12" controlId="techBackground">
              <SLabel>{t('ProfileSkills.techBackground')}</SLabel>
              <Form.Control 
                as="textarea"
                style={{
                  height:"120px",
                  border:"1px solid #474747",
                  borderRadius:"4px",
                  margin:"0px",
                  fontFamily:"Ubuntu",
                  fontStyle:"normal",
                  fontWeight:"normal",
                  fontSize:"16px",
                  color:"#495057"
                }}
                rows={3}
                name="techBackground" 
                placeholder={readOnly ? t('Base.empty') : t('ProfileSkills.techBackgroundPlaceholder')}
                value={values.techBackground}
                onChange={(e:React.ChangeEvent<any>) => {setTechBackground(e.target.value)}}
                isInvalid={touched.techBackground && !!errors.techBackground}
                readOnly={readOnly}
                plaintext={readOnly}
              />
            </SGroup>
            <SGroup as={Col} md="12" controlId="skills">
              <SLabel>{t('ProfileSkills.skills')}</SLabel>
              <SkillSelectField 
                readOnly={readOnly} 
                skills={skills} 
                setSkills={setSkills}
              />
            </SGroup>
            <SGroup as={Col} md="12" controlId="languages">
              <SLabel>{t('ProfileSkills.languages')}</SLabel>
              <LanguageSelectField 
                readOnly={readOnly}
                languages={languages}
                setLanguages={setLanguages}
              />
              <SFeedback type="invalid" tooltip>{errors.languages}</SFeedback>
            </SGroup>
            {!readOnly ?
              <SGroup as={Col} md="12" xs controlid="button" style={{ justifyContent: "flex-end" }}>
                <SCol md="9" style={{ alignItems: "baseline", display: "flex", margin: "0px", padding: "0px"}}>
                  <SButton primary={true} type="submit">{t('Base.submit')}</SButton>
                </SCol> 
              </SGroup>
              :<></>
            }
          </SCol>
        </SForm>
      )}  
    </Formik>
    </div>
  ) 
}