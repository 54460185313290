import React, { useState } from 'react';

import { SInputGroup, SGroupText, SGroupIcon, SSelectResult, SRow } from '../FormStyle';
import styled from 'styled-components';
import { OverlayTrigger, Tooltip, InputGroup } from 'react-bootstrap';

interface IStarSlider {
  value: number
  name: string
  readonly: boolean
  onClick: Function
  onDelete: Function
}

const SStarContainer = styled("div")`
  display: flex;

  @media (max-width: 769px) {
    margin-left: 12px;
  }
`

const SStar = styled("img")`
  width: 20px;
  height: 19px;

  @media (max-width: 769px) {
    width: 36px;
    height: 32px;
  }
`

const SStarIcon = styled(SGroupIcon)`
  @media (max-width: 769px) {
    width: 50px;
    height: 50px;
  }
`

const SStarLabel = styled(SGroupText)`
  width: calc(100% - 6 * 32px - 36px);
  @media (max-width: 769px) {
    width: 100%;
  }
`


export const StarSlider = ({ ...props }: IStarSlider) => {
  const [hoverValue, setHoverValue] = useState(props.value);

  const StarTooltip = ({ ...props }: any) => {
  
    const terms = new Map<number, any>();
    terms.set(1, 'Novice');
    terms.set(2, 'Beginner');
    terms.set(3, 'Intermediate');
    terms.set(4, 'Advanced');
    terms.set(5, 'Professional');
    terms.set(6, 'Rockstar');
  
    return (
      <Tooltip id={`${props.name}_star_${props.index}_tooltip`} {...props}>
        {terms.get(hoverValue)}
      </Tooltip>
    );
  };

  const stars = [1, 2, 3, 4, 5, 6].map((index) => {
    return (
      <SStarIcon
        key={`${props.name}_star_${index}`}
        style={{ color: "#000000" , marginRight:"0px"}}
        onMouseOver={props.readonly ? () => { } : () => setHoverValue(index)}
        onMouseLeave={props.readonly ? () => { } : () => setHoverValue(props.value)}
        onClick={props.readonly ? () => { } : () => props.onClick(index)}
      >
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 200, hide: 400 }}
          overlay={StarTooltip}
        >
          <SStar src={window.location.origin + `/Assets/rstar_${index <= hoverValue ? "solid" : "raw"}.svg`} alt=""/>
        </OverlayTrigger>
      </SStarIcon>
    );
  });
  return (
    <SSelectResult>
      <SInputGroup className="mb-2">
        <SStarLabel>{props.name}</SStarLabel>
        <SStarContainer>
          {stars}
        </SStarContainer>
        {!props.readonly ?
          <SGroupIcon onClick={() => props.onDelete()}><span className="fas fa-times" /></SGroupIcon>
          : <></>}
      </SInputGroup>
    </SSelectResult>
  );
};
