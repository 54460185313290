import React, { useContext } from 'react'
import { Col, Row } from 'react-bootstrap';
import { SForm, SCol, SGroup, STitle, SLabel, SControl, SButton } from './FormStyle'
import styled from 'styled-components'
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './FormStyle.css';
import { UserContext } from '../_Core/AuthHandling'
import { tryFetch } from '../_Core/ErrorHandling'
import { ToastContext } from '../_Core/Toast'

const SLinkRight = styled.div`
  cursor: pointer;
  float: right;

  margin: 0 30px 0 0;

  font-family: Ubuntu;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 143.2%;
  color: #333333;
`

const SLinkLeft = styled.div`
  cursor: pointer;
  float: left;

  font-family: Ubuntu;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 143.2%;
  color: #b5b5b5;
`

const SSubTitle = styled.div`
  margin-bottom: 32px;
  
  display: inline-block;
  font-family: Ubuntu;
  font-size: 15px;
  font-weight: 400;
  margin-right: 5px;
`

export default function UserFormLogin({...props}:any) {
  const t = useTranslation().t;
  const navigate = useNavigate();
  const user = useContext(UserContext);
  const toastHandler = useContext(ToastContext);

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
      }}
      onSubmit={
        async (values) => {
          const {data, errors} = await tryFetch(window.__API_URL__ + '/login', {
            method: 'post',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(values),
          })
          if (errors === null){
            user.setUser(data)
            props.callback()
          } else if (errors === 401){
            toastHandler.showMsg(data.errors, true)
          } else {
            toastHandler.showMsg(errors as string[], true)
          }
        }
      }
    >
      {({
      handleSubmit,
      handleChange,
      handleBlur,
      values,
      touched,
      isValid,
      errors,
      setFieldValue
      }) => (  
        <SForm noValidate onSubmit={handleSubmit}>
          <SCol md="5">
            <Row style={{display:"flex", justifyContent:"space-between"}}>
              <STitle>{t('Login.title')}</STitle>
              <SLinkRight onClick={() => navigate(`/signup`)}>{t('Login.signuplink')}</SLinkRight>
            </Row>
            <Row>
              <SSubTitle>{t('Login.subtitle')}</SSubTitle>
            </Row>
            <Row>       
              <SGroup as={Col} md="12" controlid="email">
                <SLabel>{t('ProfileContact.email')}</SLabel>
                <SControl
                  type="text"
                  name="email"
                  placeholder={t('ProfileContact.emailPlaceHolder')}
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </SGroup>        
              <SGroup as={Col} md="12" controlid="password">
                <SLabel>{t('ProfileContact.password')}</SLabel>
                <SControl 
                  type='password' 
                  name="password" 
                  placeholder="******"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                /> 
              </SGroup>
              <SGroup as={Col} md="12" controlid="button" style={{ justifyContent: "flex-start" }}>
                  <SButton primary={true} type="submit">{t('Base.submit')}</SButton>
              </SGroup>
              <SGroup as={Col}  md="12">
                <SLinkLeft onClick={() => navigate(`/resetpasswordrequest`)}>{t('Login.resetpasswordlink')}</SLinkLeft>
              </SGroup>
            </Row>
          </SCol>
        </SForm>
      )}  
    </Formik>
  )
}