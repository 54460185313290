import React from 'react';
import MainMenu from '../_Menu/MainMenu';
import UserFormSkills from '../_UserForm/Skills'
import ProfileContainer from './ProfileContainer';


export default function VProfileContact({...props}:any) {
  return(
    <>
      <MainMenu pos={3}/>
      <ProfileContainer pos={3}>
        <UserFormSkills />
      </ProfileContainer>
    </>
  )
}