import React from 'react';
import { useNavigate } from 'react-router-dom';
import SignUpBanner from '../_Menu/SignUpBanner';
import UserFormResetPassword from '../_UserForm/ResetPassword';
import { SignUpContainer } from './SignUpStyles';

export default function VResetPassword({...props}:any) {
  const navigate = useNavigate();

  return(
    <>
      <SignUpBanner/>
      <SignUpContainer>
        <UserFormResetPassword callback={() => {navigate(`/login`)}}/>
      </SignUpContainer>
    </>
  )
}