import React, { useState, useCallback, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Form, Col, Button } from 'react-bootstrap';
import styled from 'styled-components'
import MainMenu from '../_Menu/MainMenu';
import { useFetchAuth } from '../_Core/AuthHandling';
import { ToastContext } from '../_Core/Toast'

interface IActivityData {
  id: string
  applicationId: string
  title: string
  description: string
  date: string
  done: boolean

}

interface IActivity {
  id: string
  applicationId: string
  title: string
  description: string
  date: string
  done: boolean
  onDelete: Function
  onDone: Function
}

const ScrollContainer = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  @media (max-width: 900px) {
    height: calc(100vh - 88px);
  }

  @media (min-width: 901px) {
    height: calc(100vh - 60px);
  }
`

export const SCol = styled(Col)`
  margin-left:auto;
  margin-right:auto;
`

const SActivityContainer = styled.div`
  margin-top: 40px; 

  display: inline-grid;
  grid-template-columns:  28px 90px auto 160px 160px;
  grid-template-rows: 24px auto;
  grid-template-areas: 
    "check date title goto delete" 
    "desc desc desc desc desc";
`

const SActivityCheck = styled(Form.Check)`
  grid-area: check;
  height: 30px;
`

const SActivityTitle = styled("div")<{done:boolean}>`
  grid-area: title;
  margin-left: 20px;

  font-family: Ubuntu;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: ${props => props.done ? "#798CA3" : "#333333"};
  ${props => props.done ? "text-decoration: line-through;" : ""}
`

const SActivityDate = styled("div")<{late:boolean,done:boolean}>`
  grid-area: date;

  background: ${props => props.done ? "#798CA3" : props.late ? "#eb4034" : "#00CD69"};
  border-radius: 4px;

  font-family: Ubuntu;
  font-style: normal;
  font-weight: lighter;
  font-size: 16px;
  color: #FFFFFF;
  text-align: center;
`

const SApplicationButton = styled(Button)`
  grid-area: goto; 

  background: #333333;
  border-color: #333333;
  border-radius: 4px;
  width: 150px;
  margin-left:10px;
  padding: 0px;

  font-family: Ubuntu;
  font-style: normal;
  font-weight: lighter;
  font-size: 16px;
  color: #FFFFFF;
  text-align: center;
`

const SActivityDelete = styled(Button)`
  grid-area: delete; 

  background: #333333;
  border-color: #333333;
  border-radius: 4px;
  width: 150px;
  margin-left:10px;
  padding: 0px;

  font-family: Ubuntu;
  font-style: normal;
  font-weight: lighter;
  font-size: 16px;
  color: #FFFFFF;
  text-align: center;

  :hover{
    cursor: pointer;
  }
`

const SActivityDescription = styled("div")<{done:boolean}>`
  grid-area: desc;
  margin-top: 5px;

  font-family: Ubuntu;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: ${props => props.done ? "#798CA3" : "#333333"};
`

const Activity = ({...props}:IActivity) => {
  const navigate = useNavigate();
  const t = useTranslation().t;

  return(
    <SActivityContainer>
      <SActivityCheck 
        value={props.done}
        onChange={() => props.onDone()}
        />
      <SActivityDate 
        done={props.done}
        late={false}
      >
        {props.date}
      </SActivityDate>
      <SActivityTitle 
        done={props.done}
      >
        {props.title}
      </SActivityTitle>
      <SApplicationButton
        onClick={() => navigate(`/application?applicationId=${props.applicationId}`)}
      >
        {t("Base.GoToApplication")}
      </SApplicationButton>
      <SActivityDelete onClick={() => props.onDelete()}>
        {t("Base.delete")}
      </SActivityDelete>
      <SActivityDescription 
        done={props.done}
      >
        {props.description}
      </SActivityDescription>
    </SActivityContainer>
  )
}

export default function VActivities({...props}:any){
  const toastHandler = useContext(ToastContext);
  const fetchAuth = useFetchAuth()
  const [activities, setActivities] = useState([] as IActivityData[])

  useEffect(() => {
    const effectAsync = async () => {
      fetchAuth(
        window.__API_URL__ + `/activities`, 
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        }
      )
      .then((data: IActivityData[]) => {
        setActivities(data)
      })
    }
    effectAsync()
  }, [])

  const deleteAsync = async (id:string, list:IActivityData[]) => {
    const listClone = Array.from(list) as IActivityData[];
    const idx = listClone.findIndex((elem) => elem.id === id);

    return await fetchAuth(
      window.__API_URL__ + `/activity/${id}`, 
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        }
      }
    )
    .then((data:any) => {
      return({code:true, error:"", result:listClone})
    })
    .catch(error => {
      return({code:false, error:"Deleting didn't work please try again ...", result:[]})
    })
  }

  const onDelete = useCallback(async (elem:IActivityData) => {
    const result = await deleteAsync(elem.id, activities)

    if (!result.code){
      toastHandler.showMsg(result.error, true)
    }
    else{
      setActivities(result.result);
    }

  }, [activities])

  const updateAsync = async (activity:IActivityData) => {
    return await fetchAuth(
      window.__API_URL__ + `/activity/${activity.id}`, 
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(activity),
      }
    )
    .then((data:any) => {
      return({code:true, error:"", result:activity})
    })
    .catch(error => {
      return({code:false, error:"Updating didn't work please try again ...", result:null})
    })
  }

  const onDone = useCallback(async (activity:IActivityData) => {
    activity.done = !activity.done
    
    const result = await updateAsync(activity)

    if (!result.code){
      toastHandler.showMsg(result.error, true)
    }
    else{
      setActivities(activities)
    }
  }, [])

  const dateSort = (a: any,b: any) => {
    if (a.date > b.date){
      return 1
    }
    else if (a.date < b.date){
      return -1
    }
    else {
      return 0
    }
  } 

  const openActivitiesDisplay = activities.filter(elem => !elem.done).sort(dateSort).map(elem => {
    return(
      <Activity 
        id={elem.id}
        done={elem.done}
        date={elem.date}
        title={elem.title}
        description={elem.description}
        applicationId={elem.applicationId}
        onDelete = {() => onDelete(elem)}
        onDone = {() => onDone(elem)}/>)
  })

  return(
    <>
      <MainMenu pos={2}/>
      <ScrollContainer>
        <SCol md="7">
          {openActivitiesDisplay}
        </SCol>
      </ScrollContainer>
    </>
  )
}