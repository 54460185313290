import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import SignUpBanner from '../_Menu/SignUpBanner';
import UserFormResetPasswordRequest from '../_UserForm/ResetPasswordRequest';
import { SignUpContainer } from './SignUpStyles';

export interface IResetPasswordRequest {

}

export default function VResetPasswordRequest({...props}:IResetPasswordRequest) {
  const navigate = useNavigate();

  return(
    <>
      <SignUpBanner/>
      <SignUpContainer>
        <UserFormResetPasswordRequest callback={() => {navigate(`/login`)}}/>
      </SignUpContainer>
    </>
  )
}