import React from 'react';
import MainMenu from '../_Menu/MainMenu';
import UserFormDreamJob from '../_UserForm/DreamJob'
import ProfileContainer from './ProfileContainer';


export default function VProfileContact({...props}:any) {
  return(
    <>
      <MainMenu pos={3}/>
      <ProfileContainer pos={2}>
        <UserFormDreamJob />
      </ProfileContainer>
    </>
  )
}