import React, { useContext } from 'react'
import Select from 'react-select';
import ValueType, { ActionMeta} from 'react-select';
import { selectStyles, SSelectContainer } from '../FormStyle';
import { useTranslation } from 'react-i18next';
import { StarSlider } from './StarSlider';

type MyOptionType = { label: string, value: string }

export interface ILanguage {
  code: string
  level: number
}

interface ILanguageSelectField {
  languages: ILanguage[]
  setLanguages: Function
  readOnly: boolean
}

export const LanguageSelectField = ({...props}:ILanguageSelectField) => {
  const t = useTranslation().t;

  const options = [
    {value: 'de-ch', label: t('Language.de-ch')},
    {value: 'de-de', label: t('Language.de-de')},
    {value: 'en-us', label: t('Language.en-us')},
    {value: 'fr-fr', label: t('Language.fr-fr')},
    {value: 'it-it', label: t('Language.it-it')},
    {value: 'ar-ae', label: t('Language.ar-ae')}, 
    {value: 'bn', label: t('Language.bn')},
    {value: 'bg', label: t('Language.bg')},
    {value: 'hr', label: t('Language.hr')},
    {value: 'da', label: t('Language.da')},
    {value: 'nl-nl', label: t('Language.nl-nl')},
    {value: 'cs', label: t('Language.cs')},
    {value: 'et', label: t('Language.et')},
    {value: 'fi', label: t('Language.fi')},
    {value: 'el', label: t('Language.el')},
    {value: 'he', label: t('Language.he')},
    {value: 'hi', label: t('Language.hi')},
    {value: 'hu', label: t('Language.hu')},
    {value: 'ja', label: t('Language.ja')},
    {value: 'ms-my', label: t('Language.ms-my')},
    {value: 'zh-cn', label: t('Language.zh-cn')},
    {value: 'fa', label: t('Language.fa')},
    {value: 'pt-pt', label: t('Language.pt-pt')},
    {value: 'pa', label: t('Language.pa')},
    {value: 'ro', label: t('Language.ro')},
    {value: 'ru', label: t('Language.ru')},
    {value: 'sk', label: t('Language.sk')},
    {value: 'sl', label: t('Language.sl')},
    {value: 'es-es', label: t('Language.es-es')},
    {value: 'sw', label: t('Language.sw')},
    {value: 'sv-se', label: t('Language.sv-se')},
    {value: 'ko', label: t('Language.ko')},
    {value: 'ta', label: t('Language.ta')},
    {value: 'th', label: t('Language.th')},
    {value: 'tr', label: t('Language.tr')},
    {value: 'vi', label: t('Language.vi')},
    {value: 'sq', label: t('Language.sq')},
    {value: 'bs', label: t('Language.bs')},
    {value: 'pt-br', label: t('Language.pt-br')},
    {value: 'zh-hk', label: t('Language.zh-hk')},
    {value: 'zh', label: t('Language.zh')},
    {value: 'is', label: t('Language.is')},
    {value: 'la', label: t('Language.la')},
    {value: 'de-lu', label: t('Language.de-lu')},
    {value: 'sr-sp', label: t('Language.sr-sp')},
    {value: 'lt', label: t('Language.lt')},
    {value: 'lv', label: t('Language.lv')},
    {value: 'no-no', label: t('Language.no-no')},
    {value: 'pl', label: t('Language.pl')},
    {value: 'uk', label: t('Language.uk')},
  ]
  
  const languagessDisplay = (props.languages.length === 0 && props.readOnly) ? [<div>{t("Base.empty")}</div>] 
    : props.languages.map((language:ILanguage, index:number) => {
      return(
        <StarSlider 
            key={language.code}
            name={t(`Language.${language.code}`)}
            readonly = {props.readOnly}
            value={language.level}
            onClick={(value:number) => { 
                let languages_tmp = [...props.languages]
                languages_tmp[index] = {code: language.code, level:value}
                props.setLanguages(languages_tmp);
              }
            }
            onDelete={() => {
                let languages_tmp = [...props.languages]
                languages_tmp.splice(index, 1)
                props.setLanguages(languages_tmp)
              }
            }
          />
      )
    }
  )

  const distabledOptions = new Set()
  props.languages.map(language => {
    distabledOptions.add(language.code)
  })

  return(
    <SSelectContainer>
      {!props.readOnly ?
        <Select 
          styles={selectStyles}
          options={options}
          value={{value:"", label:t('ProfileSkills.languageSelectPlaceholder')}}
          onChange={
            async (s: any, action: any) => {
              if(action.action==="select-option"){
                let languages_tmp = [...props.languages]
                languages_tmp.push({
                  code: (s as MyOptionType).value,
                  level: 0
                });
                props.setLanguages(languages_tmp);
              }
            }
          } 
          isOptionDisabled={(option) => distabledOptions.has(option.value)}

        />
        : <></>
      } 
      {languagessDisplay}
    </SSelectContainer>
  )
}
