import styled from 'styled-components'
import { Form, Button, Col, Row, InputGroup } from 'react-bootstrap';


export const SForm = styled(Form)`
  margin-bottom: 32px;
  text-align: left;

  @media (max-width: 769px) {
    margin-bottom: 24px;
  }
`

export const SCol = styled(Col)`
  margin-left:auto;
  margin-right:auto;
  max-width: 610px;
  z-index: 2;
  padding: 0;

  @media (max-width: 769px) {
    padding: 0 20px;
  }
  `
  
export const SRow = styled(Row)`
  margin: 0px;
`

export const SColCheck = styled(Col)`
  display: flex;
  height: 32px;
  margin-top: 10px;
  min-width: 16px;
  padding-left: 0px;
  padding-right: 0px;
  flex-grow: 0; 
`

export const SCheckLabel = styled(Form.Label)<{active:boolean}>`
  color: ${props => props.active ? "#111111" : "#b5b5b5"};
  font-family: Ubuntu;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  height: 32px;
  margin: 0px 0px 0px 0px;
  padding-left: 0px;
  width: 160px;

  @media (max-width: 769px) {
    width: calc(100% - 16px);
  }
`

export const STitle = styled.h1`
  font-family: Ubuntu;
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 4px;
`
export const SSubTitle = styled.h3`
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 64px;

  @media (max-width: 769px) {
    margin-bottom: 44px;
  }
`

export const SEditButton = styled.div`
  height: 52px;
  margin-bottom: 40px;  
  padding-top: 8px;
  width: 10%;
  float: left;

  font-size: 24px;
  color: #798CA3;

  :hover{
    cursor: pointer;
  }
`

export const SGroup = styled(Form.Group)`
  align-items: baseline;
  display: flex;
  margin-bottom: 32px;
  padding: 0px;
  position: relative;

  @media (max-width: 769px) {
    flex-direction: column;
  }
`

export const SLabel = styled(Form.Label)`
  color: #111111;
  font-family: Ubuntu;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 143.2%;
  margin-right: 32px;
  width: 125px;
  margin-bottom: 0px;
  
  @media (max-width: 769px) {
    width: 100%;
  }
`

export const SSubLabel = styled(Form.Label)`
  height: 18px;
  margin-bottom: 2px;

  font-family: Ubuntu;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 143.2%;
  color: #333333;
`

export const SControl = styled(Form.Control)<{readOnly:boolean}>`
  background: none; 
  border: none;
  border-bottom: 1px solid ${props => props.readOnly ? "#272751" : "#474747"};
  box-sizing: border-box;
  border-radius: 0px;
  height: 30px;

  padding: 0px 0px 0px 15px; 
  font-family: Ubuntu;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: ${props => props.readOnly ? "#272751" : "#474747"};
  
  margin-bottom: 24px;
  
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  :hover{
    ${props => props.readOnly ? "" : "border-bottom: 1px solid #111111;"}
    ${props => props.readOnly ? "" : "cursor: pointer;"}
    ${props => props.readOnly ? "" : "color: #111111;"}
  };

  :active{
    ${props => props.readOnly ? "" : "border-bottom: 1px solid #111111;"}
    ${props => props.readOnly ? "" : "color: #111111;"}
  };

  :focus{
    ${props => props.readOnly ? "outline: none;" : ""}
  };

  width: 450px;
  @media (max-width: 769px) {
    width: 100%;
  };
  
`

export const SCheckControl = styled(SControl)`
  height: 16px; 
  width: 16px;  
  margin-top: 4px; 
  margin-right: 16px; 
  background-color: #111111;
`

export const SCheckContainer = styled("div")`
  display: flex;
`

export const SInputGroup = styled(InputGroup)`
  background: none;
  border: 0px;
  height: 32px;
  margin-top: 8px;
  width: 100%;

  @media (max-width: 769px) {
    flex-direction: row;
  }
`

export const SGroupControl = styled(SControl)`
  background: none;
  border: 0px;
  border-bottom: 1px solid #474747;
  height: 48px;
`

export const SGroupText = styled(InputGroup.Text)`
  background: none;
  border: 0px;
  // height: 48px;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 16px;

  font-family: Ubuntu;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #111111;
  
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  width: calc(100% - 32px);
`

export const SGroupIcon = styled(InputGroup.Text)`
  background: none;
  border: 0px;
  height: 32px;
  width: 32px;
  margin-right:4px;
  cursor: pointer;
  font-size: 16px;
  color: #666666;


`

export const SGroupButton = styled(Button)`
  background: none;
  border: 0px;
  height: 48px;
  width: 36px;

  font-size: 16px;
  color: #E0E0E0;
`

export const SGroupSeperator = styled.span`
  height: 34px;
  margin-bottom: 7px;
  margin-top: 7px;
  background-color: #E0E0E0;
  width: 1px;
  box-sizing: border-box;
`

export const SButton = styled(Button)<{primary:boolean, type:string}>`
  background: ${props => props.primary ? "#111111" : "#FFFFFF"};
  border-radius: 2px;
  border: none;
  color: ${props => props.primary ? "#FFFFFF" : "#111111"};
  font-family: Ubuntu;  
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  height: 50px;
  
  margin-top: 32px;
  padding: 12px 0px 12px 0px;
  width: 100%;
  max-width: 610px;
  
  :hover,:active,:focus {
    box-shadow: none !Important;
    outline: none !Important;
  }

  :hover {
    background-color: ${props => props.primary ? "#666666" : "#666666"} !Important;
    border-color: ${props => props.primary ? "#FFFFFF" : "#FFFFFF"} !Important;
    color: ${props => props.primary ? "#FFFFFF" : "#FFFFFF"} !Important;
  }

  :active {
    background-color: ${props => props.primary ? "#111111" : "#FFFFFF"} !Important;
    border-color: ${props => props.primary ? "#FFFFFF" : "#111111"} !Important;
    color: ${props => props.primary ? "#FFFFFF" : "#111111"} !Important;
  }
  :focus {
    background-color: ${props => props.primary ? "#111111" : "#FFFFFF"} !Important;
    border-color: ${props => props.primary ? "#FFFFFF" : "#111111"} !Important;
    color: ${props => props.primary ? "#FFFFFF" : "#111111"} !Important;
  }

  @media (max-width: 769px) {
    margin-left: 0;
    width: 100%;
  }
`

export const SButtonsGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`

export const SFormButtonsHolder = styled(SGroup)`
  justify-content: flex-end;
  // display: none;

  @media (max-width: 769px) {
   margin-top: 100px;
   margin-bottom: 0px;
  }
`
export const SFormButton = styled(SButton)`
  @media (max-width: 769px) {
    margin-top: 0px;

    &:nth-child(2) {
      margin-top: 10px;
    }
  }
`
export const SSelectFieldWrapper = styled.div`
  width: 450px;
  @media (max-width: 769px) {
    width: 100%;
  }
`;

export const SSelectResult = styled.div`
  background: none;
  // border-bottom: 2px solid #b5b5b5;
  height: 32px;
  width: 100%;
  @media (max-width: 769px) {
    margin-bottom: 16px;
  }
`;

export const SFileLabel = styled(Form.Label)`
  background: #111111;
  border-radius: 2px;
  height: 46px;
  display: inline-block;
  cursor: pointer;

  padding-top: 12px;
  text-align: center;

  font-family: Ubuntu;  
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #FFFFFF;

  width: 450px;
  @media (max-width: 769px) {
    width: 100%;
  }
`

export const SFile = styled.div`
  font-family: Ubuntu;  
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #b5b5b5;

  padding-right: calc(1.5em + .75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(.375em + .1875rem) center;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem);
`

export const SFeedback = styled(Form.Control.Feedback)`
  // background: rgba(220,53,69,.9);
  // color: #FFFFFF;
  background-color: transparent;
  color: #FC4236;
  // display: block;
  right: 0;
  top: 25px;
  width: 450px;

  @media (max-width: 769px) {
    top: 45px;
    left: 0px;
  }
`

export const SSelectContainer = styled("div")`
  width: 450px;
  @media (max-width: 769px) {
    width: 100%;
  }
`

export const selectStyles = {
  container: (provided:any) => ({
    ...provided,
    "@media only screen and (max-width: 797px)": {
      width: "100%",
    },
  }),
  control: (provided:any) => ({
    ...provided,
    background: "none",
    border: "none",
    cursor: "pointer",
    borderBottom: "1px solid #666666",
    borderRadius: "0px",
    height: "50px",
    boxShadow: "none",
    width: "450px",
    paddingLeft: "12px",
    color: "#666666",
    '&:hover': {
      color: "#111111",
      borderBottom: "1px solid #111111",
    },
    "@media only screen and (max-width: 797px)": {
      width: "100%",
    },
  }),
  input: (provided:any) => ({
    ...provided,
    alignSelf: "end",
    display: "flex",
    marginLeft: "0px",
  }),
  valueContainer: (provided:any) => ({
    ...provided,
    height: "50px",
    padding: "0px 6px 0px 2px",
  }),
  singleValue : (provided:any) => ({
    ...provided,
    fontFamily: "Ubuntu",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    color: "#111111",
    top: "70%",
  }),
  indicatorsContainer: (provided:any) => ({
    ...provided,
    height: "50px",
    alignItems: "end",
  }),
  menu: (provided:any) => ({
    ...provided,
    zIndex: "6",
  }),
  
  option: (provided:any, state:any) => ({
    ...provided,
    backgroundColor: "#FFFFFF",
    color: state.isSelected ? "#A0A0A0" : "#111111",
    '&:hover': {
      backgroundColor: "#D3D3D3",
      cursor: "pointer",
    }
  }),
  placeholder: (provided:any) => ({
    ...provided,
    margin: "0px 0px 0px 0px",
    fontFamily: "Ubuntu",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    color: "#666666",
    width: "100%",
    top: "70%",
  }),
  dropdownIndicator: (provided:any) => ({
    ...provided,
    color: "#b5b5b5",
  }),
  indicatorSeparator: (provided:any) => ({
    ...provided,
    backgroundColor: "none",
  }),
}

