import React, { useState, useEffect, useContext } from 'react';
import { DragDropContext, Droppable, Draggable, DropResult, DraggableLocation } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap'
import styled from 'styled-components'
import ApplicationContainer from '../_ApplicationOverlay/Container';
import ApplicationInfo from '../_ApplicationOverlay/Info';
import ApplicationActivities from '../_ApplicationOverlay/Activities';
import ApplicationNotes from '../_ApplicationOverlay/Notes';
import MainMenu from '../_Menu/MainMenu';
import { useFetchAuth } from '../_Core/AuthHandling';
import { ToastContext } from '../_Core/Toast'


interface IActivitiesSummary{
  next_due_date: string,
  count_total: number,
  count_done: number
}

export interface ICard {
  id: string,
  title: string,
  company_name: string,
  url: string,
  salary: string, 
  location: string,
  background: string,
  opportunity: boolean,
  description: string,
  rating: number,
  activities: IActivitiesSummary
  position: number,
  state: string,
  active: boolean,
  onDelete: Function,
  onClick: Function,
  type: string,
}

export interface ICardInfo {
  title: string,
  company_name: string,
  url: string,
  salary: string, 
  location: string,
  background: string,
  opportunity: boolean,
  description: string,
  rating: number,
  position: number
}

interface IMove {
  source: ICard[],
  destination: ICard[]
}

interface IList {
  pos: number,
  state: string,
  items: ICard[],
  onAddCard: Function,
  onDeleteCard: Function,
  onClickCard: Function
}

interface IDeleteResult {
  droppableId: string,
  position: number
}

const SBoard = styled("div")`
  position: relative;
  top: 0px;
  left: 0px;
  float: left;
  width: 100vw;
  overflow-x: scroll;
  overflow-y: scroll;
  white-space: nowrap;

  margin-left: auto;
  margin-right: auto;

  background-color: #F3F3F3;

  @media (max-width: 900px) {
    height: calc(100vh - 88px);
  }

  @media (min-width: 901px) {
    height: calc(100vh - 60px);
  }
`

const SList = styled("div")<{pos:number}>`
  position: absolute;
  left: ${props=> 305 * (props.pos-1)}px;
  top: 0px;

  width: 305px;
  height: 100%;

  border-left: 1px solid #D3D7DD;
  border-right: 1px solid #D3D7DD;
  float: left;
`

const SListTitle = styled.div`
  margin-top: 52px;

  text-align: center;
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #333333;
`

const SListItemCounter = styled.div`
  margin-top: 1px;

  text-align: center;
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #798CA3;
`

const SListAddButton = styled(Button)`
  width: 277px;
  height: 35px;
  margin-left: 13px;
  margin-top: 29px;

  background: #FFFFFF;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  border: none;

  font-family: Ubuntu;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #798CA3;
`

const SCard = styled("div")<{type:string}>`
  position: relative;
  width: 275px;
  height: ${props => props.type === "Opportunity" ? "138px" : "100px"};
  margin-left: auto;
  margin-right: auto;
  margin-top: 8px;
  margin-bottom: 8px;

  user-select: none;

  background: #FFFFFF;
  border-radius: 4px;
`

const SCardTitle = styled("div")`
  position: absolute;
  width: 180px;
  top: 10px;
  left: 36px;

  text-overflow: ellipsis;
  font-family: Ubuntu;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #333333;
`

const SCardCompany = styled("div")`
  position: absolute;
  width: 180px;
  top: 31px;
  left: 36px;

  text-overflow: ellipsis;
  font-family: Ubuntu;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;

  color:#798CA3;
  opacity: 0.6;
`

const SCardLogo = styled("div")<{background:string}>`
  position: absolute;
  top: 7px;
  left: 7px;
  width: 22px;
  height: 22px;

  border-radius: 50%;
  background-color: ${props => props.background};

  font-family: Ubuntu;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  text-align: center;

  color: #FFFFFF;
`

const SCardDeadline = styled("div")`
  position: absolute;
  top: 52px;
  left: 36px;
  width: 72px;
  height: 16px;

  border: 1px solid #333333;
  box-sizing: border-box;
  border-radius: 3.7px;

  line-height: 7px;
`

const SCardDeadlineIcon = styled("span")`
  margin-left: 4px;
  margin-right: 4px;

  font-size: 9px;
`

const SCardDeadlineTitle = styled("span")`
  margin-top: auto;
  margin-bottom: auto;

  font-family: Ubuntu;
  font-style: normal;
  font-weight: normal;
  font-size: 8px;
  line-height: 9px;
  color: #333333;
`

const SCardToDo = styled("div")`
  position: absolute;
  top: 52px;
  left: 115px;
  width: 72px;
  height: 16px;

  border: 1px solid transparent;
  box-sizing: border-box;
  border-radius: 3.7px;
  
  line-height: 7px;
`

const SCardToDoIcon = styled("span")`
  margin-left: 4px;
  margin-right: 4px;

  font-size: 9px;
`

const SCardToDoTitle = styled("span")`
  margin-top: auto;
  margin-bottom: auto;

  font-family: Ubuntu;
  font-style: normal;
  font-weight: normal;
  font-size: 8px;
  line-height: 9px;
  color: #333333;
`

const SCardMenuIcon = styled("div")<{show:boolean}>`
  position: absolute;
  top: 8px;
  right: 12px;

  background: #FFFFFF;

  font-size: 16px;
  color: #333333;
  opacity: 0.6;
  
  ${props => props.show ? "" : "display: none"};

  :hover{
    cursor: pointer;
  }
`

const SCardMenu = styled("div")<{show:boolean}>`
  position: absolute;
  top: 24px;
  right: 4px;
  margin: 8px;
  box-shadow: 0px 3.173184394836426px 19.039106369018555px 0px #00000026;
  ${props => props.show ? "" : "display: none"};
`

const SCardMenuItemContainer = styled("div")`
  position: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50px;
`

const SCardMenuItem = styled("div")`
  width: 100%;
  margin: 6px;

  font-family: Ubuntu;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #333333;
`

const SCardExtensionDivider = styled("div")`
  position: absolute;
  top: 101px;
  left: 7px;
  width: 258px;
  height: 1px;
  background-color: #E0E0E0;
`

const SCardExtension = styled("div")`
  position: absolute;
  top: 101px;
  height: 37px;

  border-radius: 4px;
`

const SCardExtensionLogo = styled("img")`
  position: absolute;
  top: 8px;
  left: 7px;
  width: 22px;
  height: 22px;

  border-radius: 50%;
`

const SCardExtensionNote = styled("div")`
  position: absolute;
  top: 10px;
  left: 38px;
  width: 72px;
  height: 18px;

  overflow-wrap: normal;
  font-family: Ubuntu;
  font-style: normal;
  font-weight: normal;
  font-size: 8px;
  line-height: 9px;
  color: #333333;
`

const SCardExtensionLabel = styled("div")`
  position: absolute;
  top: 11px;
  left: 172px;
  height: 16px;
  width: 93px;
  
  background: #333333;
  border-radius: 4px;

  padding-top: 4px;
  padding-left: 8px;

  font-family: Ubuntu;
  font-style: normal;
  font-weight: normal;
  font-size: 8px;
  line-height: 9px;
  color: #FFFFFF;
`

const Card = ({...props}:ICard) => {
  const t = useTranslation().t;
  const [showIcon, setShowIcon] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  return(
    <Draggable
      key={props.id}
      draggableId={props.id.toString()}
      index={props.position}>
      {(provided, snapshot) => (
        <SCard
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          type={props.type}
          style={{...provided.draggableProps.style}}
          onMouseEnter={() => setShowIcon(true)}
          onMouseLeave={() => setShowIcon(false)}
        >
          <div>
            <SCardLogo background={props.background}>{(props.company_name?.length > 0) ? props.company_name[0].toUpperCase() : ""}</SCardLogo>
            <SCardTitle>{props.title}</SCardTitle>
            <SCardMenuIcon 
              show={showIcon || showMenu}
              onClick={() => setShowMenu(true)}>
                <span className="fas fa-bars"></span>
            </SCardMenuIcon>
            <SCardMenu show={showMenu}>
              <SCardMenuItemContainer>
                <SCardMenuItem onClick={() => props.onClick()}>
                  {t("Applications.Card.Edit")}
                </SCardMenuItem>
                <SCardMenuItem onClick={() => props.onDelete()}>
                  {t("Applications.Card.Delete")}
                </SCardMenuItem>
              </SCardMenuItemContainer>
            </SCardMenu>
          </div>
          <SCardCompany>{props.company_name}</SCardCompany>
          {(props.activities && (props.activities?.next_due_date !== "" && props.activities?.next_due_date !== undefined && props.activities?.next_due_date !== null)) ?
            <SCardDeadline>
              <SCardDeadlineIcon className="far fa-clock"/>
              <SCardDeadlineTitle>
                {props.activities?.next_due_date}
              </SCardDeadlineTitle>
            </SCardDeadline>
            : <></>
          }
          {(props.activities && props.activities?.count_total !== 0) ?
            <SCardToDo>
              <SCardToDoIcon className="far fa-check-circle"/>
              <SCardToDoTitle>
                {props.activities?.count_done} / {props.activities?.count_total}
              </SCardToDoTitle>
            </SCardToDo>
            : <></>
          }
          {props.type === "Opportunity" ?
            <>
              <SCardExtensionDivider/>
              <SCardExtension>
                <SCardExtensionLogo 
                  width=""
                  height=""
                  src=""
                  alt="NOT FOUND"
                  onError={(e:any) => {e.target.onerror = null; e.target.src=window.location.origin + `/Assets/rstar_solid_white.svg`}}
                />
                <SCardExtensionNote>Rockstar manages this <br/>application for you</SCardExtensionNote>
                <SCardExtensionLabel>Powered by Rockstar</SCardExtensionLabel>
              </SCardExtension>
            </>
            : <></>
          }
        </SCard>
      )}
    </Draggable>
  )
}

const List = ({...props}:IList) => {
  const t = useTranslation().t;
  const items = props.items.map(item => (
    <Card 
      id={item.id} 
      title={item.title}
      company_name={item.company_name}
      url={item.url}
      location={item.location}
      salary={item.salary}
      background={item.background}
      description={item.description}
      rating={item.rating}
      activities={item.activities}
      opportunity={item.type === "Opportunity"}
      state={props.state}
      position={item.position}
      active={true}
      type={item.type}
      onDelete={() => {props.onDeleteCard({droppableId:props.state, position:item.position})}}
      onClick={() => {props.onClickCard(item)}}/>
  ))

  
  return(
    <SList
      pos={props.pos}
    >
      <SListTitle>{t(`Applications.${props.state}`)}</SListTitle>
      <SListItemCounter>{`${props.items.length} ${props.items.length===1 ? t("Applications.Job"):t("Applications.Jobs")}`}</SListItemCounter>
      <SListAddButton onClick={() => props.onAddCard()}><span style={{margin:"auto"}} className="fas fa-plus"></span></SListAddButton>
      <Droppable droppableId={props.state}>
        {(provided, snapshot) => (
          <div style={{height:"100%"}} ref={provided.innerRef}>
            {items}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </SList>
  )
}

export interface IApplications {
}

export default function VApplications({...props}:IApplications){  
  const toastHandler = useContext(ToastContext);
  const fetchAuth = useFetchAuth()
  const [wishlist, setWishlist] = useState([] as ICard[])
  const [applied, setApplied] = useState([] as ICard[])
  const [interview, setInterview] = useState([] as ICard[])
  const [offer, setOffer] = useState([] as ICard[])
  const [declined, setDeclined] = useState([] as ICard[])
  const [overlay, setOverlay] = useState(0)
  const [activeItem, setActiveItem] = useState({} as ICard)
  const [newItem, setNewItem] = useState(false)
  const [newItemState, setNewItemState] = useState("")

  const getList = (id:string) => {
    if (id === "wishlist") { return wishlist }
    else if (id === "applied") { return applied }
    else if (id === "interview") { return interview }
    else if (id === "offer") { return offer }
    else if (id === "declined") { return declined }
  }

  const getSetList = (id:string) => {
    if (id === "wishlist") { return setWishlist}
    else if (id === "applied") { return setApplied}
    else if (id === "interview") { return setInterview}
    else if (id === "offer") { return setOffer}
    else if (id === "declined") { return setDeclined}
  }

  const params = new URLSearchParams(window.location.search)
  const activeItemId = params.has('applicationId') ? params.get('applicationId') : null
  
  useEffect(() => {
    const effectAsync = async () => {
      fetchAuth(
        window.__API_URL__ + `/applications`, 
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        }
      )
      .then((data: ICard[]) => {
        data.forEach(elem => {
          if (elem.position === null){elem.position=0}
          if (elem.title === null){elem.title=elem.description}
          if (!["wishlist","applied","interview","offer","declined"].includes(elem.state)) {elem.state="wishlist"}
        })

        const lists = new Map<string,ICard[]>([
          ["wishlist", []],
          ["applied", []],
          ["interview", []],
          ["offer", []],
          ["declined", []]
        ]);
        data.map(card => {
          lists.get(card.state)?.push(card)
          if (activeItemId === `${card.id}`){
            setActiveItem(card)
            setOverlay(1)
          }
          return null;
        })
        setWishlist(lists.get("wishlist")?.sort((a,b) => a.position - b.position) as ICard[])
        setApplied(lists.get("applied")?.sort((a,b) => a.position - b.position) as ICard[])
        setInterview(lists.get("interview")?.sort((a,b) => a.position - b.position) as ICard[])
        setOffer(lists.get("offer")?.sort((a,b) => a.position - b.position) as ICard[])
        setDeclined(lists.get("declined")?.sort((a,b) => a.position - b.position) as ICard[])
      })
    }
    effectAsync()
  }, [])

  const emptyCard = () => {
    const item = {
      id: '0',
      title: '',
      company_name: '',
      location: '',
      salary: '',
      url: '',
      background: '',
      description: '',
      activities: {
        next_due_date: "",
        count_done: 0,
        count_total: 0
      },
      type: "Wish",
      state: '',
      position: 0,
    } as ICard

    return (item)
  }

  const createCardAsync = async (cardInfo: ICardInfo, state:string, list:ICard[]) => {
    const item = emptyCard()

    item.title = cardInfo.title
    item.company_name = cardInfo.company_name
    item.location = cardInfo.location
    item.salary = cardInfo.salary
    item.url = cardInfo.url
    item.background = cardInfo.background
    item.description = cardInfo.description
    item.state = state
    item.position = list.length
    
    return await fetchAuth(
      window.__API_URL__ + `/wish`, 
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(item),
      }
    )
    .then(
      (data:any) => {
        item.id = data.id
        list.push(item); 
        return({code: true, error: "", result: list, item: item})
      }
    )
    .catch(
      error => {
        return({code: false, error: "Creating didn't work please try again ...", result: list, item: item})
      }
    )
  }
  
  const getOrderFromList = (list:ICard[]) => {
    return new Map<string,number>(
      list.map(elem=>{
        return [elem.id, elem.position]
      })
    )
  }

  const setCardOrderAsync = async (order: Map<string,number>) => {
    return await fetchAuth(
      window.__API_URL__ + `/applications_order`, 
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(order),
      }
    )
    .then(
      (data:any) => {
        return({code:true, error:"", result:undefined as undefined|ICard[]|IMove})
      }
    )
    .catch(
      error => {
        return({code:false, error:"Reordering didn't work, please reload the page ...", result:[]})
      }
    )
  }
  
  const deleteCardAsync = async (state: string, list:ICard[], position:number) => {
    const result = Array.from(list) as ICard[];
    const [removed] = result.splice(position, 1);
    if (removed?.type === "Opportunity"){
      return {code:false, error:"You cannot move an opportunity please contact your rockstar manager", result:[]}
    }
    
    const result_re = result.map((elem, index) => {
      elem.position = index
      return elem
    })
    const order_re = getOrderFromList(result_re)
    const resp = await setCardOrderAsync(order_re)
    if (!resp.code){
      return(resp)
    }

    removed.active = false
    const resp2 =  await fetchAuth(
      window.__API_URL__ + `/wish`, 
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(removed),
      }
    )
    .then(
      (data:any) => {
        return({code:true, error:"", result:result})
      }
    )
    .catch(
      error => {
        return({code:false, error:"Deleting didn't work please try again ...", result:[]})
      }
    )

    if (!resp2.code){
      const order = getOrderFromList(result)
      let resp3 = await setCardOrderAsync(order)
      if (!resp3.code){
        resp3 = {code:false, error:"Something went wrong, please reload the page and try again ...", result:[]}
        return resp3
      }
      return(resp2)
    }
    return resp2
  };

  const reorderCardAsync = async (list:ICard[], startIndex:number, endIndex:number) => {
    const result = Array.from(list) as ICard[];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    const result_re = result.map((elem, index) => {
      elem.position = index
      return elem
    })
    const order_re = getOrderFromList(result_re)
    const resp = await setCardOrderAsync(order_re)
    if (!resp.code){
      return(resp)
    }
    
    resp.result = result_re

    return resp;
  };
  
  const moveCardAsync = async (source:ICard[], destination:ICard[], stateSource:DraggableLocation, stateDestination:DraggableLocation) => {
    const _source = Array.from(source);
    const _destination = Array.from(destination);
    const [removed] = _source.splice(stateSource.index, 1);
    if (removed?.type === "Opportunity"){
      return ({code:false, error:"You cannot move an opportunity please contact your rockstar manager", result:{source:[], destination:[]}})
    }
    _destination.splice(stateDestination.index, 0, removed);

    const source_re = _source.map((elem, index) => {
      elem.position = index
      return elem
    })
    const sourceorder_re = getOrderFromList(source_re)
    const resp = await setCardOrderAsync(sourceorder_re)
    if (!resp.code){
      return(resp)
    }

    const destination_re = _destination.map((elem, index) => {
      elem.position = index
      return elem
    })
    const destinationorder_re = getOrderFromList(destination_re)
    const resp2 = await setCardOrderAsync(destinationorder_re)
    if (!resp2.code){
      const sourceorder = getOrderFromList(_source)
      await setCardOrderAsync(sourceorder)
      return(resp2)
    }
    
    removed.state = stateDestination.droppableId
    const result = {
      source: source_re,
      destination: destination_re
    } as IMove;
    const resp3 = await fetchAuth(
      window.__API_URL__ + `/wish`, 
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(removed),
      }
    )
    .then(
      (data:any) => {
        return({code:true, error:"", result:result})
      }
    )
    .catch(
      error => {
        return({code:false, error:"Moving didn't work please try again ...", result:{source:[], destination:[]}})
      }
    )

    if (!resp3.code){
      const sourceorder = getOrderFromList(_source)
      await setCardOrderAsync(sourceorder)
      const destinationorder = getOrderFromList(_destination)
      await setCardOrderAsync(destinationorder)
    }

    return resp3
  };

  const onDragEnd = async (dragdrop:DropResult) => {
    const { source, destination } = dragdrop;

    if (!destination) {
    }
    else if (source.droppableId === destination.droppableId) {
      const result = await reorderCardAsync(
        getList(source.droppableId) as ICard[],
        source.index,
        destination.index
      );
      if (!result.code){
        toastHandler.showMsg(result.error, true)
      } 
      else {
        const setList = getSetList(source.droppableId) as React.Dispatch<React.SetStateAction<ICard[]>>
        setList(result?.result as ICard[]);
      } 
    } 
    else {
      const result = await moveCardAsync(
        getList(source.droppableId) as ICard[],
        getList(destination.droppableId)  as ICard[],
        source,
        destination
      );

      if (!result.code){
        toastHandler.showMsg(result.error, true)
      }
      else {
        const setSource = getSetList(source.droppableId) as React.Dispatch<React.SetStateAction<ICard[]>>
        setSource((result?.result as IMove).source);
        const setDestination = getSetList(destination.droppableId) as React.Dispatch<React.SetStateAction<ICard[]>>
        setDestination((result?.result as IMove).destination); 
      }
    }
  };

  const onClickCard = (item:ICard) =>{
    setNewItem(false);
    setActiveItem(item)
    setOverlay(1)
  }

  const onClickNewCard = (state:string) =>{
    setNewItem(true);
    setNewItemState(state);
    setActiveItem(emptyCard())
    setOverlay(1);
  }

  const onCreateCard = async (cardInfo:ICardInfo, state:string) => {
    const result = await createCardAsync(cardInfo, state, getList(state) as ICard[])

    if (!result.code){
      toastHandler.showMsg(result.error, true)
    }
    else{
      const setList = getSetList(state) as React.Dispatch<React.SetStateAction<ICard[]>>
      setList(result.result);
      setNewItem(false);
      setActiveItem(result.item)
    }
    return(result.code)
  }

  const onDeleteCard = async (dragdrop:IDeleteResult) => {
    const state = dragdrop.droppableId
    const result = await deleteCardAsync(state, getList(state) as ICard[], dragdrop.position)
    
    if (!result.code){
      toastHandler.showMsg(result.error, true)
    }
    else{
      const setList = getSetList(state) as React.Dispatch<React.SetStateAction<ICard[]>>
      setList(result?.result as ICard[]);
    }
  }

  return(
    <>
      <MainMenu pos={1}/>
      <DragDropContext onDragEnd={onDragEnd}>
        <SBoard>
          <List 
            pos={1}
            state="wishlist" 
            items={wishlist}
            onAddCard={() => onClickNewCard("wishlist")}
            onDeleteCard={onDeleteCard}
            onClickCard={onClickCard}/>
          <List
            pos={2}
            state="applied" 
            items={applied}
            onAddCard={() => onClickNewCard("applied")}
            onDeleteCard={onDeleteCard}
            onClickCard={onClickCard}/>
          <List 
            pos={3}
            state="interview"
            items={interview}
            onAddCard={() => onClickNewCard("interview")}
            onDeleteCard={onDeleteCard}
            onClickCard={onClickCard}/>
          <List 
            pos={4}
            state="offer"
            items={offer}
            onAddCard={() => onClickNewCard("offer")}
            onDeleteCard={onDeleteCard}
            onClickCard={onClickCard}/>
          <List 
            pos={5}
            state="declined"
            items={declined}
            onAddCard={() => onClickNewCard("declined")}
            onDeleteCard={onDeleteCard}
            onClickCard={onClickCard}/> 
        </SBoard>
      </DragDropContext>
      {overlay===1 ? 
        <ApplicationContainer 
          pos={1} 
          onClickMenuItem={setOverlay}
          item={activeItem}
        >
          <ApplicationInfo
            id={activeItem.id}
            item={activeItem}
            newItem={newItem}
            onAddItem={(cardInfo:ICardInfo) => onCreateCard(cardInfo, newItemState)}
          />
        </ApplicationContainer> 
        : <></>
      }
      {overlay===2 ? 
        <ApplicationContainer 
          pos={2} 
          onClickMenuItem={setOverlay}
          item={activeItem}
        >
          <ApplicationActivities 
            id={activeItem.id}
            item={activeItem}
          />
        </ApplicationContainer> 
        : <></>
      }
      {overlay===3 ? 
        <ApplicationContainer 
          pos={3} 
          onClickMenuItem={setOverlay}
          item={activeItem}
        >
          <ApplicationNotes 
            id={activeItem.id}
          />
        </ApplicationContainer> 
        : <></>
      }
      {overlay===4 ? 
        <ApplicationContainer 
          pos={4} 
          onClickMenuItem={setOverlay}
          item={activeItem}
        >
        </ApplicationContainer> 
        : <></>
      }
    </>
  )
}