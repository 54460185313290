import React from 'react'
import { SLogoWrapper, SHeader } from './MenuStyles'
import { RockstarLogo } from '../svgs'

export default function SignUpBanner({color}: any){

  return(    
    <SHeader>
      <SLogoWrapper>
        <RockstarLogo color="#111111"/>
      </SLogoWrapper>
    </SHeader>
  )
} 