import React from 'react';
import MainMenu from '../_Menu/MainMenu';
import UserFormPersonal from '../_UserForm/Personal'
import ProfileContainer from './ProfileContainer';


export default function VProfileContact({...props}:any) {
  return(
    <>
      <MainMenu pos={3}/>
      <ProfileContainer pos={5}>
        <UserFormPersonal/>
      </ProfileContainer>
    </>
  )
}