type RResponse = {
  ok: boolean
  status: number
  body: any
}

export const handleResponse = (response:RResponse) => {
  
  if (response.ok){
    return {data:response.body, errors:null}
  }

  if (response.status === 401){
    return {data:response.body, errors:401}
  }
  
  if (response.body?.errors){
    return {data:null, errors:response.body.errors as Array<string>}
  }
  
  return {data:null, errors:["Something went wrong please check your connection and reload. If the problem persists return later, we are most likley working on a fix."]}
}

export const tryFetch = async (input:string, init:RequestInit) => {
  let response;
  
  try {
    response = await Promise.race([
      fetch(input, init),
      new Promise((_, reject) => setTimeout(() => reject(),30000)),
    ]);
  } catch (error) {
    console.warn(error)
    response = {
      ok: false,
      status: 0,
      body: {}
    } as RResponse
    return handleResponse(response)
  }
  try {
    const body = await (response as Response).json();
    response = {
      ok: (response as Response).ok,
      status: (response as Response).status,
      body: body
    } as RResponse
  } catch (error) {
    console.warn(error)
    response = {
      ok: false,
      status: 0,
      body: {}
    } as RResponse
  }
  return handleResponse(response)
}