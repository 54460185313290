import React from 'react';
import { useNavigate } from 'react-router-dom';
import UserFormContact from '../_UserForm/Contact'
import SignUpContainer from './SignUpContainer';

export default function VSignUpContact({...props}:any) {
  const navigate = useNavigate();

  return(
    <>
      <SignUpContainer pos={1}>
        <UserFormContact submitCallback={() => {navigate(`/signup/dreamJob`)}}/>
      </SignUpContainer>
    </>
  )
}