import React, { useContext, useEffect } from 'react'
import { Form, Col } from 'react-bootstrap';
import { SForm, SCol, SGroup, SLabel, SControl, SFeedback, SButton, SColCheck, SCheckControl, SCheckContainer } from './FormStyle'
import styled from 'styled-components'
import { Formik } from 'formik';
import * as yup from "yup";
import { useTranslation } from 'react-i18next';
import config from '../config.json'
import { ICookie, UserContext } from '../_Core/AuthHandling'
import { tryFetch } from '../_Core/ErrorHandling'
import { ToastContext } from '../_Core/Toast'
import { FileUploadField, ServerFile, ClientFile } from './Fields/FileUploadField'
import './FormStyle.css';

//TODO go through this file at the end
//TODO remove capchta?
declare global {
  interface Window {
    grecaptcha: {
      ready: Function
      execute: {(s:string, o:object) : Promise<string>}
    };
  }
}

interface IValues {
  firstName: string;
  lastName: string;
  telephone: string;
  email: string;
  files: File[];
  recaptcha: string;
  referralKey?: string;
}

const STerms = styled.p`
  color: #111111;
  display: flex;
  font-family: Ubuntu;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 143.2%;
  // display: inline;
  margin-bottom: 0px;
  // margin-right: 5px;
`
const STermsBreak = styled(STerms)`
  &&::after {
    content: "\ ";
    white-space: pre;
  }
`

const SSignupPageButton = styled(SButton)`
  display: flex;
  justify-content: center;
  width: 450px;

  @media (max-width: 769px) {
    width: 100%;
  }
`

const STermsFeedback = styled(SFeedback)`
  left: 6px;
  max-width: 450px;
  position: absolute;
  top: 45px;
  width: 470px;
  
  @media (max-width: 769px) {
    width: 100%;
  }
`
const SSignUpPageColCheck = styled(SColCheck)`
  && input {
    display: flex;
    height: 16px;
    width: 16px;
    margin-left: 10px;
  }
  @media (max-width: 769px) {
    min-width: 100%;
    && input {
      margin-left: 0px;
    }
  }
`

const STermsWrapper = styled.div`
  align-items: baseline;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  
  @media (max-width: 769px) {
    left: 25px;
    position: absolute;
    top: -3px;
    width: auto;
  }
`

const toBase64 = (file:File) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

export default function UserFormSignup({...props}:any) {
  const t = useTranslation().t;
  const user = useContext(UserContext);
  const toastHandler = useContext(ToastContext);
  
  const params = new URLSearchParams(window.location.search)
  const referralKey = params.has('ref') ? params.get('ref') : undefined

  useEffect(() => {
    const script = document.createElement("script")
    script.src = `https://www.google.com/recaptcha/api.js?render=${config.REACT_APP_GOOGLE_RECAPTCHA_API_KEY}`
    document.body.appendChild(script)
  }, [])

  const fetchRecaptchToken = (values:IValues, callbackFcn:{(v:IValues) : Promise<void>}) => {
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(config.REACT_APP_GOOGLE_RECAPTCHA_API_KEY, {action: 'submit'})
        .then(async (token:string) => {
          values.recaptcha = token;
          await callbackFcn(values)
        });
    });
  }

  const submitFiles = async (file:File, cookie:ICookie) => {
    const body = {
      candidate_id: cookie.id,
      name: file.name,
      content_type: file.type,
      data: await toBase64(file)  
    }
    
    const {data, errors} = await tryFetch(
      window.__API_URL__ + '/file', 
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': cookie.authorization,
        },
        body: JSON.stringify(body),
      }
    )
    if (errors !== null && errors !== 401){
      toastHandler.showMsg(errors as string[], true)
    } 
  }

  const onSubmit = async (values:IValues) => {
    const files = values.files
    const body = values
    if (referralKey){
      body.referralKey = referralKey
    }
  
    const {data, errors} = await tryFetch(
      window.__API_URL__ + `/signup`, 
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      }
    )
    if (errors === null){
      const userdata = user.setUser(data)
      files.map(async (file) => submitFiles(file, userdata))
      props.submitCallback()
    } else if (errors === 401){
      toastHandler.showMsg(data.errors, true)
    } else {
      toastHandler.showMsg(errors as string[], true)
    }
  }

  return (
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        telephone: '',
        email: '',
        files : [] as ClientFile[],
        message : '',
        terms : false,
        recaptcha: '',
        //referralKey: referralKey,
      }}
      enableReinitialize={true}
      onSubmit={
        async (values) => {
          fetchRecaptchToken(values, onSubmit)
        }
      }
      validationSchema={yup.object().shape({ 
        firstName: yup.string()
          .required(t('ProfileContact.firstNameValidationErrorRequired')), 
        lastName: yup.string()
          .required(t('ProfileContact.lastNameValidationErrorRequired')),   
        telephone: yup.string()
          .required(t('ProfileContact.telephoneValidationErrorRequired'))
          .matches(/(^[+0{2}][0-9\-\s/]+$)/, t('ProfileContact.telephoneValidationErrorMatch')),   
        email: yup.string()
          .required(t('ProfileContact.emailValidationErrorRequired'))
          .email(t('ProfileContact.emailValdationErrorFormat')),
        files: yup.mixed()
          .test('fileSize', t('ProfileContact.filesValidationErrorRequired'), (value:File[]) => {return(value.length >= 1)}),
        terms: yup.bool()
          .oneOf([true], t('ProfileContact.termsValidationErrorRequired'))
      })}
    >
      {({
      handleSubmit,
      handleChange,
      handleBlur,
      values,
      touched,
      isValid,
      errors,
      setFieldValue,
      setErrors
      }) => (
        
        <SForm noValidate onSubmit={handleSubmit}>
          <SCol md="12">
            {/* <Row> */}
              <SGroup as={Col} md="12" xs controlid="firstName">
                <SLabel>{t('ProfileContact.firstName')}</SLabel>
                <SControl
                  type="text" 
                  name="firstName" 
                  placeholder={t('ProfileContact.firstNamePlaceHolder')}
                  value={values.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.firstName && !!errors.firstName}
                  />
                <SFeedback type="invalid" tooltip>{errors.firstName}</SFeedback>
              </SGroup> 
              <SGroup as={Col} md="12" xs controlid="lastName">
                <SLabel>{t('ProfileContact.lastName')}</SLabel>
                <SControl 
                  type="text" 
                  name="lastName" 
                  placeholder={t('ProfileContact.lastNamePlaceHolder')}
                  value={values.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.lastName && !!errors.lastName}
                  />
                <SFeedback type="invalid" tooltip>{errors.lastName}</SFeedback>
              </SGroup>
              <SGroup as={Col} md="12" xs controlid="telephone">
                <SLabel>{t('ProfileContact.telephone')}</SLabel>
                <SControl 
                  type="text" 
                  name="telephone" 
                  placeholder={t('ProfileContact.telephonePlaceHolder')}
                  value={values.telephone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.telephone && !!errors.telephone}
                  />
                <SFeedback type="invalid" tooltip>{errors.telephone}</SFeedback>
              </SGroup>        
              <SGroup as={Col} md="12" xs controlid="email">
                <SLabel>{t('ProfileContact.email')}</SLabel>
                <SControl
                  type="text"
                  name="email"
                  placeholder={t('ProfileContact.emailPlaceHolder')}
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.email && !!errors.email}
                />
                <SFeedback type="invalid" tooltip>{errors.email}</SFeedback>
              </SGroup>
              {/* TODO upload file drop zone */}
              <SGroup as={Col} md="12" xs controlid="files">
                <SCol md="3" style={{ alignItems: "baseline", display: "flex", flexWrap: "wrap", margin: "0px", padding: "0px"}}>
                  <SLabel>{t('ProfileContact.cv')}</SLabel>
                </SCol>
                <SCol md="9" style={{ alignItems: "baseline", display: "flex", flexWrap: "wrap", margin: "0px", padding: "0px"}}>
                  <FileUploadField 
                    serverFiles={[]} 
                    setServerFiles={(files:ServerFile[]) => {}}
                    clientFiles={values.files}
                    setClientFiles={(files:ClientFile[]) => setFieldValue("files", files)} 
                    invalid={touched.files && !!errors.files}
                    error={errors.files as string}
                    readOnly={false}/>
                </SCol>
              </SGroup>
              <SGroup as={Col} md="12" xs controlid="message">
                <SLabel>{t('ProfileContact.message')}</SLabel>
                <Form.Control 
                  style={{
                    height:"120px",
                    border:"1px solid #474747",
                    borderRadius:"4px",
                    margin:"0px",
                    fontFamily:"Ubuntu",
                    fontStyle:"normal",
                    fontWeight:"normal",
                    fontSize:"16px",
                    color:"#495057"
                  }}
                  as="textarea" 
                  rows={3}
                  name="message" 
                  placeholder={t('ProfileContact.messagePlaceHolder')}
                  value={values.message}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.message && !!errors.message}
                />
                <SFeedback type="invalid" tooltip>{errors.message}</SFeedback>
              </SGroup>
              <SGroup as={Col} md="12" xs controlid="terms" style={{ justifyContent: "flex-end", marginTop: "12px", height:"48px"}}>
                <SCol md="9" style={{ alignItems: "baseline",justifyContent: "flex-start", display: "flex", margin: "0px", padding: "0px"}}>
                  <SCheckContainer>
                    <SCheckControl 
                      type="checkbox"
                      name="terms"
                      label={t('ProfileContact.terms')}
                      value={values.terms}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.terms && !!errors.terms}
                    />
                    <STermsFeedback type="invalid" tooltip>{errors.terms}</STermsFeedback>
                  </SCheckContainer>
                  <STermsWrapper>
                    <STerms>{t('ProfileContact.terms')}</STerms>
                    <a style={{ marginLeft: "5px", marginRight: "5px", color: "#111111", fontWeight: "700", textDecoration: "underline", textDecorationThickness: "2px", }} href="https://www.rockstarjobs.ch/privacy.html" target="_blank" rel="noreferrer">{t('ProfileContact.termslinkPrivacy')}</a>
                    <STermsBreak>&</STermsBreak>
                    <a style={{ color: "#111111", fontWeight: "700", textDecoration: "underline", textDecorationThickness: "2px", }} href="https://www.rockstarjobs.ch/terms.html" target="_blank" rel="noreferrer">{t('ProfileContact.termslinkTermsAndConditions')}</a>
                  </STermsWrapper>
                </SCol>  
              </SGroup>
              <SGroup as={Col} md="12" xs controlid="button" style={{ justifyContent: "flex-end" }}>
                <SCol md="9" style={{ alignItems: "baseline", display: "flex", margin: "0px", padding: "0px"}}>
                  <SButton primary={true} type="submit" disabled={!values.terms}>{t('ProfileContact.joinNow')}</SButton>
                </SCol> 
              </SGroup>
          </SCol>
        </SForm>
      )}  
    </Formik>
  )
}
