import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Row, Col } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from "yup";
import { SButton, SGroup, SLabel, SControl, SFeedback } from './FormStyle'
import styled from 'styled-components'
import { useFetchAuth } from '../_Core/AuthHandling';
import { ToastContext } from '../_Core/Toast'

interface INote {
  id: string
  title: string
  description: string
  onDelete: Function
}

interface INoteData {
  id: string
  title: string
  description: string
  createdAt?: string
  updatedAt?: string
}

interface IApplicationNote {
  id: string
}

export const SActivityInputContainer = styled(Form)`
  margin: 15px;
  padding: 10px 20px 10px 20px;

  background: none; 
  border: none;
  box-sizing: border-box;
  border-radius: 0px;
  
  text-align: left;
`

const SFormListSeperator = styled.div`
  height: 1px;
  background-color: #333333;
  margin: 0px 20px 0px 20px;
`

export const SNoteContainer = styled.div`
  margin: 20px 20px 20px 20px;
  padding: 15px;

  background: none; 
  border: solid 1px #000000;
  box-sizing: border-box;
  border-radius: 4px;

`

export const SNoteTitle = styled("div")`
  width: 90%;

  font-family: Ubuntu;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  color: #000000;
`

export const SNoteDescription = styled("div")`
  width: 90%;
  margin-top: 5px;

  font-family: Ubuntu;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #000000;
`


const Note = ({...props}:INote) => {
  return(
    <SNoteContainer>
      <SNoteTitle>
        {props.title}
      </SNoteTitle>
      <SNoteDescription>
        {props.description}
      </SNoteDescription>
    </SNoteContainer>
  )
}

export default function ApplicationNotes({...props}:IApplicationNote){
  const t = useTranslation().t;
  const toastHandler = useContext(ToastContext);
  const [notes, setNotes] = useState([] as INoteData[])
  const fetchAuth = useFetchAuth()

  useEffect(() => {
    const effectAsync = async () => {
      fetchAuth(
        window.__API_URL__ + `/notes?applicationId=${props.id}`, 
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        }
      )
      .then((data: INoteData[]) => {
        setNotes(data)
      })
    }
    effectAsync()
  }, [])

  const deleteAsync = async (id:string, list:INoteData[]) => {
    const listClone = Array.from(list) as INoteData[];

    return await fetchAuth(
      window.__API_URL__ + `/note/${id}`, 
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        }
      }
    )
    .then((data:any) => {
      return({code:true, error:"", result:listClone})
    })
    .catch(error => {
      return({code:false, error:"Deleting didn't work please try again ...", result:[]})
    })
  }

  const onDelete = useCallback(async (elem:INoteData) => {
    const result = await deleteAsync(elem.id, notes)

    if (!result.code){
      toastHandler.showMsg(result.error, true)
    }
    else{
      setNotes(result.result);
    }
  }, [notes])

  const onSubmit = useCallback(
    (elem:INoteData) => {
      const listClone = Array.from(notes) as INoteData[]; 
      listClone.push(elem)
      setNotes(listClone)
  }, [notes])

  const notesDisplay = notes.map(elem => {
    return(
      <Note 
        id={elem.id}
        title={elem.title}
        description={elem.description}
        onDelete={() => onDelete(elem)}/>)
  })

  return(
    <>
      <Formik
        initialValues={{
          title: '',
          description: '',
          date: '',
        }}
        onSubmit={(values) => {
          const body = {
            applicationId: props.id,
            title: values.title,
            description: values.description,
          }

          fetchAuth(
            window.__API_URL__ + `/note`, 
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(body),
            }
          )
          .then((data:any) => {
            const note = {
              id: data.id,
              title: values.title,
              description: values.description,
            } as INoteData
            
            onSubmit(note)
          })
        }}
        validationSchema={yup.object().shape({ 
          title: yup.string().required(t('Application.Overlay.Notes.TitleRequiredError'))
        })}
      >
        {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue
        }) => (  
          <SActivityInputContainer noValidate onSubmit={handleSubmit}>
            <Row>
              <SGroup as={Col} md="7" controlId="title">
                <SLabel>{t('Application.Notes.title')}</SLabel>
                <SControl 
                  type="text" 
                  name="title" 
                  placeholder={t('Application.Notes.titlePlaceholder')}
                  value={values.title}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.title && !!errors.title}
                />
                <SFeedback type="invalid" tooltip>{errors.title}</SFeedback>
              </SGroup>
            </Row>
            <Row>
              <SGroup as={Col} md="12" controlId="description">
                <SLabel style={{width:"83.7px"}}>{t('Application.Notes.description')}</SLabel>
                <Form.Control 
                  style={{width:"654.3px"}}
                  as="textarea" 
                  rows={7}
                  name="description" 
                  placeholder={t('Application.Notes.descriptionPlaceholder')}
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  />
              </SGroup>
            </Row>
            <Row>
              <SGroup as={Col} md="6" />
              <SGroup as={Col} md="6" >
                <SButton primary type="submit">{t('Base.submit')} </SButton>
              </SGroup>
            </Row>
          </SActivityInputContainer>
        )}  
      </Formik>

      {notes.length > 0 ?
        <>
          <SFormListSeperator/>
          {notesDisplay}
        </>
        : <div style={{height: "30px"}}/>
      }
    </>
  )
}