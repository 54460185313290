import React, { useState, createContext } from 'react';
import styled from 'styled-components'
import { Button } from 'react-bootstrap'

const SToastDisplay = styled("div")<{show:boolean, error:boolean, success:boolean}>`
  z-index: 99;
  position: absolute;
  bottom: 100px;
  left: 100px;
  width: 400px; 
  min-height: 100px;
  padding: 20px;

  background: ${props => props.error ? "#fcdcdc" : props.success ? "#d4fcd4" : "#e3e3e3"} ;
  border: 1px solid ${props => props.error ? "#ff9696" : props.success ? "#9fff9f" : "#cccccc"} ;
  border-radius: 4px;

  font-family: Ubuntu;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #333333;

  @media (max-width: 797px) {
    left: 0px;
    width: calc(100% - 40px); 
    margin: 0px 20px 0px 20px;
  }
  
  ${props => props.show ? "" : "display: none;"} 
`
const SToastCloseButton = styled(Button)<{error:boolean, success:boolean}>`
  float: right;

  margin-bottom: 10px;

  background: #FFFFFF ;
  border: 1px solid ${props => props.error ? "#ff9696" : props.success ? "#9fff9f" : "#cccccc"} ;
  
  font-family: Ubuntu;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: ${props => props.error ? "#ff0000" : props.success ? "#00ff00" : "#333333"} ;
`

export const ToastContext = createContext(
  {
    showMsg: (msg:string[]|string, error:boolean = true, success:boolean = true) => {}, 
  }
)

export function useToastProvider() {
  const [message, setMessage] = useState("")
  const [active, setActive] = useState(false)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  
  const Toast = <SToastDisplay 
    show={active} 
    error={error}
    success={success}
    >
      <div>
        <SToastCloseButton 
          onClick={() => setActive(false)}
          error={error}
          success={success}
        >
          <span className="fas fa-times"/>  
        </SToastCloseButton>
      </div>
      {message}
    </SToastDisplay>

  const sleep = () => new Promise((r) => setTimeout(r, 5000));

  const showMsg = (msg:string[]|string, error:boolean = false, success:boolean = false) => {
    if (msg instanceof Array<string>){
      setMessage(msg[0]);
    } else{
      setMessage(msg);
    }
    setError(error);
    setSuccess(success);
    setActive(true);
    sleep().then(() => setActive(false))
  }

  const toastValue = {
    showMsg:showMsg
  }

  return {Toast, toastValue}
}