import React from 'react'
import Select from 'react-select';
import CountryCodes from './CountryCodes.json'
import { useTranslation } from 'react-i18next';
import { SInputGroup, SGroupControl, SGroupIcon, SSelectResult, selectStyles, SSelectContainer } from '../FormStyle';
import '../FormStyle.css'

export type TPermit = {
  country: string
  detail: string
}

interface IPermit {
  country: string
  value: string
  onBlur: Function
  onDelete: Function
  readOnly: boolean
}

interface IPermitSelectField{
  permits: TPermit[]
  setPermits: Function
  readOnly: boolean
}

const Permit = ({...props}:IPermit) => {
  const t = useTranslation().t;
  return(
    <SSelectResult>
      <SInputGroup className="mb-2" style={{marginTop:"16px"}}>
        <SGroupIcon style={{width:"54px"}}><img width="28" height="28" src={window.location.origin + `/Assets/CountryFlags/${props.country}.svg`} alt=""/></SGroupIcon>
        <SGroupControl
          style={{width:"calc(100% - 54px - 40px)", height:"28px",}}
          type="text"
          defaultValue={props.value}
          placeholder={t("ProfilePersonal.workpermitsDefinitionPlaceHolder")}
          readOnly={props.readOnly}
          plaintext={props.readOnly}
          onBlur={props.readOnly ? () => {} : (e:React.FocusEvent<HTMLInputElement>) => props.onBlur(e.target.value)} />
        {!props.readOnly ? 
          <SGroupIcon onClick={() => props.onDelete()}><span className="fas fa-times"/></SGroupIcon>
          : <></>
        }
      </SInputGroup>
    </SSelectResult>
  )
}

export const PermitSelectField = ({...props}:IPermitSelectField) => {
  const t = useTranslation().t;

  const options = CountryCodes.map((code)=>{
    return({ value: code, label: t(`Country.${code}`) })
  })
  
  const nationsDisplay = (props.permits.length === 0 && props.readOnly) ? [<div>{t("Base.empty")}</div>] 
    : props.permits.map((permit:TPermit, index:number) => {
      return(
        <Permit
          key={`permit_${t(`Country.${permit.country}`)}`}
          country={permit.country}
          readOnly={props.readOnly}
          value={permit.country} 
          onBlur={(value:string) => {
            let permits_tmp = [...props.permits] 
            permits_tmp[index].detail = value
            props.setPermits(permits_tmp)}}
          onDelete={() => {
              let permits_tmp = [...props.permits]
              permits_tmp.splice(index, 1)
              props.setPermits(permits_tmp)
            }
          }
        />
      )
    }
  )

  const distabledOptions = new Set()
  props.permits.map((elem:TPermit) => {
    distabledOptions.add(elem.country)
    return true
  })

  return(
    <SSelectContainer style={{marginBottom:"32px"}}>
      {!props.readOnly ?
        <Select
          styles={selectStyles}
          options={options}
          placeholder={t("ProfilePersonal.workpermitsSearchPlaceHolder") as string}
          value={{value:"", label:t('ProfilePersonal.workpermitsSearchPlaceHolder')}}
          escapeClearsValue={true}
          onChange={
            async (s: any, action: any) => {
              if(action.action==="select-option"){
                let permits_tmp = [...props.permits]
                permits_tmp.push({
                  country:s.value as string, 
                  detail: ''
                })
                props.setPermits(permits_tmp);
              }
            }
          } 
          isOptionDisabled={(option) => distabledOptions.has(option.value)}
        />
        : <></>
      }
      {nationsDisplay}
    </SSelectContainer>
  )
}