import React from 'react';
import { useNavigate } from 'react-router-dom';
import UserFormPersonal from '../_UserForm/Personal'
import SignUpContainer from './SignUpContainer';

export default function VSignUpPersonal({...props}:any) {
  const navigate = useNavigate();

  return(
    <>
      <SignUpContainer pos={5}>
        <UserFormPersonal 
          submitCallback={() => navigate(`/signup/success`)}
          returnCallback={() => navigate(`/signup/portfolio`)}/>
      </SignUpContainer>
    </>
  )
}