import React from 'react'

const RockstarLogo = ({ color }) => {
    return (
        <svg 
            width="100"
            height="30"
            viewBox="0 0 100 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
        <path
            d="M4.98126 6.44041C5.18881 6.44041 5.42231 6.45793 5.68175 6.49298C5.95849 6.51051 6.22658 6.54556 6.48602 6.59813C6.74546 6.63318 6.97896 6.67699 7.18651 6.72957C7.41136 6.76462 7.57567 6.79967 7.67944 6.83472L7.26434 8.96399C7.07408 8.8939 6.75411 8.81503 6.30441 8.72741C5.87201 8.62226 5.30989 8.56968 4.61804 8.56968C4.16835 8.56968 3.71865 8.62226 3.26895 8.72741C2.83655 8.81503 2.55117 8.87637 2.4128 8.91142V20.399H0V7.30789C0.570769 7.09759 1.27991 6.90482 2.12741 6.72957C2.97492 6.53679 3.9262 6.44041 4.98126 6.44041Z"
            fill={color}
        />
        <path
            d="M21.1377 13.5643C21.1377 14.6508 20.9821 15.6322 20.6707 16.5085C20.3594 17.3847 19.9184 18.1383 19.3476 18.7692C18.7941 19.4001 18.1282 19.8908 17.3499 20.2413C16.5716 20.5743 15.7241 20.7407 14.8074 20.7407C13.8907 20.7407 13.0432 20.5743 12.2649 20.2413C11.4865 19.8908 10.812 19.4001 10.2412 18.7692C9.68775 18.1383 9.25534 17.3847 8.94401 16.5085C8.63269 15.6322 8.47702 14.6508 8.47702 13.5643C8.47702 12.4953 8.63269 11.5226 8.94401 10.6464C9.25534 9.75262 9.68775 8.99028 10.2412 8.35938C10.812 7.72849 11.4865 7.24655 12.2649 6.91358C13.0432 6.56308 13.8907 6.38783 14.8074 6.38783C15.7241 6.38783 16.5716 6.56308 17.3499 6.91358C18.1282 7.24655 18.7941 7.72849 19.3476 8.35938C19.9184 8.99028 20.3594 9.75262 20.6707 10.6464C20.9821 11.5226 21.1377 12.4953 21.1377 13.5643ZM18.6212 13.5643C18.6212 12.0221 18.2752 10.8041 17.5834 9.91034C16.9088 8.99904 15.9835 8.5434 14.8074 8.5434C13.6312 8.5434 12.6973 8.99904 12.0054 9.91034C11.3309 10.8041 10.9936 12.0221 10.9936 13.5643C10.9936 15.1065 11.3309 16.3332 12.0054 17.2445C12.6973 18.1383 13.6312 18.5852 14.8074 18.5852C15.9835 18.5852 16.9088 18.1383 17.5834 17.2445C18.2752 16.3332 18.6212 15.1065 18.6212 13.5643Z"
            fill={color}
        />
        <path
            d="M29.6174 20.7145C28.5277 20.7145 27.5678 20.5392 26.7376 20.1887C25.9247 19.8382 25.2328 19.3475 24.6621 18.7166C24.1086 18.0857 23.6935 17.3409 23.4168 16.4822C23.14 15.6059 23.0016 14.6421 23.0016 13.5906C23.0016 12.5391 23.1487 11.5752 23.4427 10.699C23.754 9.82272 24.1864 9.06915 24.7399 8.43825C25.2934 7.78983 25.9679 7.29037 26.7635 6.93987C27.5765 6.57184 28.4758 6.38783 29.4617 6.38783C30.0671 6.38783 30.6724 6.44041 31.2778 6.54556C31.8832 6.65071 32.4626 6.81719 33.0161 7.04502L32.4712 9.12172C32.108 8.94647 31.6843 8.80627 31.2 8.70112C30.733 8.59597 30.2314 8.5434 29.6952 8.5434C28.3461 8.5434 27.3084 8.97276 26.5819 9.83148C25.8728 10.6902 25.5182 11.9432 25.5182 13.5906C25.5182 14.3266 25.5961 15.0013 25.7517 15.6147C25.9247 16.2281 26.1841 16.7538 26.53 17.1919C26.8933 17.6301 27.3516 17.9718 27.9051 18.2172C28.4585 18.445 29.1331 18.5589 29.9287 18.5589C30.5687 18.5589 31.1481 18.4976 31.667 18.3749C32.1858 18.2522 32.5923 18.1208 32.8863 17.9806L33.2236 20.031C33.0852 20.1186 32.8863 20.2062 32.6269 20.2939C32.3675 20.364 32.0734 20.4253 31.7448 20.4779C31.4162 20.548 31.0616 20.6005 30.6811 20.6356C30.3179 20.6882 29.9633 20.7145 29.6174 20.7145Z"
            fill={color}
        />
        <path
            d="M39.9131 12.8808C40.432 13.2839 40.9768 13.7833 41.5476 14.3792C42.1357 14.975 42.7064 15.6235 43.2599 16.3245C43.8307 17.0079 44.3669 17.7089 44.8684 18.4275C45.37 19.1284 45.7938 19.7856 46.1397 20.399H43.3118C42.9486 19.7856 42.5248 19.1635 42.0405 18.5326C41.5736 17.8842 41.0806 17.2708 40.5617 16.6925C40.0428 16.0966 39.5153 15.5534 38.9791 15.0627C38.443 14.572 37.9327 14.1601 37.4484 13.8272V20.399H35.0356V0.420597L37.4484 0V12.4602C37.8635 12.0396 38.3219 11.5752 38.8235 11.067C39.3251 10.5588 39.818 10.0418 40.3023 9.51603C40.7866 8.99028 41.2449 8.49082 41.6773 8.01765C42.1097 7.52695 42.4816 7.09759 42.7929 6.72957H45.6208C45.223 7.16769 44.7906 7.64963 44.3236 8.17537C43.8566 8.70112 43.3723 9.23563 42.8708 9.7789C42.3692 10.3047 41.8589 10.8392 41.34 11.3824C40.8385 11.9082 40.3628 12.4076 39.9131 12.8808Z"
            fill={color}
        />
        <path
            d="M50.9513 18.6377C51.9372 18.6377 52.6636 18.5063 53.1306 18.2434C53.6149 17.9806 53.8571 17.56 53.8571 16.9816C53.8571 16.3858 53.6236 15.9126 53.1566 15.5621C52.6896 15.2116 51.9199 14.8173 50.8475 14.3792C50.3287 14.1689 49.8271 13.9586 49.3428 13.7483C48.8758 13.5205 48.4693 13.2576 48.1234 12.9597C47.7775 12.6618 47.5008 12.3025 47.2932 11.8819C47.0857 11.4613 46.9819 10.9443 46.9819 10.3309C46.9819 9.12172 47.4229 8.16661 48.305 7.46561C49.1871 6.74709 50.3892 6.38783 51.9112 6.38783C52.2918 6.38783 52.6723 6.41412 53.0528 6.46669C53.4333 6.50174 53.7879 6.55432 54.1165 6.62442C54.4451 6.67699 54.7305 6.73833 54.9726 6.80843C55.2321 6.87853 55.431 6.93987 55.5694 6.99244L55.1283 9.09543C54.8689 8.95523 54.4624 8.81503 53.9089 8.67483C53.3555 8.51711 52.6896 8.43825 51.9112 8.43825C51.2367 8.43825 50.6486 8.57845 50.1471 8.85885C49.6455 9.12172 49.3947 9.54232 49.3947 10.1206C49.3947 10.4186 49.4466 10.6814 49.5503 10.9093C49.6714 11.1371 49.8444 11.3474 50.0692 11.5402C50.3114 11.7154 50.6054 11.8819 50.9513 12.0396C51.2972 12.1973 51.7123 12.3638 52.1966 12.5391C52.8366 12.7844 53.4074 13.0298 53.9089 13.2751C54.4105 13.5029 54.8343 13.7746 55.1802 14.09C55.5434 14.4055 55.8202 14.791 56.0104 15.2467C56.2007 15.6848 56.2958 16.2281 56.2958 16.8765C56.2958 18.1383 55.8288 19.0934 54.8948 19.7418C53.9781 20.3902 52.6636 20.7145 50.9513 20.7145C49.7579 20.7145 48.8239 20.6093 48.1494 20.399C47.4748 20.2062 47.0165 20.0573 46.7743 19.9521L47.2154 17.8491C47.4921 17.9543 47.9332 18.112 48.5385 18.3223C49.1439 18.5326 49.9481 18.6377 50.9513 18.6377Z"
            fill={color}
        />
        <path
            d="M60.8694 6.72957H65.9804V8.77998H60.8694V15.089C60.8694 15.7724 60.9213 16.342 61.0251 16.7976C61.1289 17.2358 61.2846 17.5863 61.4921 17.8491C61.6997 18.0945 61.9591 18.2697 62.2704 18.3749C62.5818 18.48 62.945 18.5326 63.3601 18.5326C64.0865 18.5326 64.6659 18.4537 65.0983 18.296C65.548 18.1208 65.8594 17.9981 66.0323 17.928L66.4993 19.9521C66.2572 20.0748 65.8334 20.2238 65.2281 20.399C64.6227 20.5918 63.9309 20.6882 63.1525 20.6882C62.2358 20.6882 61.4748 20.5743 60.8694 20.3464C60.2814 20.1011 59.8057 19.7418 59.4425 19.2686C59.0793 18.7955 58.8199 18.2172 58.6642 17.5337C58.5258 16.8327 58.4566 16.0265 58.4566 15.1152V2.9179L60.8694 2.4973V6.72957Z"
            fill={color}
        />
        <path
            d="M72.8386 18.6377C73.4094 18.6377 73.9109 18.629 74.3433 18.6115C74.793 18.5764 75.1649 18.5238 75.4589 18.4537V14.3792C75.286 14.2916 75.0006 14.2215 74.6028 14.1689C74.2223 14.0988 73.7553 14.0637 73.2018 14.0637C72.8386 14.0637 72.4494 14.09 72.0343 14.1426C71.6365 14.1952 71.2646 14.3091 70.9187 14.4843C70.5901 14.6421 70.3134 14.8699 70.0885 15.1678C69.8637 15.4482 69.7512 15.825 69.7512 16.2982C69.7512 17.1744 70.028 17.7878 70.5815 18.1383C71.1349 18.4713 71.8873 18.6377 72.8386 18.6377ZM72.631 6.38783C73.5996 6.38783 74.4125 6.51927 75.0698 6.78214C75.7443 7.02749 76.2805 7.38675 76.6783 7.85993C77.0934 8.31557 77.3874 8.86761 77.5604 9.51603C77.7334 10.1469 77.8198 10.8479 77.8198 11.619V20.1624C77.6123 20.1975 77.3183 20.25 76.9377 20.3201C76.5745 20.3727 76.1594 20.4253 75.6924 20.4779C75.2254 20.5304 74.7152 20.5743 74.1617 20.6093C73.6255 20.6619 73.0894 20.6882 72.5532 20.6882C71.7922 20.6882 71.0917 20.6093 70.4517 20.4516C69.8118 20.2939 69.2583 20.0485 68.7913 19.7155C68.3243 19.365 67.9611 18.9094 67.7017 18.3486C67.4422 17.7878 67.3125 17.1131 67.3125 16.3245C67.3125 15.5709 67.4595 14.9225 67.7535 14.3792C68.0649 13.8359 68.48 13.3978 68.9989 13.0648C69.5177 12.7319 70.1231 12.4865 70.8149 12.3288C71.5068 12.1711 72.2332 12.0922 72.9942 12.0922C73.2364 12.0922 73.4872 12.1097 73.7466 12.1448C74.0061 12.1623 74.2482 12.1973 74.4731 12.2499C74.7152 12.285 74.9228 12.32 75.0957 12.3551C75.2687 12.3901 75.3898 12.4164 75.4589 12.4339V11.7505C75.4589 11.3474 75.4157 10.9531 75.3292 10.5675C75.2427 10.1645 75.0871 9.81395 74.8622 9.51603C74.6374 9.20058 74.326 8.95523 73.9282 8.77998C73.5477 8.58721 73.0461 8.49082 72.4235 8.49082C71.6279 8.49082 70.9274 8.55216 70.322 8.67483C69.7339 8.77998 69.2929 8.8939 68.9989 9.01657L68.7135 6.99244C69.0248 6.85224 69.5437 6.72081 70.2701 6.59813C70.9966 6.45793 71.7835 6.38783 72.631 6.38783Z"
            fill={color}
        />
        <path
            d="M86.0301 6.44041C86.2377 6.44041 86.4712 6.45793 86.7306 6.49298C87.0074 6.51051 87.2754 6.54556 87.5349 6.59813C87.7943 6.63318 88.0278 6.67699 88.2354 6.72957C88.4602 6.76462 88.6245 6.79967 88.7283 6.83472L88.3132 8.96399C88.1229 8.8939 87.803 8.81503 87.3533 8.72741C86.9209 8.62226 86.3587 8.56968 85.6669 8.56968C85.2172 8.56968 84.7675 8.62226 84.3178 8.72741C83.8854 8.81503 83.6 8.87637 83.4617 8.91142V20.399H81.0489V7.30789C81.6196 7.09759 82.3288 6.90482 83.1763 6.72957C84.0238 6.53679 84.9751 6.44041 86.0301 6.44041Z"
            fill={color}
        />
        <path
            d="M95.9008 2.39215C95.9008 3.1282 95.8316 3.83796 95.6933 4.52143C95.5722 5.18737 95.4425 5.87961 95.3041 6.59813C95.9268 6.24763 96.5408 5.9059 97.1461 5.57292C97.7515 5.23995 98.4088 4.95079 99.1179 4.70544L99.2736 4.65287L100 6.861L99.7924 6.93987C99.1006 7.20274 98.4088 7.37799 97.7169 7.46561C97.0251 7.53571 96.3159 7.59705 95.5895 7.64963C96.1603 8.14032 96.6965 8.62226 97.198 9.09543C97.6996 9.55108 98.1666 10.0856 98.599 10.699L98.7287 10.883L96.8348 12.2236L96.731 12.0659C96.3159 11.435 95.9527 10.8129 95.6414 10.1995C95.3474 9.5686 95.0447 8.92018 94.7333 8.25424C94.422 8.92018 94.1107 9.5686 93.7994 10.1995C93.5053 10.8129 93.1508 11.435 92.7357 12.0659L92.6319 12.2236L90.738 10.883L90.8677 10.699C91.3001 10.0856 91.7671 9.55108 92.2687 9.09543C92.7702 8.62226 93.3064 8.14032 93.8772 7.64963C93.1508 7.59705 92.4416 7.53571 91.7498 7.46561C91.0579 7.37799 90.3661 7.20274 89.6743 6.93987L89.4667 6.861L90.1931 4.65287L90.3488 4.70544C91.0579 4.95079 91.7152 5.23995 92.3205 5.57292C92.9259 5.9059 93.5399 6.24763 94.1626 6.59813C94.0242 5.87961 93.8858 5.18737 93.7475 4.52143C93.6264 3.83796 93.5659 3.1282 93.5659 2.39215V2.18185H95.9008V2.39215Z"
            fill={color}
        />
        <path
            d="M47.2875 24.5088C48.0005 24.5088 48.5447 24.6401 48.9202 24.9027C49.3004 25.1653 49.4905 25.5664 49.4905 26.1059C49.4905 26.7791 49.1602 27.2351 48.4995 27.4738C48.5898 27.5836 48.692 27.7173 48.8061 27.8749C48.9202 28.0324 49.0366 28.2043 49.1554 28.3905C49.2743 28.572 49.3883 28.7605 49.4976 28.9563C49.607 29.1473 49.7044 29.3359 49.79 29.5221H48.7847C48.6944 29.3502 48.597 29.1783 48.4924 29.0064C48.3878 28.8298 48.2809 28.6603 48.1716 28.4979C48.067 28.3308 47.9624 28.1757 47.8579 28.0324C47.7533 27.8844 47.6559 27.7531 47.5656 27.6385C47.499 27.6433 47.442 27.6457 47.3945 27.6457C47.3469 27.6457 47.3018 27.6457 47.259 27.6457H46.8241V29.5221H45.9258V24.6305C46.1444 24.5828 46.3773 24.5518 46.6245 24.5374C46.8716 24.5183 47.0926 24.5088 47.2875 24.5088ZM47.3517 25.2894C47.1616 25.2894 46.9857 25.2966 46.8241 25.3109V26.9152H47.2162C47.4349 26.9152 47.6273 26.9032 47.7937 26.8794C47.9601 26.8555 48.0979 26.8125 48.2072 26.7505C48.3213 26.6884 48.4068 26.6048 48.4639 26.4998C48.5209 26.3947 48.5494 26.2611 48.5494 26.0987C48.5494 25.9459 48.5209 25.817 48.4639 25.712C48.4068 25.6069 48.3237 25.5234 48.2143 25.4613C48.1098 25.3993 47.9838 25.3563 47.8365 25.3324C47.6891 25.3038 47.5275 25.2894 47.3517 25.2894Z"
            fill={color}
        />
        <path
            d="M52.1259 29.5221V24.5589H55.2985V25.3252H53.0242V26.5499H55.049V27.3019H53.0242V28.7558H55.4696V29.5221H52.1259Z"
            fill={color}
        />
        <path
            d="M59.958 29.6295C59.5872 29.6295 59.2545 29.5722 58.9598 29.4576C58.6652 29.343 58.4156 29.1759 58.2112 28.9563C58.0069 28.7319 57.85 28.4598 57.7407 28.1399C57.6314 27.82 57.5767 27.4523 57.5767 27.0369C57.5767 26.6215 57.6385 26.2539 57.7621 25.934C57.8904 25.6141 58.0639 25.3443 58.2825 25.1247C58.5012 24.9003 58.7578 24.7308 59.0525 24.6162C59.3472 24.5016 59.6633 24.4443 60.0007 24.4443C60.2051 24.4443 60.3905 24.461 60.5568 24.4945C60.7232 24.5231 60.8682 24.5565 60.9917 24.5947C61.1153 24.6329 61.2175 24.6735 61.2983 24.7165C61.3791 24.7595 61.4361 24.7905 61.4694 24.8096L61.2056 25.5473C61.0916 25.4756 60.9276 25.4088 60.7137 25.3467C60.5046 25.2799 60.2764 25.2465 60.0293 25.2465C59.8154 25.2465 59.6158 25.2847 59.4304 25.3611C59.245 25.4327 59.0834 25.5425 58.9456 25.6905C58.8125 25.8385 58.7079 26.0247 58.6319 26.2491C58.5558 26.4735 58.5178 26.7337 58.5178 27.0298C58.5178 27.2924 58.5463 27.5335 58.6034 27.7531C58.6652 27.9727 58.7578 28.1637 58.8814 28.3261C59.005 28.4836 59.1618 28.6078 59.352 28.6985C59.5421 28.7844 59.7702 28.8274 60.0364 28.8274C60.3548 28.8274 60.6139 28.7964 60.8135 28.7343C61.0131 28.6674 61.1676 28.6054 61.2769 28.5481L61.5193 29.2858C61.4623 29.3239 61.3839 29.3621 61.284 29.4003C61.189 29.4385 61.0749 29.4767 60.9418 29.5149C60.8088 29.5484 60.659 29.5746 60.4927 29.5937C60.3263 29.6176 60.1481 29.6295 59.958 29.6295Z"
            fill={color}
        />
        <path
            d="M65.31 24.5088C66.023 24.5088 66.5672 24.6401 66.9427 24.9027C67.3229 25.1653 67.513 25.5664 67.513 26.1059C67.513 26.7791 67.1827 27.2351 66.522 27.4738C66.6123 27.5836 66.7145 27.7173 66.8286 27.8749C66.9427 28.0324 67.0591 28.2043 67.1779 28.3905C67.2968 28.572 67.4108 28.7605 67.5202 28.9563C67.6295 29.1473 67.7269 29.3359 67.8125 29.5221H66.8072C66.7169 29.3502 66.6195 29.1783 66.5149 29.0064C66.4103 28.8298 66.3034 28.6603 66.1941 28.4979C66.0895 28.3308 65.985 28.1757 65.8804 28.0324C65.7758 27.8844 65.6784 27.7531 65.5881 27.6385C65.5215 27.6433 65.4645 27.6457 65.417 27.6457C65.3694 27.6457 65.3243 27.6457 65.2815 27.6457H64.8466V29.5221H63.9483V24.6305C64.1669 24.5828 64.3998 24.5518 64.647 24.5374C64.8941 24.5183 65.1152 24.5088 65.31 24.5088ZM65.3742 25.2894C65.1841 25.2894 65.0082 25.2966 64.8466 25.3109V26.9152H65.2387C65.4574 26.9152 65.6499 26.9032 65.8162 26.8794C65.9826 26.8555 66.1204 26.8125 66.2297 26.7505C66.3438 26.6884 66.4294 26.6048 66.4864 26.4998C66.5434 26.3947 66.5719 26.2611 66.5719 26.0987C66.5719 25.9459 66.5434 25.817 66.4864 25.712C66.4294 25.6069 66.3462 25.5234 66.2369 25.4613C66.1323 25.3993 66.0063 25.3563 65.859 25.3324C65.7117 25.3038 65.5501 25.2894 65.3742 25.2894Z"
            fill={color}
        />
        <path
            d="M72.052 29.6295C71.7145 29.6295 71.4246 29.5818 71.1822 29.4863C70.9398 29.386 70.7378 29.2476 70.5762 29.0709C70.4194 28.8942 70.3029 28.6865 70.2269 28.4478C70.1508 28.2091 70.1128 27.9465 70.1128 27.66V24.5589H71.0182V27.5741C71.0182 27.7985 71.042 27.9918 71.0895 28.1542C71.1418 28.3117 71.2131 28.4407 71.3034 28.5409C71.3985 28.6412 71.5078 28.7152 71.6314 28.7629C71.7597 28.8107 71.9023 28.8346 72.0591 28.8346C72.216 28.8346 72.3586 28.8107 72.4869 28.7629C72.6152 28.7152 72.7245 28.6412 72.8149 28.5409C72.9099 28.4407 72.9812 28.3117 73.0287 28.1542C73.081 27.9918 73.1072 27.7985 73.1072 27.5741V24.5589H74.0126V27.66C74.0126 27.9465 73.9722 28.2091 73.8914 28.4478C73.8154 28.6865 73.6965 28.8942 73.5349 29.0709C73.3781 29.2476 73.1761 29.386 72.9289 29.4863C72.6818 29.5818 72.3895 29.6295 72.052 29.6295Z"
            fill={color}
        />
        <path
            d="M80.2447 24.5589V25.3396H78.7261V29.5221H77.8206V25.3396H76.3021V24.5589H80.2447Z"
            fill={color}
        />
        <path
            d="M82.5696 24.5589H83.4679V29.5221H82.5696V24.5589Z"
            fill={color}
        />
        <path
            d="M89.6206 29.5221C89.459 29.2499 89.2784 28.9587 89.0787 28.6483C88.8791 28.3332 88.6723 28.0181 88.4585 27.703C88.2446 27.3831 88.0259 27.0751 87.8026 26.7791C87.5839 26.4783 87.3748 26.2085 87.1752 25.9698V29.5221H86.2911V24.5589H87.0254C87.2156 24.7595 87.4199 24.9982 87.6386 25.2751C87.8572 25.5473 88.0759 25.8313 88.2945 26.1274C88.5179 26.4234 88.7318 26.7218 88.9361 27.0226C89.1453 27.3186 89.3306 27.5956 89.4922 27.8534V24.5589H90.3834V29.5221H89.6206Z"
            fill={color}
        />
        <path
            d="M95.5183 25.2393C95.0097 25.2393 94.62 25.3993 94.3491 25.7191C94.0829 26.0343 93.9498 26.4735 93.9498 27.0369C93.9498 27.3043 93.9807 27.5502 94.0425 27.7746C94.1091 27.9942 94.2041 28.1828 94.3277 28.3404C94.456 28.4979 94.6129 28.6221 94.7982 28.7128C94.9884 28.7987 95.2094 28.8417 95.4613 28.8417C95.6181 28.8417 95.7536 28.8369 95.8677 28.8274C95.9817 28.8131 96.072 28.7964 96.1386 28.7773V26.994H97.0369V29.3789C96.9133 29.4266 96.7042 29.4791 96.4095 29.5364C96.1148 29.5937 95.775 29.6224 95.39 29.6224C95.0335 29.6224 94.7079 29.5651 94.4132 29.4505C94.1186 29.3359 93.8666 29.1688 93.6575 28.9491C93.4531 28.7295 93.2939 28.4598 93.1798 28.1399C93.0658 27.82 93.0087 27.4523 93.0087 27.0369C93.0087 26.6215 93.0705 26.2539 93.1941 25.934C93.3224 25.6141 93.4959 25.3443 93.7146 25.1247C93.9332 24.9003 94.1898 24.7308 94.4845 24.6162C94.7792 24.5016 95.0929 24.4443 95.4256 24.4443C95.6538 24.4443 95.8558 24.461 96.0316 24.4945C96.2122 24.5231 96.3667 24.5565 96.495 24.5947C96.6234 24.6329 96.7279 24.6735 96.8087 24.7165C96.8943 24.7595 96.9537 24.7905 96.987 24.8096L96.7161 25.5473C96.5735 25.4613 96.3952 25.3897 96.1814 25.3324C95.9722 25.2703 95.7512 25.2393 95.5183 25.2393Z"
            fill={color}
        />
        </svg>
    )
};

export default RockstarLogo;




