import React from 'react'
import CreatableSelect from 'react-select/creatable';
import { useTranslation } from 'react-i18next';
import { SInputGroup, SGroupText, SGroupIcon, selectStyles, SSelectContainer, SSelectResult } from '../FormStyle';

export type TLocation = {
  country: string
  label: string
  long: number
  lat: number
}

interface ILocationSelectField {
  locations: TLocation[]
  setLocations: Function
  readOnly: boolean
}

interface ILocation {
  country: string
  label: string
  readOnly: boolean
  onDelete: Function
}

type MyOptionType = { label: string, value: TLocation } ;

const Location = ({...props}:ILocation) => {
  return(
    <SSelectResult>
      <SInputGroup className="mb-2">
        <SGroupIcon style={{width:"54px"}}>
          <img 
            width="28" 
            height="28" 
            src={window.location.origin + `/Assets/CountryFlags/${props.country}.svg`} 
            onError={(e:any) => {e.target.onerror = null; e.target.src=window.location.origin + `/Assets/CountryFlags/Unknown.svg`}}
          />
        </SGroupIcon>
        <SGroupText style={{width:`calc(100% - ${props.readOnly ? "50px" : "94px"})`}} >{props.label}</SGroupText>
        {!props.readOnly ?
          <SGroupIcon onClick={() => props.onDelete()}><span className="fas fa-times"/></SGroupIcon>
          : <></>
        }
      </SInputGroup>
    </SSelectResult>
  )
}

export const LocationSelectField = ({...props}:ILocationSelectField) => {
  const t = useTranslation().t;

  const locationOptions = [
    {value: {country:'CH', label:'Zurich', long:47.369019, lat:8.53803}, label: t('Location.CH-Zurich')},
    {value: {country:'CH', label:'Geneva', long:46.204391, lat:6.143158}, label: t('Location.CH-Geneva')},
    {value: {country:'CH', label:'Basel', long:47.559608, lat:7.58061}, label: t('Location.CH-Basel')},
    {value: {country:'CH', label:'Bern', long:46.948002, lat:7.44813}, label: t('Location.CH-Bern')},
    {value: {country:'CH', label:'StGallen', long:47.426418, lat:9.37601}, label: t('Location.CH-StGallen')},
    {value: {country:'CH', label:'Montreux', long:46.43132, lat:6.91061}, label: t('Location.CH-Montreux')},
    {value: {country:'CH', label:'Biel', long:47.143299, lat:7.24876}, label: t('Location.CH-Biel')},
    {value: {country:'CH', label:'Lausanne', long:46.51963, lat:6.63213}, label: t('Location.CH-Lausanne')},
    {value: {country:'CH', label:'Chur', long:46.849491, lat:9.53067}, label: t('Location.CH-Chur')},
    {value: {country:'CH', label:'Zug', long:47.166191, lat:8.51541}, label: t('Location.CH-Zug')},
    {value: {country:'CH', label:'Fribourg', long:46.8025, lat:7.15128}, label: t('Location.CH-Fribourg')},
    {value: {country:'CH', label:'Winterthur', long:47.499882, lat:8.72616}, label: t('Location.CH-Winterthur')},
    {value: {country:'CH', label:'Lucerne', long:47.04554, lat:8.30801}, label: t('Location.CH-Lucerne')},
    {value: {country:'CH', label:'Neuchatel', long:46.992008, lat:6.93092}, label: t('Location.CH-Neuchatel')},
    {value: {country:'CH', label:'Lugano', long:46.003601, lat:8.95362}, label: t('Location.CH-Lugano')},
    {value: {country:'CH', label:'Sion', long:46.23241, lat:7.35848}, label: t('Location.CH-Sion')},
    {value: {country:'CH', label:'Schaffhausen', long:47.69754, lat:8.63517}, label: t('Location.CH-Schaffhausen')},
    {value: {country:'CH', label:'Thun', long:46.75257, lat:7.62343}, label: t('Location.CH-Thun')},
    {value: {country:'LI', label:'Vaduz', long:47.1410, lat:9.5209}, label: t('Location.LI-Vaduz')},
    {value: {country:'DE', label:'Berlin', long:52.52343, lat:13.41144}, label: t('Location.DE-Berlin')},
    {value: {country:'DE', label:'Hamburg', long:53.553841, lat:9.99165}, label: t('Location.DE-Hamburg')},
    {value: {country:'DE', label:'Cologne', long:50.940529, lat:6.95991}, label: t('Location.DE-Cologne')},
    {value: {country:'DE', label:'Dresden', long:51.050991, lat:13.73363}, label: t('Location.DE-Dresden')},
    {value: {country:'DE', label:'Bremen', long:53.074982, lat:8.80708}, label: t('Location.DE-Bremen')},
    {value: {country:'DE', label:'Munich', long:48.13913, lat:11.58022}, label: t('Location.DE-Munich')},
    {value: {country:'DE', label:'FrankfurtAmMain', long:50.110882, lat:8.67949}, label: t('Location.DE-FrankfurtAmMain')},
    {value: {country:'DE', label:'Stuttgart', long:48.777111, lat:9.18077}, label: t('Location.DE-Stuttgart')},
    {value: {country:'DE', label:'Düsseldorf', long:51.22496, lat:6.77567}, label: t('Location.DE-Düsseldorf')},
    {value: {country:'DE', label:'Dortmund', long:51.511028, lat:7.46352}, label: t('Location.DE-Dortmund')},
    {value: {country:'DE', label:'Essen', long:51.45791, lat:7.01386}, label: t('Location.DE-Essen')},
    {value: {country:'DE', label:'Leipzig', long:51.339691, lat:12.37136}, label: t('Location.DE-Leipzig')},
    {value: {country:'DE', label:'Bremen', long:53.074982, lat:8.80708}, label: t('Location.DE-Bremen')},
    {value: {country:'DE', label:'Dresden', long:51.050991, lat:13.73363}, label: t('Location.DE-Dresden')},
    {value: {country:'DE', label:'Hannover', long:52.37207, lat:9.73569}, label: t('Location.DE-Hannover')},
    {value: {country:'DE', label:'Nuremberg', long:49.452103, lat:11.076665}, label: t('Location.DE-Nuremberg')},
    {value: {country:'DE', label:'Duisburg', long:51.434406, lat:6.762329}, label: t('Location.DE-Duisburg')},
    {value: {country:'DE', label:'Bochum', long:51.482391, lat:7.21237}, label: t('Location.DE-Bochum')},
    {value: {country:'DE', label:'Wuppertal', long:51.256119, lat:7.15075}, label: t('Location.DE-Wuppertal')},
    {value: {country:'DE', label:'Bielefeld', long:52.030228, lat:8.532471}, label: t('Location.DE-Bielefeld')},
    {value: {country:'DE', label:'Bonn', long:50.7327, lat:7.09631}, label: t('Location.DE-Bonn')},
    {value: {country:'DE', label:'Munster', long:51.96471, lat:7.6285}, label: t('Location.DE-Munster')},
    {value: {country:'DE', label:'Karlsruhe', long:49.008091, lat:8.40376}, label: t('Location.DE-Karlsruhe')},
    {value: {country:'DE', label:'Mannheim', long:49.48468, lat:8.47672}, label: t('Location.DE-Mannheim')},
    {value: {country:'DE', label:'Augsburg', long:48.370544, lat:48.370544}, label: t('Location.DE-Augsburg')},
    {value: {country:'DE', label:'Wiesbaden', long:50.084309, lat:8.23873}, label: t('Location.DE-Wiesbaden')},
  ] as {value: TLocation, label:string}[]
  
  const locationsDisplay = (props.locations.length === 0 && props.readOnly) ? [<div>{t("Base.empty")}</div>] 
    : props.locations.map((location:TLocation, index:number) => {
      return(
        <Location
          key={`location_${location.label}`}
          country={location.country}
          label={location.label} 
          onDelete={async () => {
            let location_tmp = [...props.locations]
            location_tmp.splice(index, 1)
            props.setLocations(location_tmp)
            }
          }
          readOnly={props.readOnly}
        />
      )
    }
  )

  const distabledOptions = new Set()
  props.locations.map(elem => {
    distabledOptions.add(elem.label)
    return true
  })

  return(
    <SSelectContainer style={{marginBottom:"18px"}}>
      {!props.readOnly ?
        <CreatableSelect 
          styles={selectStyles}
          options={locationOptions}
          value={{value:{} as TLocation, label:t('Base.Search')}}
          onChange={
            async (s: any, action: any) => {
              if(action.action==="select-option"){
                let location_tmp = [...props.locations]
                location_tmp.push((s as MyOptionType).value);
                props.setLocations(location_tmp);
              }
            }
          }
          onCreateOption={
            (inputValue:string) => {
              let location_tmp = [...props.locations]
              location_tmp.push({country:'Unknown', label:inputValue, long:0.0, lat:0.0});
              props.setLocations(location_tmp);
            }
          }
          formatCreateLabel={(inputValue:string) => t(`Base.Creatable`) + `${inputValue}`}
          isOptionDisabled={(option) => distabledOptions.has(option.value.label)}
        />
        : <></>
      } 
      {locationsDisplay}
    </SSelectContainer>
  )
}


