import React, { useState, useEffect }  from 'react'
import { Formik } from 'formik';
import { Col, Form, Row } from 'react-bootstrap';
import { SForm, SCheckControl, SControl, SCol, SGroup, STitle, SSubTitle, SEditButton, SLabel, SCheckLabel, SCheckContainer, SFeedback, SButton, selectStyles } from './FormStyle'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next';
import { Select } from './Fields/SelectField';
import { LocationSelectField, TLocation } from './Fields/LocationSelectField'
import './FormStyle.css';
import { useFetchAuth } from '../_Core/AuthHandling'

const id = 1
interface IUserJobpreference {
  preferenceDetail: string
  preferenceHomeoffice: string
  preferenceUrgency: string
  preferenceStartDate: string
  preferencePermanent: boolean
  preferenceFreelance: boolean
  preferenceLocations: TLocation[]
}

const SColLabel = styled(Col)`
  margin-top: 6px;
  margin-right: auto;
  padding-left: 5px;
  padding-right: 25px;
`

export default function UserFormDreamJob({...props}:any) {
  const t = useTranslation().t;
  const fetchAuth = useFetchAuth()
  const [readOnly, setReadOnly] = useState(props.readinit === true)
  const [preferenceDetail, setPreferenceDetail] = useState('')
  const [preferenceHomeoffice, setPreferenceHomeoffice] = useState('')
  const [preferenceUrgency, setPreferenceUrgency] = useState('')
  const [preferenceStartDate, setPreferenceStartDate] = useState('')
  const [preferencePermanent, setPreferencePermanent] = useState(false)
  const [preferenceFreelance, setPreferenceFreelance] = useState(false)
  const [preferenceLocations, setPreferenceLocations]= useState([] as TLocation[])

  const homeofficeOptions = [
    { value: '0 - I prefer working in the office', label: t('ProfileDreamJob.Homeoffice.0') },
    { value: '1 - 1 day/week from home', label: t('ProfileDreamJob.Homeoffice.1') },
    { value: '2 - 2 day/week from home', label: t('ProfileDreamJob.Homeoffice.2') },
    { value: '3 - 3 day/week from home', label: t('ProfileDreamJob.Homeoffice.3') },
    { value: '4 - 4 day/week from home', label: t('ProfileDreamJob.Homeoffice.4') },
    { value: '5 - I look for remote jobs (100% from home)', label: t('ProfileDreamJob.Homeoffice.5') },
  ]

  const urgencyOptions = [
    { value: '1 - Immediately, I am looking for a job right now.', label: t('ProfileDreamJob.Urgency.1') },
    { value: '2 - Not urgently, but I am actively looking.', label: t('ProfileDreamJob.Urgency.2') },
    { value: '3 - Not urgently, but if the right job comes along I would like to hear about it.', label: t('ProfileDreamJob.Urgency.3') },
    { value: '4 - I do not want to change jobs yet, but I would like to be prepared in the future', label: t('ProfileDreamJob.Urgency.4') },
    { value: '5 - No, not in the next 3-5 years.', label: t('ProfileDreamJob.Urgency.5') },
  ]

  useEffect(() => {
    const effectAsync = async () => {
      fetchAuth(
        window.__API_URL__ + `/user/${id}/jobpreference`, 
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .then(
        (data: IUserJobpreference) => {
          setPreferenceDetail(data.preferenceDetail)
          setPreferenceHomeoffice(data.preferenceHomeoffice)
          setPreferenceUrgency(data.preferenceUrgency)
          setPreferenceStartDate(data.preferenceStartDate)
          setPreferencePermanent(data.preferencePermanent === null ? false : data.preferencePermanent)
          setPreferenceFreelance(data.preferenceFreelance === null ? false : data.preferenceFreelance)
          setPreferenceLocations(data.preferenceLocations)
        }
      )
      .catch(error => {setReadOnly(true)})
    }
    effectAsync()
  }, [])

  return (
    <Formik
      initialValues={{
        preferenceDetail: preferenceDetail,
        preferenceHomeoffice: preferenceHomeoffice,
        preferenceUrgency: preferenceUrgency,
        preferenceStartDate: preferenceStartDate,
        preferencePermanent: preferencePermanent,
        preferenceFreelance: preferenceFreelance,
        preferenceLocations: preferenceLocations,
      }}
      enableReinitialize={true}
      onSubmit={
        async (values) => {
          await fetchAuth(
            window.__API_URL__ + `/user/0/jobpreference`, 
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },  
              body: JSON.stringify(values),
            }
          )
          .then(data => {setReadOnly(true); props.submitCallback(); return data})
        }
      }
    >
      {({
      handleSubmit,
      handleChange,
      handleBlur,
      values,
      touched,
      isValid,
      errors,
      setFieldValue
      }) => (  
        <SForm noValidate onSubmit={handleSubmit}>
          <SCol md="5">
            <STitle>{t('ProfileDreamJob.title')}</STitle>
            <SSubTitle>{t('ProfileDreamJob.subTitle')}</SSubTitle>
            {props.readinit === true ? 
              <Row>
                <SEditButton 
                  className="fas fa-pen"
                  onClick={() => setReadOnly(false)}/> 
              </Row> : <></>
            }
            <SGroup as={Col} md="12" controlId="preferenceType">
              <SLabel>{t('ProfileDreamJob.preferenceType')}</SLabel>
              <SCol style={{display: "flex", flexDirection: "row", flexWrap:"wrap", justifyContent:"space-between", padding:"0px"}} md="9">
                <SCheckContainer>
                  <SCheckControl
                    type="checkbox"
                    name="preferencePermanent"
                    checked={preferencePermanent}
                    onChange={() => {setPreferencePermanent(!preferencePermanent)}}
                    disabled={readOnly}
                    />
                  <SCheckLabel active={values.preferencePermanent}>{t('ProfileDreamJob.preferencePermanentPlaceHolder')}</SCheckLabel>
                </SCheckContainer>
                <SCheckContainer>
                  <SCheckControl   
                    type="checkbox"
                    name="preferenceFreelance"
                    checked={preferenceFreelance}
                    onChange={() => {setPreferenceFreelance(!preferenceFreelance)}}
                    disabled={readOnly}
                    />
                  <SCheckLabel active={values.preferenceFreelance}>{t('ProfileDreamJob.preferenceFreelancePlaceHolder')}</SCheckLabel>
                </SCheckContainer>
              </SCol> 
            </SGroup>
            <SGroup as={Col} md="12" controlId="preferenceUrgency">
              <SLabel>{t('ProfileDreamJob.preferenceUrgency')}</SLabel>
              {readOnly ?
                <p>{urgencyOptions.find(option => option.value === values.preferenceUrgency)?.label}</p> :
                <Select 
                  styles={selectStyles}
                  options={urgencyOptions}
                  onChange={(e:any) => {setPreferenceUrgency(e.value)}}
                  value={urgencyOptions.filter(option => option.value === values.preferenceUrgency)}
                  isOptionSelected={(elem:any) => {return(elem.value === values.preferenceUrgency)}}
                />
              }
            </SGroup>
            <SGroup as={Col} md="12" controlId="preferenceStartDate">
              {(values.preferenceUrgency===null || values.preferenceUrgency===urgencyOptions[0].value) ? 
                <SLabel>{t('ProfileDreamJob.preferenceStartDate')}</SLabel>
                : <SLabel>{t('ProfileDreamJob.preferenceNoticePeriod')}</SLabel>}
              <SControl
                type="text" 
                name="preferenceStartDate" 
                placeholder={readOnly ? t('Base.empty') : t('ProfileDreamJob.preferenceStartDatePlaceHolder')}
                value={values.preferenceStartDate}
                onChange={(e:React.ChangeEvent<any>) => {setPreferenceStartDate(e.target.value)}}
                isInvalid={touched.preferenceStartDate && !!errors.preferenceStartDate && !readOnly}
                readOnly={readOnly}
                plaintext={readOnly}
                />
            </SGroup>
            <SGroup as={Col} md="12" controlId="preferenceDetail">
              <SLabel>{t('ProfileDreamJob.preferenceDetail')}</SLabel>
              <Form.Control 
                style={{
                  height:"120px",
                  border:"1px solid #474747",
                  borderRadius:"4px",
                  margin:"0px",
                  fontFamily:"Ubuntu",
                  fontStyle:"normal",
                  fontWeight:"normal",
                  fontSize:"16px",
                  color:"#495057"
                }}
                as="textarea"
                rows={5}
                name="preferenceDetail"
                placeholder={readOnly ? t('Base.empty') : t('ProfileDreamJob.preferenceDetailPlaceholder')}
                value={values.preferenceDetail}
                onChange={(e:React.ChangeEvent<any>) => {setPreferenceDetail(e.target.value)}}
                isInvalid={touched.preferenceDetail && !!errors.preferenceDetail}
                readOnly={readOnly}
                plaintext={readOnly}
              />
              <SFeedback type="invalid" tooltip>{errors.preferenceDetail}</SFeedback>
            </SGroup>
            <SGroup as={Col} md="12" controlId="preferenceLocations">
              <SLabel>{t('ProfileDreamJob.preferenceLocations')}</SLabel>
              <LocationSelectField
                locations={preferenceLocations}
                setLocations={setPreferenceLocations}
                readOnly={readOnly}
              />
            </SGroup>
            <SGroup as={Col} md="12" controlId="preferenceHomeoffice">
              <SLabel>{t('ProfileDreamJob.preferenceHomeoffice')}</SLabel>
              {readOnly ?
                <p>{homeofficeOptions.find(option => option.value === values.preferenceHomeoffice)?.label}</p> :
                <Select 
                  styles={selectStyles}
                  options={homeofficeOptions}
                  onChange={(e:any) => {setPreferenceHomeoffice(e.value)}}
                  value={homeofficeOptions.filter(option => option.value === values.preferenceHomeoffice)}
                  isOptionSelected={(elem:any) => {return(elem.value === values.preferenceHomeoffice)}}
                />
              }
            </SGroup>
            {!readOnly ?
              <SGroup as={Col} md="12" xs controlid="button" style={{ justifyContent: "flex-end" }}>
                <SCol md="9" style={{ alignItems: "baseline", display: "flex", margin: "0px", padding: "0px"}}>
                  <SButton primary={true} type="submit">{t('Base.submit')}</SButton>
                </SCol> 
              </SGroup>
              : <></>
            }
          </SCol>
        </SForm>
      )}  
    </Formik>
  )
}
