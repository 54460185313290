import React from 'react';
import MainMenu from '../_Menu/MainMenu';
import UserFormContact from '../_UserForm/Contact'
import ProfileContainer from './ProfileContainer';


export default function VProfileContact({...props}:any) {
  return(
    <>
      <MainMenu pos={3}/>
      <ProfileContainer pos={1}>
        <UserFormContact />
      </ProfileContainer>
    </>
  )
}