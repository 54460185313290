import React, { useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components'
import { Button } from 'react-bootstrap';
import { UserContext, useLogout } from '../_Core/AuthHandling';
import './Style.css';

const SBar = styled("div")`
  width: 100vw;
  overflow-x: scroll;

  background: #FFFFFF;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.05);

  display:grid;
  height: 60px;
  grid-template-columns: 160px auto 60px;
  grid-template-rows: 60px;
  grid-template-areas: "logo menu user";
`

const SBarIcon = styled.img`
  grid-area: logo; 
  display: block;
  margin-top: 5px;
  margin-left: 10px;
  float:left;
`

const SMenu = styled("div")`
  grid-area: menu;
  display: block;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 900px) {
    width: 0px;
  }

  @media (min-width: 901px) {
    top: 510px;
  }

  background: #FFFFFF;
`

const SMenuItem = styled.div<{active:boolean}>`
  margin-top: 16px;
  margin-bottom: 16px;
  margin-left: 10px;
  margin-right: 10px;
  float: left;

  width: 150px;

  color: ${props => props.active ? "#333333": "#798CA3"};

  :hover{
    color: ${props => props.active ? "#333333": "#007bff"};
    cursor: pointer;
  }
`

const SItemIcon = styled("div")`
  display: inline-block;
  height: 28px;
  width: 28px;
  margin-right: 20px;
  padding-top: 3px;
  float:left;

  font-size: 20px;
  text-align: center;
`

const SItemLabel = styled("div")`
  display: inline-block;
  height: 28px;
  width: 102px;
  float: left;

  font-family: Ubuntu;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
`

const SUserMenu = styled("div")`
  display: block;
  position: absolute;
  right: 10px;
  width: 180px;
  z-index: 4;
  background: #fff;
  border-radius: 4px;
  border: 1px solid;
  border-color: #798CA3;
  top: 70px;
`

export const SDropMenu = styled(Button)<{left:boolean}>`
  background: #FFFFFF;
  border-color: #FFFFFF;
  border-radius: 4px;
  border: none;
  text-align: center;
  color: #000;
  font-family: Ubuntu;  
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  height: 40px;
  padding: 0px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
  width: 40px;
  
  :hover,:active,:focus {
    box-shadow: none !Important;
    outline: none !Important;
  }

  :hover {
    background-color: #666666 !Important;
    border-color: #FFFFFF !Important;
    color: #FFFFFF !Important;
  }

  :active {
    background-color: #111111 !Important;
    border-color: #FFFFFF !Important;
    color: #FFFFFF !Important;
  }
  :focus {
    background-color: #111111 !Important;
    border-color: #FFFFFF !Important;
    color: #FFFFFF !Important;
  }
`

const SReferalContainer = styled("div")`
  position: absolute;
  top: 100px;
  overflow: auto;

  border: 1px solid #000;
  border-radius: 10px;
  
  z-index: 4;
  background: rgba(255,255,255,1.0);

  @media (max-width: 900px) {
    left: 10vw;
    width: 80vw;
  }

  @media (min-width: 901px) {
    left: 20vw;
    width: 60vw;
  }
`

const SReferalText = styled("div")`
  display: inline-block;
  margin: 30px 20px 10px 20px;
  float: left;

  font-family: Ubuntu;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #000;
`

const SReferalLink = styled("div")`
  display: inline-block;
  margin: 10px 20px 10px 20px;
  float: left;
  width: 100%;

  font-family: Ubuntu;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #000;
`

const SReferalShareContainer = styled("div")`
  display: inline-block;
  margin: 10px 20px 30px 10px;
  float: left;
`

const SReferalShareIcon = styled("a")`
  display:inline-block;
  width:50px;
  float:left;
  text-align:center;

  font-size: 30px;
  color: #000;
`

const SeferalCloseButton = styled(Button)`
  position: relative;
  float: right;

  margin-top: 16px;
  margin-bottom: 30px;
  margin-right: 20px;

  background: #fff;
  border: 1px solid #000;
  
  font-family: Ubuntu;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #000;
`

export default function MainMenu({...props}:any) {
  const [expand, setExpand] = useState(false)
  const [showReferal, setShowReferal] = useState(false)
  const t = useTranslation().t;
  const logout = useLogout()
  const navigate = useNavigate();
  const user = useContext(UserContext);

  const smallScreen = window.matchMedia("(max-width: 900px)").matches

  return(
    <>
      <SBar>
        <SBarIcon height="50px" src={window.location.origin + "/Logo_Rockstar_black.png"}/>
        <SMenu>
          {smallScreen ? 
            <></> : 
            <>
              <SMenuItem onClick={() => navigate(`/applications`)} active={props.pos===1}>
                <SItemIcon style={{fontSize:"20px"}} className="fas fa-code" />
                <SItemLabel >{t("Menu.Board")}</SItemLabel>
              </SMenuItem>
              <SMenuItem onClick={() => navigate(`/activities`)} active={props.pos===2}>
                <SItemIcon  className={`fa${props.pos===2 ? "s":"r"} fa-calendar-check`} />
                <SItemLabel >{t("Menu.Activities")}</SItemLabel>
              </SMenuItem>
            </>
          } 
        </SMenu>
        <SDropMenu onClick={(e:any) => {setExpand(!expand); e.currentTarget.blur()}}>
          <span className="fas fa-bars"/>
        </SDropMenu>
        {expand ?
          <SUserMenu >
            {smallScreen ? 
              <>
                <SMenuItem onClick={() => navigate(`/applications`)} active={props.pos===1}>
                  <SItemIcon style={{fontSize:"20px"}} className="fas fa-code" />
                  <SItemLabel >{t("Menu.Board")}</SItemLabel>
                </SMenuItem>
                <SMenuItem onClick={() => navigate(`/activities`)} active={props.pos===2}>
                  <SItemIcon style={{fontSize:"20px"}} className={`fa${props.pos===2 ? "s":"r"} fa-calendar-check`} />
                  <SItemLabel >{t("Menu.Activities")}</SItemLabel>
                </SMenuItem>
              </> : <></>
            } 
            <SMenuItem onClick={() => navigate(`/profile`)} active={props.pos===3}>
              <SItemIcon style={{fontSize:"20px"}} className="fas fa-user" />
              <SItemLabel  >{t("Menu.Profile")}</SItemLabel>
            </SMenuItem>
            <SMenuItem onClick={(e:any) => {setShowReferal(!showReferal); setExpand(!expand); e.currentTarget.blur()}} active={false}>
              <SItemIcon style={{fontSize:"20px"}} className="fas fa-paper-plane" />
              <SItemLabel  >{t("Menu.Referral")}</SItemLabel>
            </SMenuItem>
            <SMenuItem onClick={() => logout()} active={false}>
              <SItemIcon style={{fontSize:"20px"}} className="fas fa-door-open" />
              <SItemLabel  >{t("Menu.Logout")}</SItemLabel>
            </SMenuItem>
          </SUserMenu> : <></>
        }
      </SBar>
      {showReferal ? <SReferalContainer>
          <SReferalText>{t("Base.ReferalTitle")}</SReferalText>
          <SReferalLink>{window.location.origin + `/signup?ref=${user.referralKey}`}</SReferalLink>
          <SReferalShareContainer>
            <SReferalShareIcon href={`mailto:your@friend.com?subject=${t("Base.ReferalMailSubject")}%20${window.location.origin + `/signup?ref=${user.referralKey}`}`}><i className="fas fa-envelope"></i></SReferalShareIcon>
            <SReferalShareIcon href={`sms:+411234567?body=${t("Base.ReferalMailSubject")}%20${window.location.origin + `/signup?ref=${user.referralKey}`}`}><i className="fas fa-comments"></i></SReferalShareIcon>
            <SReferalShareIcon href={`https://wa.me/?text=${t("Base.ReferalMailSubject")}%20${window.location.origin + `/signup?ref=${user.referralKey}`}`}><i className="fab fa-whatsapp"></i></SReferalShareIcon>
            <SReferalShareIcon href={`https://t.me/share/url?url=${window.location.origin + `/signup?ref=${user.referralKey}`}&text=${t("Base.ReferalMailSubject")}`}><i className="fab fa-telegram-plane"></i></SReferalShareIcon>
          </SReferalShareContainer>
          <SeferalCloseButton onClick={() => setShowReferal(false)}>{t("Base.close")}</SeferalCloseButton>
        </SReferalContainer> : <></>}
    </>
  )
}