import styled from 'styled-components'

export const SMenuIconBarTop = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  
  @media (max-width: 1023px) {
    background:  #F5F5F5;
  }
  @media (min-width: 1024px) {
    display:  none;
  }
`

export const SMenuIconBarBottom = styled.div`
  position: absolute;
  top: 100vh - 50px;
  left: 0px;
  width: 100vw;
  
  @media (max-width: 1023px) {
    background:  #F5F5F5;
  }
  @media (min-width: 1024px) {
    display:  none;
  }
`

export const SMenuIcon = styled.div`
  position: absolute;
  left: 18px;
  top: 18px;
  z-index: 4;
  color: #333333;
  font-size: 30px;

  @media (min-width: 1024px) {
    display:  none;
  }

  :hover{
    cursor: pointer;
  }
`

export const SMenuIconBarLogo = styled.img`
  margin-left: auto;
  margin-right: auto;
  margin-top: 4px;
  margin-bottom: 10px;
  display: block;
`

export const SMenu = styled("div")<{expand:boolean}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`

export const SLogoWrapper = styled.div`
 
`

export const SLogo = styled.img`
 display: block;
 float:left;
 grid-area: logo; 
 height:40px;
 margin-left: 10px;
 margin-top: 5px;
 width:135px;
`

export const SBanner = styled.img`
  position: absolute;
  margin-left: 10px;
  margin-right: 10px;
  top: calc(100vh - 110px);
  width: 280px;
`

export const SHeader = styled.div`
  align-items: center;
  align-self: flex-start;
  display: flex;
  justify-content: space-between;
  padding: 32px 32px 32px 32px;

  @media (max-width: 769px) {
    padding: 40px 20px;
  }
`