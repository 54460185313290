import { useState, useContext, createContext } from 'react';
import { tryFetch } from '../_Core/ErrorHandling'
import { ToastContext } from '../_Core/Toast'
import { useNavigate } from 'react-router-dom';

export interface ICookie {
  id: string
  referralKey: string
  firstName: string
  lastName: string
  authorization: string
  validUntil: string
  refreshToken: string
}

export interface ICookieParsed {
  id: string
  referralKey: string
  firstName: string
  lastName: string
  authorization: string
  validUntil: number
  refreshToken: string
}

const loadSafe = (key:string) => {
  const tokenString = localStorage.getItem(key);
  if (tokenString !== null
      && tokenString !== 'null' 
      && tokenString !== undefined
      && tokenString !== 'undefined'){
    try {
      return tokenString
    } catch {
      console.log(`CorruptedDataError: The local storage item for ${key} is corrupted :  ${tokenString} `)
      return ""
    }
  }
  else{
    return ""
  }
};

export const getAuthorization = () => {
  const data = {
    id: loadSafe("id"),
    referralKey: loadSafe("referralKey"),
    firstName : loadSafe("firstname"),
    lastName : loadSafe("lastname"),
    authorization: loadSafe("authorization"),
    refreshToken: loadSafe("refreshToken"),
    validUntil: loadSafe("validUntil")
  }
  
  return data
}

export const UserContext = createContext(
  {
    id: "",
    referralKey: "",
    firstName: "",
    lastName: "",
    authorization: "",
    refreshToken: "",
    isLoggedIn: false,
    setUser: (data:ICookie) => {return data},
    cleanUser: () => {}
  }
)

export function useUserProvider(...props:any){
  const userdata = getAuthorization()

  const [id, setId] = useState(userdata.id)
  const [referralKey, setReferralKey] = useState(userdata.referralKey)
  const [firstName, setFirstName] = useState(userdata.firstName)
  const [lastName, setLastName] = useState(userdata.lastName)
  const [authorization, setAuthorization] = useState(userdata.authorization)
  const [refreshToken, setRefreshToken] = useState(userdata.refreshToken)
  const [isLoggedIn, setIsLoggedIn] = useState(userdata.authorization !== "")
  
  const setUser = (data:ICookie) => {
    localStorage.setItem("id", `${data.id}`);
    localStorage.setItem("referralKey", `${data.referralKey}`);
    localStorage.setItem("firstname", `${data.firstName}`);
    localStorage.setItem("lastname", `${data.lastName}`);
    localStorage.setItem("authorization", data.authorization);
    localStorage.setItem("validUntil", JSON.stringify(new Date(Date.parse(data.validUntil))));
    localStorage.setItem("refreshToken", data.refreshToken);
    
    setId(data.id);
    setReferralKey(data.referralKey);
    setFirstName(data.firstName);
    setLastName(data.lastName);
    setAuthorization(data.authorization);
    setRefreshToken(data.refreshToken);
    setIsLoggedIn(data.authorization != undefined)

    return data
  }

  const cleanUser = () => {
    setId("");
    setReferralKey("");
    setFirstName("");
    setLastName("");
    setAuthorization("");
    setRefreshToken("");
    setIsLoggedIn(false)
  }

  const value = {
    id: id,
    referralKey: referralKey,
    firstName: firstName,
    lastName: lastName,
    authorization: authorization,
    refreshToken: refreshToken,
    isLoggedIn: isLoggedIn,
    setUser: setUser,
    cleanUser: cleanUser
  }

  return value
}

export function useFetchAuth(){
  const user = useContext(UserContext);
  const toastHandler = useContext(ToastContext);
  const navigate = useNavigate();

  const handleAuthFail = () => {
    user.cleanUser();
    toastHandler.showMsg("No Valid Authorization Found, Please Login", true)
    navigate("/login");  
  }

  const fetchAuth = async (input:string, init:RequestInit) => {

    // Initial Fetch Try
    (init.headers as { [key: string]: string }).Authorization = user.authorization;
    
    const aRes = await tryFetch(
      input,
      init
    )

    if (aRes.errors === null){
      return aRes.data
    }
    if (aRes.errors instanceof Array<string>){
      toastHandler.showMsg(aRes.errors, true)
      return Promise.reject(aRes.errors)
    } 
    if ( aRes.errors === 401 && (!user?.refreshToken || !user?.authorization || !user?.id)){
      handleAuthFail()
    }

    // Try try to refresh Token
    const bRes = await tryFetch(window.__API_URL__ + `/user/${user.id}/refreshToken`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': user.authorization,
      },
      body: JSON.stringify({
        "refreshToken":user.refreshToken,
      }),
    })

    if (bRes.errors !== null){
      handleAuthFail()
    }

    user.setUser(bRes.data as ICookie);
    (init.headers as { [key: string]: string }).Authorization = (bRes.data as ICookie).authorization;

    // Try to Fetch with new Token
    const cRes = await tryFetch(
      input,
      init
    )

    if (cRes.errors === null){
      return cRes.data
    } else {
      toastHandler.showMsg(cRes.errors as string[], true)
      return Promise.reject(cRes.errors)
    } 
  }

  return(fetchAuth)
}


export function useLogout(){
  const user = useContext(UserContext);
  const navigate = useNavigate();
  const fetchAuth = useFetchAuth()
  
  const cleanAndNav = () => {
    user.cleanUser(); 
    navigate("/login"); 
    window.location.reload()
  }

  const logout = () => {
    fetchAuth(
      window.__API_URL__ + `/logout`, 
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({})
      }
    ).then(() => {cleanAndNav()})
    .catch(() => {cleanAndNav()})
  }

  return(logout)
}